import React from "react";
import {config} from "./config/formConfiguration";

const moment = require("moment");

function concatAll() {
  let s = "";
  for (let x in arguments) {
    if (!arguments[x]) return null;
    s += arguments[x];
  }
  return s;
}

const SummaryItem = (props) => {
  if (!props.text || !props.value || props.text === undefined || props.value === undefined) return null;
  return (
    <div className="ml-0" style={{ marginBottom: "8px" }}>
      <div style={{ fontSize: "1rem", marginBottom: "-2px" }}>{props.text}</div>
      <div className="col-lg">
        <div className="row">
          <div className="text-muted">
            <div>{props.value}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HeadlessSummaryItem = (props) => {
  if (!props.value || props.value === undefined) return null;
  return (
    <div className="ml-0">
      <div className="col-lg">
        <div className="row">
          <div className="text-muted">
            <div>{props.value}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

class PermitRequestDetails extends React.Component {
  state = { loading: true, partialPaymentType: null };

  render() {
    const conf = config(this.props, "applicantInformationForm");
    const styles = {
      header: { fontSize: "1.15rem", height: "14px" },
    };

    return (
      <div style={{backgroundColor:'white'}}>
        <div className="mb-2"><h4>REQUEST DETAILS</h4></div>
        <div className="mb-4">
          <h5 style={styles.header}>Applicant Information</h5>
          {this.props.applicantInformationForm.applicantType === "consultant" && (
            <div className="ml-0">
              <div>{conf.applicantInformationForm.consultantLabel}</div>
              <SummaryItem text={concatAll(conf.applicantInformationForm.consultantLabel, " name")} value={this.props.applicantInformationForm.consultantName} />
              <SummaryItem text="Contact Name" value={concatAll(this.props.applicantInformationForm.consultantFirstName, " ", this.props.applicantInformationForm.consultantLastName)} />
              <SummaryItem text="Job Title" value={this.props.applicantInformationForm.consultantTitle} />
              <SummaryItem
                text="Address"
                value={concatAll(
                  this.props.applicantInformationForm.consultantUnit ? ` Unit ${this.props.applicantInformationForm.consultantUnit}`: ' ', " ", this.props.applicantInformationForm.consultantStreet, " "
                )}
              />
              <SummaryItem text="City" value={this.props.applicantInformationForm.consultantCity} />
              <SummaryItem text="Province" value={this.props.applicantInformationForm.consultantProvince} />
              <SummaryItem text="Postal Code" value={this.props.applicantInformationForm.consultantPostalCode} />
              <SummaryItem
                text="Phone"
                value={concatAll(
                  this.props.applicantInformationForm.consultantPhone,
                  " ",
                  this.props.applicantInformationForm.consultantExtension ? ` ext ${this.props.applicantInformationForm.consultantExtension}` : " "
                )}
              />
              <SummaryItem text="Cell" value={this.props.applicantInformationForm.consultantCell} />
              <SummaryItem text="Email" value={this.props.applicantInformationForm.consultantEmail} />
            </div>
          )}
          <div className="ml-0">
            <div>{conf.applicantInformationForm.businessLabel}</div>
            <SummaryItem text={concatAll(conf.applicantInformationForm.businessLabel, " name")} value={this.props.applicantInformationForm.owner} />
            <SummaryItem text="Contact Name" value={concatAll(this.props.applicantInformationForm.firstName, " ", this.props.applicantInformationForm.lastName)} />
            <SummaryItem text="Job Title" value={this.props.applicantInformationForm.title} />
            <SummaryItem
              text="Address"
              value={concatAll(this.props.applicantInformationForm.unit ? ` Unit ${this.props.applicantInformationForm.unit}`: ' ', " ", this.props.applicantInformationForm.street)}
            />
            <SummaryItem text="City" value={this.props.applicantInformationForm.city} />
            <SummaryItem text="Province" value={this.props.applicantInformationForm.province} />
            <SummaryItem text="Postal Code" value={this.props.applicantInformationForm.postalCode} />
            <SummaryItem
              text="Phone"
              value={concatAll(this.props.applicantInformationForm.phone, " ", this.props.applicantInformationForm.extension ?` ext ${this.props.applicantInformationForm.extension}`: " ")}
            />
            <SummaryItem text="Cell" value={this.props.applicantInformationForm.cell} />
            <SummaryItem text="Email" value={this.props.applicantInformationForm.email} />
          </div>
        </div>

        {this.props.contactInformationForm && (
          <div className="mb-4">
            <h5 style={styles.header}>Contact Information</h5>
            <div className="ml-0">
              <div>Emergency Contact</div>
              <SummaryItem text="Name" value={this.props.contactInformationForm.emergencyContact.name} />
              <SummaryItem text="Phone" value={this.props.contactInformationForm.emergencyContact.phone} />
              <SummaryItem text="Email" value={this.props.contactInformationForm.emergencyContact.email} />
            </div>
            {this.props.contactInformationForm.otherContacts && this.props.contactInformationForm.otherContacts.length > 0 && this.props.contactInformationForm.otherContacts[0].name !== null && (
              <div className="ml-0">
                <div>Other Contacts</div>
                {this.props.contactInformationForm.otherContacts.map((contact, index) => (
                  <>
                    <div className="ml-0 mt-3 mb-1" style={{ fontStyle: "italic" }}>
                      Contact #{index + 1}
                    </div>
                    <SummaryItem text="Name" value={contact.name} />
                    <SummaryItem text="Phone" value={contact.phone} />
                    <SummaryItem text="Email" value={contact.email} />
                  </>
                ))}
              </div>
            )}
          </div>
        )}

        {this.props.invoiceInformationForm && (
          <div className="mb-4">
            <h5 style={styles.header}>Invoice Information</h5>
            {this.props.invoiceInformationForm.invoiceSource === "applicant" ? (
              <div className="ml-0">Use applicant information for invoice</div>
            ) : (
              <>
                <SummaryItem text="Business Name" value={this.props.invoiceInformationForm.invoiceOwner} />
                <SummaryItem text="Business Title" value={this.props.invoiceInformationForm.invoiceTitle} />
                <SummaryItem
                  text="Address"
                  value={concatAll(this.props.invoiceInformationForm.invoiceUnit ? `Unit ${this.props.invoiceInformationForm.invoiceUnit}`: " ", " ", this.props.invoiceInformationForm.invoiceStreet)}
                />
                <SummaryItem text="Province" value={this.props.invoiceInformationForm.invoiceProvince} />
                <SummaryItem
                  text="Phone"
                  value={concatAll(
                    this.props.invoiceInformationForm.invoicePhone,
                    " ",
                    this.props.invoiceInformationForm.invoiceExtension ? ` ext ${this.props.invoiceInformationForm.invoiceExtension}`: " "
                  )}
                />
                <SummaryItem text="Email" value={this.props.invoiceInformationForm.invoiceEmail} />
              </>
            )}
          </div>
        )}

        {this.props.permitInformationForm && this.props.type !== "Municipal Consent" && (
          <div className="mb-4">
            <h5 style={styles.header}>Permit information</h5>
            <SummaryItem text="Occupancy Type" value={this.props.permitInformationForm.occupancyType} />
            <SummaryItem text="Sub Type" value={this.props.permitInformationForm.subtype} />
            <SummaryItem text="Restriction Type" value={this.props.permitInformationForm.restrictionType} />
            <SummaryItem text={conf.permitInformationForm.activityDetail} value={this.props.permitInformationForm.activityDetail} />
            {this.props.permitInformationForm.pavementCutRequired && <SummaryItem text="Pavement Cut Required" value={this.props.permitInformationForm.pavementCutRequired === 'yes' ? "Yes" : "No"} />}
            {this.props.permitInformationForm.subtype !== "Business (fees apply)" && (
              <SummaryItem
                text="Occupancy Dates"
                value={concatAll(
                  moment(this.props.permitInformationForm.occupancyFromDate).format("YYYY-MM-DD"),
                  " ",
                  "to ",
                  moment(this.props.permitInformationForm.occupancyToDate).format("YYYY-MM-DD")
                )}
              />
            )}
            {this.props.permitInformationForm.subtype === "Business (fees apply)" && (
              <SummaryItem text="Occupancy Start Date" value={moment(this.props.permitInformationForm.occupancyFromDate).format("YYYY-MM-DD")} />
            )}
            <SummaryItem text={conf.permitInformationForm.contractNumber} value={this.props.permitInformationForm.approvalContractNumber} />
            <SummaryItem text="Charity Registration Number" value={this.props.permitInformationForm.charityRegistrationNumber} />
            <SummaryItem text="Sign Type" value={this.props.permitInformationForm.signType} />
            <SummaryItem text="Sign Number" value={this.props.permitInformationForm.signNumber} />
            {/*TODO Load Number*/}
            <SummaryItem text="Load Number" value={this.props.permitInformationForm.loadNumber} />
            <SummaryItem text="Load Height (m)" value={this.props.permitInformationForm.loadHeight} />
            <SummaryItem text="Load Length (m)" value={this.props.permitInformationForm.loadLength} />
            <SummaryItem text="Load Width (m)" value={this.props.permitInformationForm.loadWidth} />
            <SummaryItem text="Load Weight (kg)" value={this.props.permitInformationForm.loadWeight} />
          </div>
        )}
        {this.props.encroachmentPermitInformationForm && (
          <div className="mb-4">
            <h5 style={styles.header}>Permit Information</h5>
            {this.props.applicantInformationForm.applicantSubtype === "owner" && (
              <>
                <SummaryItem text="Applicant Parcel Register (PIN)" value={this.props.encroachmentPermitInformationForm.applicantPIN} />
                <SummaryItem text="Applicant Legal Description" value={this.props.encroachmentPermitInformationForm.applicantLegalDescription} />
                <SummaryItem text="Address" value={this.props.encroachmentPermitInformationForm.address} />
                <SummaryItem text="Parcel Register (PIN)" value={this.props.encroachmentPermitInformationForm.parcelPIN} />
                <SummaryItem text="Region Legal Description" value={this.props.encroachmentPermitInformationForm.regionLegalDescription} />
                <SummaryItem text="Location" value={this.props.encroachmentPermitInformationForm.location} />
              </>
            )}
            {this.props.applicantInformationForm.applicantSubtype === "municipality" && (
              <>
                <SummaryItem text="Town / City" value={this.props.encroachmentPermitInformationForm.locationCity} />
                {(this.props.encroachmentPermitInformationForm.regionRoads || []).map((road, index) => {
                  return (
                    <SummaryItem
                      text={"Region road #" + (index + 1)}
                      value={concatAll(
                        road.locationRegionRoad,
                        " ",
                        road.locationFromRoad ? ` between ${road.locationFromRoad}` : "",
                        road.locationToRoad ? ` and ${road.locationToRoad}` : "",
                        road.locationDetail ? ` at ${road.locationDetail}` : ""
                      )}
                    />
                  );
                })}
              </>
            )}
            <SummaryItem text="Construction & Maintain" value={this.props.encroachmentPermitInformationForm.activityDetail} />
          </div>
        )}

        {this.props.locationInformationForm && (
          <div className="mb-4">
            <h5 style={styles.header}>{conf.locationInformationForm.title}</h5>
            <SummaryItem text="Town / City" value={this.props.locationInformationForm.locationCity} />
            <SummaryItem text="Street" value={this.props.locationInformationForm.street} />
            {this.props.locationInformationForm.regionRoads && Array.isArray(this.props.locationInformationForm.regionRoads) &&
              (this.props.locationInformationForm.regionRoads).map((road, index) => (
                <SummaryItem
                  text="Region road"
                  value={concatAll(
                    road.locationRegionRoad,
                    " ",
                    road.locationFromRoad ? ` between ${road.locationFromRoad}` : " ",
                    road.locationToRoad ? ` and ${road.locationToRoad}` : " ",
                    road.locationDetail ? ` at ${road.locationDetail}` : " "
                  )}
                />
              ))
            }
            {/*{this.props.type === "Road Occupancy Application - Temporary Road Sign" && (*/}
              <>
                <SummaryItem text={conf.locationInformationForm.streetAddress} value={this.props.locationInformationForm.streetAddress} />
                <SummaryItem text="Activity description" value={this.props.locationInformationForm.activityDetail} />
              </>
            {/*)}*/}
            <SummaryItem text="Client Number" value={this.props.locationInformationForm.clientNumber} />
          </div>
        )}

        {this.props.routeInformationForm &&
          (this.props.permitInformationForm.subtype !== 'Excess Load Annual') && (
          <div className="mb-4">
            <h5 style={styles.header}>Route Information</h5>
            <SummaryItem text="Start point in region" value={concatAll(this.props.routeInformationForm.startRegionRoad, " starting at ", this.props.routeInformationForm.startLocationDetail)} />
            <SummaryItem text="End point in region" value={concatAll(this.props.routeInformationForm.endRegionRoad, " ending at ", this.props.routeInformationForm.endLocationDetail)} />
            {(this.props.routeInformationForm.regionRoads || []).map((road, index) => (
              <SummaryItem text="Region road" value={road.locationDetail} />
            ))}
          </div>
        )}

        {this.props.trafficManagementPlanForm && (
          <div className="mb-4">
            <h5 style={styles.header}>Traffic Management Plan(s)</h5>
            <SummaryItem text="Is a custom traffic and detour plan required?" value={this.props.trafficManagementPlanForm.planIncludedInDrawings ? "Yes" : "No"} />
            {this.props.trafficManagementPlanForm.plans.map((plan, planIndex) => (
              <div className="ml-0">
                <div className="font-weight-bold">Plan #{planIndex + 1}</div>
                <div className="ml-0">
                  <SummaryItem text="Zone Required" value={plan.zoneRequired} />
                  <SummaryItem
                    text="Dates Permitted:"
                    value={concatAll(moment(plan.dateFrom).format("YYYY-MM-DD"), " to ", moment(plan.dateTo).format("YYYY-MM-DD"), plan.datesInclusive ? " inclusive " : " ")}
                  />
                  <SummaryItem text="OTM Book 7 Figure ID" value={plan.figureId} />
                  {plan.timesPermitted && plan.zoneRequired.includes("Road Allowance") && plan.timesPermitted.filter((timePermitted) => timePermitted.days).length > 0 && (
                    <>
                      <SummaryItem
                        text="Times Permitted"
                        value={plan.timesPermitted
                          .filter((timePermitted) => timePermitted.days)
                          .map((timePermitted, key) => (
                            <HeadlessSummaryItem
                              value={concatAll(timePermitted.days, " ", moment(timePermitted.fromTime).format("h:mm a"), " to ", moment(timePermitted.ToTime).format("h:mm a"))}
                            />
                          ))}
                      />
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {this.props.supportingDocumentsForm && (
          <div className="mb-4">
            <h5 style={styles.header}>Supporting Documents</h5>
            {this.props.supportingDocumentsForm.drawings && (
              <SummaryItem text="Uploaded Drawing" value={this.props.supportingDocumentsForm.drawings.name || this.props.supportingDocumentsForm.drawings[0].fileName} />
            )}
            {this.props.supportingDocumentsForm.insurance && (
              <SummaryItem text="Insurance Certificate" value={this.props.supportingDocumentsForm.insurance.name || this.props.supportingDocumentsForm.insurance[0].fileName} />
            )}
            {this.props.supportingDocumentsForm.plan && (
              <SummaryItem text="Custom Traffic and Detour Plan" value={this.props.supportingDocumentsForm.plan.name || this.props.supportingDocumentsForm.plan[0].fileName} />
            )}
            {this.props.supportingDocumentsForm.townLetter && (
              <SummaryItem text="Town Letter" value={this.props.supportingDocumentsForm.townLetter.name || this.props.supportingDocumentsForm.townLetter[0].fileName} />
            )}
            {this.props.supportingDocumentsForm.loadDistributionDiagram && (
              <SummaryItem
                text="Load Distribution Diagram"
                value={this.props.supportingDocumentsForm.loadDistributionDiagram.name || this.props.supportingDocumentsForm.loadDistributionDiagram[0].fileName}
              />
            )}
            {this.props.supportingDocumentsForm.workLocation && (
              <SummaryItem text="Work Location Map" value={this.props.supportingDocumentsForm.workLocation.name || this.props.supportingDocumentsForm.workLocation[0].fileName} />
            )}
            {this.props.supportingDocumentsForm.otherDocuments && this.props.supportingDocumentsForm.otherDocuments.length > 0 && (
              <SummaryItem
                text="Other Documents"
                value={this.props.supportingDocumentsForm.otherDocuments.map((doc, index) => (
                  <HeadlessSummaryItem value={doc.name || doc.fileName} />
                ))}
              />
            )}
            {this.props.type === "Municipal Consent" && (
              <>
                <SummaryItem
                  text="Has the municipal consent application submission requirements been reviewed?"
                  value={this.props.supportingDocumentsForm.drawingRequirementsReviewed ? "Yes" : "No"}
                />
                <SummaryItem text="Is the work for a York Region capital delivery project?" value={this.props.supportingDocumentsForm.drawingRequirementsReviewed} />

                {this.props.supportingDocumentsForm.capitalDeliveryProject === "yes" && (
                  <div className="ml-0">
                    <SummaryItem
                      text="Name of the project manager"
                      value={concatAll(this.props.supportingDocumentsForm.projectManagerFirstName, " ", this.props.supportingDocumentsForm.projectManagerLastName)}
                    />
                  </div>
                )}
                <SummaryItem text="Does the design follow the York Region utility corridor drawings standard?" value={this.props.supportingDocumentsForm.drawingsStandardFollowed} />
                {this.props.supportingDocumentsForm.drawingsStandardFollowed === "no" && (
                  <div className="ml-0">
                    <SummaryItem text="Reason for not following drawings standard:" value={this.props.supportingDocumentsForm.drawingsStandardNotFollowedReason} />
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {this.props.feeInformationForm && (
          <div className="mb-4">
            <h5 style={styles.header}>Fees</h5>
            <div className="ml-0">
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.feeInformationForm.baseFeeMsg || null,
                  }}
                ></div>
                <div className="mb-3">Subsequent fees will be calculated based on the information entered in this permit request application.</div>
              </div>
            </div>
            {(this.props.feeInformationForm.numberOfDays || this.props.feeInformationForm.numberOfLanes) && (
              <div className="ml-0">
                <div>Road / Lane Closures</div>
                <SummaryItem text="Number of Days" value={this.props.feeInformationForm.numberOfDays} />
                <SummaryItem text="Number of Lanes" value={this.props.feeInformationForm.numberOfLanes} />
              </div>
            )}
            {(this.props.feeInformationForm.totalLength || this.props.feeInformationForm.totalWidth || this.props.feeInformationForm.numberOfLocations) && (
              <div className="ml-0">
                <div>Pavement Degradation</div>
                <SummaryItem text="Total Length (m)" value={this.props.feeInformationForm.totalLength} />
                <SummaryItem text="Total Width (m)" value={this.props.feeInformationForm.totalWidth} />
                <SummaryItem text="Number of Locations" value={this.props.feeInformationForm.numberOfLocations} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default PermitRequestDetails;
