import React, {Component} from 'react';

class Logout extends Component {
    render(){
        return (
            <h3>You have been successfully logged out</h3>
        )
    }
}

export default Logout;