import Spinner from "./Spinner";
import Modal from 'react-bootstrap/Modal';
import PermitRequestDetails from './PermitRequestDetails';
import React, {useState, useEffect} from "react";
import { NavLink } from 'react-router-dom';
import {permitNameMapping} from "../components/config/formConfiguration";
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { MonerisContext } from "./MonerisProvider";
import { UserContext } from "./UserContext";

const moment = require("moment");
const axios = require("axios");

// note: this is a hooks component
const PartialPaymentModal = (props) => {
  const [maxPaymentAmount, setMaxPaymentAmount] = useState(props.partialPaymentType === 'Fee' ? props.maxFeePaymentAmount : props.maxSecurityPaymentAmount);
  const [partialPaymentAmount, setPartialPaymentAmount] = useState(0);
  const [partialPaymentAmountDirty, setPartialPaymentAmountDirty] = useState(false);
  
  useEffect(() => {
    // monerisContext.setPartialPaymentAmount(null);
    setPartialPaymentAmount(null);
    setPartialPaymentAmountDirty(false);
  },[props.partialPaymentType]);

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      if(partialPaymentAmountValid()){
        props.handleNext(partialPaymentAmount);
      }
      event.preventDefault();
    }
  }

  const partialPaymentAmountValid = () => {
    let parsedAmount = partialPaymentAmount ? partialPaymentAmount.replace(/[$,]+/g,"") : null;
    return parsedAmount && parsedAmount > 0 && parsedAmount <= maxPaymentAmount;
  }

  return (
    <Modal show={props.partialPaymentType} onHide={props.handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Partial {props.partialPaymentType} Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Use the partial payment function if you want to split your payment across multiple credit cards. 
        </p>
      <form>
        <div className="form-group">
          <label for="paymentAmount">Payment Amount (maximum: ${maxPaymentAmount})</label>
          <MaskedInput
            value={partialPaymentAmount}
            onChange={(e) => {setPartialPaymentAmount(e.target.value); setPartialPaymentAmountDirty(true);}}
            id="paymentAmount"
            className={`form-control${!partialPaymentAmountValid() && partialPaymentAmountDirty ? ' is-invalid' : ''}`}
            mask={createNumberMask({
              prefix: '$',
              allowDecimal: true
            })}
            placeholder="$0.00"
            onKeyPress={handleKeyPress}
          />
          <div className="invalid-feedback">Please enter an amount no greater than ${maxPaymentAmount}</div>
        </div>
      </form>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" disabled={!partialPaymentAmountValid()} onClick={() => props.handleNext(partialPaymentAmount)}>Next Step: Payment Information</button>
      </Modal.Footer>
    </Modal>
  )
}
class PermitRequest extends React.Component {
  paySecurityDropdownRef = React.createRef();
  payFeeDropdownRef = React.createRef();
  state = { loading: true, partialPaymentType: null };

  componentDidMount() {
    if (this.props.match && this.props.match.params.permitId) {
      axios
        .get(`/api/permit/${this.props.match.params.permitId}`, {
          headers: { Pragma: "no-cache" },
        })
        .then((response) => {
          if (response.data) {
            this.setState({ ...response.data });
            let feeTotal = Math.round(response.data.fees.reduce((sum, fee) => sum + fee.AMOUNT, 0) * 100) / 100;
            this.setState({feeTotal: feeTotal});
            this.setState({feePaymentTotal: Math.round(response.data.feePayments.reduce((sum, payment) => sum + (payment.PAYMENT_AMOUNT || 0), 0) * 100) / 100 });

            // calculate securities
            let securityTotal = Math.round(response.data.securities.reduce((sum, fee) => sum + fee.AMOUNT, 0) * 100) / 100;
            let securityPaymentTotal = Math.round(response.data.securityPayments.filter(payment => payment.result !== 'd').reduce((sum, payment) => sum + payment.PAYMENT_AMOUNT || 0, 0) * 100) / 100;
            let securityReleaseTotal = Math.round(response.data.securityReleases ? response.data.securityReleases.reduce((sum, securityRelease) => sum + securityRelease.AMOUNT_RELEASED, 0) : 0 * 100) / 100;
            this.setState({securityOwing: Math.round((securityTotal - securityPaymentTotal) * 100) / 100});
            this.setState({securityPaymentTotal});
            this.setState({securityReleaseTotal});
            this.setState({loading: false});
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
        });
    } else {
      this.setState({ ...this.props, loading: false });
    }

    // handle click outside dropdowns
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleCloneButtonClick = async (permit) => {
    let result = await axios.get(`/api/clonepermit/${permit.id}`)
    window.location = `/request/${permitNameMapping[permit.type]}/continue`;
  }

  handleRenewalButtonClick = (permit) => {
    window.location = `/renewal/${permit.id}`;
  }

  toggleDropdown = (dropdownRef) => {
    dropdownRef.classList.toggle('show');
  }

  handleClickOutside = (event) => {
    if (this.paySecurityDropdownRef.current && !this.paySecurityDropdownRef.current.contains(event.target)) {
      this.paySecurityDropdownRef.current.classList.remove('show');
    }
    if (this.payFeeDropdownRef.current && !this.payFeeDropdownRef.current.contains(event.target)) {
      this.payFeeDropdownRef.current.classList.remove('show');
    }
  }

  handlePartialPayNext = (paymentType) => {
    if(paymentType === 'Security'){
      this.props.history.push(`/paysecurity/${this.state.id}`)
    }else if(paymentType === 'Fee'){
      this.props.history.push(`/payfee/${this.state.id}`)
    }
  }

  render() {
    return (
      <UserContext.Consumer>{ userContext => 
      <div className="shade-background">
        {this.state.loading ? (
          <Spinner />
        ) : this.state.type ? (
          <div>
            {this.state.id && (
              <>
              <div style={{display:'none'}}>{ `${this.state.cityworksCaseId} ${this.state.cityworksCaseNumber}` }</div>
              {/* ACTION BUTTONS */}
              <MonerisContext.Consumer>{monerisContext => 
              <div className="d-flex flex-row">
                { this.state.feeTotal - this.state.feePaymentTotal > 0 && this.state.status === 'Ready for Payment' && this.state.onlinePaymentEnabled &&
                  <div className="m-2">
                    <button type="button" className="btn btn-primary" onClick={() => {monerisContext.setPartialPaymentAmount(null); this.props.history.push(`/payfee/${this.state.id}`);}}>Pay Fees</button>
                  </div>
                }
                { this.state.securityOwing > 0 && this.state.status === 'Ready for Payment' && this.state.onlinePaymentEnabled && 
                  <div className="m-2">
                    <div className="btn-group" role="group">
                      <button type="button" className="btn btn-primary" onClick={() => {monerisContext.setPartialPaymentAmount(null); this.props.history.push(`/paysecurity/${this.state.id}`);}}>Pay Security Deposit</button>
                      <div className="btn-group" role="group">
                        <button type="button" className="btn btn-outline-primary dropdown-toggle" onClick={() => this.toggleDropdown(this.paySecurityDropdownRef.current)}></button>
                        <div id="paySecurityDropdown" ref={this.paySecurityDropdownRef} className="dropdown-menu">
                          <button type="button" className="dropdown-item btn btn-link" onClick={() => this.setState({partialPaymentType:'Security'})}>Pay Partial Security</button>
                        </div>
                      </div>
                    </div>
                  </div>}

                <PartialPaymentModal 
                  handleClose={() => this.setState({partialPaymentType:null})} 
                  maxFeePaymentAmount={this.state.feeTotal - this.state.feePaymentTotal} 
                  maxSecurityPaymentAmount={this.state.securityOwing} 
                  partialPaymentType={this.state.partialPaymentType}
                  handleNext={(partialPaymentAmount) => { monerisContext.setPartialPaymentAmount(partialPaymentAmount); this.handlePartialPayNext(this.state.partialPaymentType);}}/>

                <div className="p-2">
                  { this.state && this.state.id &&
                  <button type="button" className="btn btn-primary" onClick={() => { this.handleCloneButtonClick(this.state) }}>Clone Permit</button>
                  }
                </div>
                <div className="p-2">
                  { this.state && this.state.id &&
                    ['Issued'].includes(this.state.status) &&
                    this.state.occupancyToDate &&
                    this.state.occupancyToDate !== "" &&
                    moment.duration(moment(this.state.occupancyToDate).diff(new moment())).asDays() >= 0 &&
                    this.state.revisionNumber < 5 &&
                    !(this.state.type === "Road Occupancy Application - Excess Load" && this.state.permitInformationForm?.subtype === "Excess Load Annual") &&
                    <button type="button" className="btn btn-primary" onClick={() => { this.handleRenewalButtonClick(this.state) }}>Request Renewal</button>
                  }
                </div>
              </div>
              }</MonerisContext.Consumer>
              {/* HEADER */}
              <div className="mb-1 p-1" >
                <div className="d-flex flex-row bd-highlight">
                  <div className="p-2 bd-highlight">
                    <h5 style={{fontWeight:'bold'}}>
                      {this.state.type} #{this.state.id} {this.state.revisionNumber > 0 && `(Revision ${this.state.revisionNumber})`}
                    </h5>
                    <p>Submitted on {moment(this.state.dateSubmitted).format("YYYY-MM-DD h:mma")}</p>
                    <h5>
                      Status: {this.state.status}
                    </h5>
                  </div>
                </div>
              </div>
              </>
            )}

            {/* do not show payment info for MC permit type */}
            <div className="mx-2 row">
            { this.state.type !== 'Municipal Consent' && ((this.state.fees && this.state.fees.length > 0) || (this.state.securities && this.state.securities.length > 0)) &&  <div className="col-lg-6 col-xl-5">
              {/* FEES */}
              { this.state.fees && this.state.fees.length > 0 && <div className="card p-3 mb-3" style={{backgroundColor:'white'}}>
                <div className="mb-2"><h4>FEES</h4></div>
                { this.state.onlinePaymentEnabled && <p className="text-muted text-wrap">You can pay your fees in full using our online payment option when the status is "Ready for Payment"</p> }
                { this.state.feeThresholdReached && <p className="text-muted text-wrap">Due to the total amount, we are unfortunately not able to accept payment online. Please contact <a href="mailto:permits@york.ca">permits@york.ca</a> to arrange for payment.</p> }
                { !this.state.feeThresholdReached && !this.state.onlinePaymentEnabled && <p className="text-muted text-wrap">Online payment is unavailable. Please contact <a href="mailto:permits@york.ca">permits@york.ca</a> to arrange for payment.</p> }
                <div className="p-4">
                  {/* FEES CHARGED */}
                  <div className="mb-4">
                  {this.state.fees.map(fee => 
                    <div className="row justify-content-end" >
                      <div className="col text-right text-muted">{fee.FEE_DESC}</div>
                      <div className="col text-right" style={{fontSize:'1.25rem'}}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(fee.AMOUNT)}</div>
                    </div>
                  )}
                  </div>
                  {/* PAYMENTS */}
                  <div className="mb-4">
                  {this.state.feePayments.map(payment => 
                    <div className="row justify-content-end">
                      <div className={`col text-right text-muted`}><NavLink to={`/myrequests/${this.state.id}/${payment.result === 'd' ? `declined-payments/${payment.orderNumber}` : `payments/${payment.CA_RECEIPT_ID}`}`} className={payment.result === 'd' ? 'text-danger' : ''} activeClassName="active" exact >{payment.result === 'd' ? 'Declined payment on' : 'Payment on'} {new Intl.DateTimeFormat("en-CA", {dateStyle: "short"}).format(new Date(payment.PAYMENT_DATE || payment.transactionTimestamp))}</NavLink></div>
                      <div className={`col text-right ${payment.result === 'd' ? 'text-danger' : ''}`} style={{fontSize:'1.25rem'}}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(payment.PAYMENT_AMOUNT || payment.amount)}</div>
                    </div>
                  )}
                  </div>
                  {/* TOTAL */}
                  <div className="row justify-content-end mt-2">
                    <div className="col text-right text-muted">Total Amount Owing</div>
                    <div className="col text-right font-weight-bold" style={{color:this.state.feeTotal - this.state.feePaymentTotal <= 0 ? 'green' : 'red', fontSize:'1.25rem'}}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(this.state.feeTotal - this.state.feePaymentTotal >= 0 ? this.state.feeTotal - this.state.feePaymentTotal : 0)}</div>
                  </div>
                </div>
              </div> }
              {/* SECURITIES */}
              {/* do not show for MC permit type */}
              { this.state.securities && this.state.securities.length > 0 && 
              <div className="card p-3 mb-3" style={{backgroundColor:'white'}}>
                <div className="mb-2"><h4>SECURITIES</h4></div>
                { this.state.onlinePaymentEnabled && <p className="text-muted text-wrap">You can deposit the security using our online payment option when the status is "Ready for Payment"</p> }
                { this.state.feeThresholdReached && <p className="text-muted text-wrap">Online payment is not available due to the combined total amounts exceeding our threshold of ${this.state.feeThreshold}</p> }
                <div className="p-4">
                  {/* SECURITY REQUIRED */}
                  <div className="mb-4">
                  {this.state.securities.map(security => 
                    <div className="row justify-content-end">
                      <div className="col text-right text-muted">Required Security Deposit</div>
                      <div className="col text-right" style={{fontSize:'1.25rem'}}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(security.AMOUNT)}</div>
                    </div>
                  )}
                  </div>
                  {/* DEPOSITS */}
                  <div className="mb-4">
                  {this.state.securityPayments.map(payment => 
                    <div className="row justify-content-end">
                      <div className={`col text-right text-muted`}><NavLink to={`/myrequests/${this.state.id}/${payment.result === 'd' ? `declined-payments/${payment.orderNumber}` : `payments/${payment.CA_RECEIPT_ID}`}`} className={payment.result === 'd' ? 'text-danger' : ''} activeClassName="active" exact >{payment.result === 'd' ? 'Declined deposit on' : 'Deposit on'} {new Intl.DateTimeFormat("en-CA", {dateStyle: "short"}).format(new Date(payment.PAYMENT_DATE || payment.transactionTimestamp))}</NavLink></div>
                      <div className={`col text-right ${payment.result === 'd' ? 'text-danger' : ''}`} style={{fontSize:'1.25rem'}}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(payment.PAYMENT_AMOUNT || payment.amount)}</div>
                    </div>
                  )}
                  </div>
                  {/* TOTAL OWING */}
                  <div className="mb-4">
                    <div className="row justify-content-end mt-2">
                      <div className="col text-right text-muted">Total Amount Owing</div>
                      <div className="col text-right font-weight-bold" style={{color:this.state.securityOwing === 0 ? 'green' : 'red', fontSize:'1.25rem'}}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(this.state.securityOwing)}</div>
                    </div>
                  </div>
                  {/* RELEASES */}
                  <div className="mb-4">
                  {this.state.securityReleases.map(securityRelease => 
                    <div className="row justify-content-end">
                      <div className="col text-right text-muted">Released on {new Intl.DateTimeFormat("en-CA", {dateStyle: "short"}).format(new Date(securityRelease.DATE_RELEASED))}</div>
                      <div className="col text-right" style={{fontSize:'1.25rem'}}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(securityRelease.AMOUNT_RELEASED)}</div>
                    </div>
                  )}
                  </div>
                  {/* TOTAL HELD SECURITIES */}
                  <div className="row justify-content-end mt-2">
                    <div className="col text-right text-muted">Total Held Securities</div>
                    <div className="col text-right font-weight-bold" style={{fontSize:'1.25rem'}}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(this.state.securityPaymentTotal - this.state.securityReleaseTotal)}</div>
                  </div>
                </div>
              </div> }
            </div> }
            {/* PERMIT REQUEST DETAILS */}
            <div className="col">
            <div className="card p-3 mb-3" style={{backgroundColor:'white'}}>
              <PermitRequestDetails {...this.state}/>
            </div>
            </div>
            </div>
          </div>
        ) : (
          <h5>Cannot find permit with # {this.props.match && this.props.match.params.permitId}</h5>
        )}
      </div>
      }</UserContext.Consumer>
    );
  }
}

export default PermitRequest;
