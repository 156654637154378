import React from 'react';
import FormWizardField from '../../form-wizard/FormWizardField';
import Spinner from '../../Spinner';
const {config} = require('../../config/formConfiguration');
const axios = require('axios');

class PermitInformationForm extends React.Component {
    state = {permitTypeMapping:null, loading: true}

    componentDidMount(){
        axios.get(`/api/getPermitConfig/${this.props.type}`, {headers: { Pragma: 'no-cache'}}).then((response) => {
            this.setState({loading: false});
            if (response.data) {
                this.setState({permitTypeMapping: response.data});
                // force occupancy type if only 1
                if(response.data.occupancyTypes && response.data.occupancyTypes.length === 1){
                    this.props.handleFieldChange({target:{id:'occupancyType', value:response.data.occupancyTypes[0].name}});
                }
            }
        });
    }

    render(){
        // UI parts
        const loadDimensions_fragment = (
         <div className="form-group">
             <label htmlFor="title">Size of load</label>
             <div className="form-row">
                 <div className="col-lg-6">
                     <FormWizardField fieldType="input" fieldName="loadNumber"
                                      fieldLabel="Number of Loads" {...this.props} required={true}/>
                 </div>
             </div>
             <div className="form-row">
                 <div className="col-lg-3 col-sm-6">
                     <FormWizardField fieldType="input" fieldName="loadHeight"
                                      fieldLabel="Height (m)" {...this.props} required={true}/>
                 </div>
                 <div className="col-lg-3 col-sm-6">
                     <FormWizardField fieldType="input" fieldName="loadLength"
                                      fieldLabel="Length (m)" {...this.props} required={true}/>
                 </div>
                 <div className="col-lg-3 col-sm-6">
                     <FormWizardField fieldType="input" fieldName="loadWidth"
                                      fieldLabel="Width (m)" {...this.props} required={true}/>
                 </div>
                 <div className="col-lg-3 col-sm-6">
                     <FormWizardField fieldType="input" fieldName="loadWeight"
                                      fieldLabel="Weight (kg)" {...this.props} required={true}/>
                 </div>
             </div>
         </div>
        );
        const dates_fragment = (
        <div className="form-group">
            <label htmlFor="unit">Occupancy Dates Required</label>
            <div className="form-row">
                <div className="col-lg-4">
                    <FormWizardField fieldType="date" fieldName="occupancyFromDate" fieldLabel="From" required={true} {...this.props} />
                </div>
                <div className="col-lg-4">
                    <FormWizardField fieldType="date" fieldName="occupancyToDate" minDate={this.props.data.occupancyFromDate} fieldLabel="To" required={true} {...this.props} />
                </div>
            </div>
        </div>
        );
        const startDate_fragment = (
         <div className="form-group">
             <div className="form-row">
                 <div className="col-lg-4">
                     <FormWizardField fieldType="date" fieldName="occupancyFromDate" fieldLabel="Start Date" required={true} {...this.props} />
                 </div>
             </div>
         </div>
        );
        let subtypes = this.props.data.occupancyType && this.state.permitTypeMapping ? this.state.permitTypeMapping.occupancyTypes.filter(occupancyType => occupancyType.name === this.props.data.occupancyType)[0].subtypes : null;

        let conf = config(this.props);
        console.log(conf);

        return (
            <div>
                {
                    this.state.loading ? <Spinner /> : <>
                    <h5>Permit Information</h5>
                    <p><span style={{"color":"red"}}>*</span> indicates a required field</p>
                    <form>
                        <div style={{display: (this.state.permitTypeMapping && this.state.permitTypeMapping.occupancyTypes.length > 1)?'block':'none'}} >
                        { this.state.permitTypeMapping && <FormWizardField fieldType="select" fieldName="occupancyType" fieldLabel={conf.occupancyTypeName} required={true} {...this.props} tooltip={this.state.permitTypeMapping.tooltip} >
                            { this.state.permitTypeMapping.occupancyTypes.length > 1 && <option key=''></option> }
                            {
                                this.state.permitTypeMapping.occupancyTypes.map(occupancyType =>
                                    <option key={occupancyType.name}>{occupancyType.name}</option>
                                )
                            }
                        </FormWizardField>
                        }
                        </div>
                        { subtypes && subtypes.length > 0 && <FormWizardField fieldType="select" fieldName="subtype" fieldLabel={conf.subtypeLabel} required={true} {...this.props}>
                                <option key=''></option>
                                {
                                    this.props.data.occupancyType ?
                                    this.state.permitTypeMapping.occupancyTypes.filter(occupancyType => occupancyType.name === this.props.data.occupancyType)[0].subtypes.map(subtype =>
                                        <option key={subtype.name}>{subtype.name}</option>
                                    ) : null
                                }
                            </FormWizardField>
                        }

                        {conf.restrictionType &&
                        <FormWizardField fieldType="select" fieldName="restrictionType" fieldLabel="Restriction Type" required={true} {...this.props}>
                            <option key=''></option>
                            {
                                 conf.restrictionType.map(subtype =>
                                  <option key={subtype.name}>{subtype.name}</option>
                                 )
                            }
                        </FormWizardField>

                        }

                        {/* <div className="form-group">
                            <label>Permit Description</label>
                            <p>{ this.props.data.subtype ? permitTypeMapping[this.props.permitType].occupancyTypes.filter(occupancyType => occupancyType.name === this.props.data.occupancyType)[0].subtypes.filter(subtype => subtype.name === this.props.data.subtype)[0].description : null}</p>
                        </div>*/}

                        <hr />

                        <FormWizardField fieldType="input" fieldName="activityDetail" fieldLabel={conf.activityDetail} required={true} placeholder={conf.activityDetailPlaceholder} maxLength={conf.activityDetailMaxLength} {...this.props} />
                        {
                            conf.pavementCut  &&  <FormWizardField fieldType="radio" fieldName="pavementCutRequired" fieldLabel="Is a pavement cut required to do the work?" required={true} radioOptions={[{label:'Yes', value:'yes'}, {label:'No', value: 'no'}]} {...this.props} />
                        }
                        { conf.loadDimensions && loadDimensions_fragment }
                        {    ["Road Occupancy Application - Load Exemption"].includes(this.props.type) && (
                         <FormWizardField fieldType="input" fieldName="loadNumber" fieldLabel="Number of loads anticipated" {...this.props} required={true}/>
                        )}
                        { conf.charityNumber &&  <FormWizardField fieldType="input" fieldName="charityRegistrationNumber" fieldLabel="Charity Registration Number" {...this.props}  maxLength="40"/>}
                        { conf.dates && dates_fragment }
                        { conf.startDate && startDate_fragment}

                        {
                            this.props.data.occupancyType === 'New Installation' && <FormWizardField fieldType="input"  mask={['M','C', /\d/, /\d/,  '-',/\d/, /\d/, /\d/, /\d/, /\d/]} guide={true} fieldName="approvalContractNumber" fieldLabel="Municipal Consent Approval / Contract Number" {...this.props} required={true} maxLength="40"/>
                        }
                        {
                            conf.contractNumber && <FormWizardField fieldType="input" fieldName="approvalContractNumber"  fieldLabel={conf.contractNumber} {...this.props} required={conf.contractNumberRequired} maxLength="40"/>
                        }
                        {
                            this.props.type === "Road Occupancy Application - Temporary Road Sign" &&
                            <>
                            <FormWizardField fieldType="select" fieldName="signType" fieldLabel="Sign Type"
                                             required={true} {...this.props}>
                                <option key=''></option>
                                <option key='"A" Frame'>"A" Frame</option>
                                <option key='Banner'>Banner</option>
                                <option key='Fascia'>Fascia</option>
                                <option key='Poster with Stakes'>Poster with Stakes</option>
                                <option key='Ground'>Ground</option>
                                <option key='Pylon'>Pylon</option>
                                <option key='Sign with post'>Sign with post</option>
                                <option key='Other'>Other</option>
                            </FormWizardField>
                            <FormWizardField fieldType="input" fieldName="signNumber" fieldLabel="Number required" {...this.props} required={true}/>
                            </>
                        }
                    </form>
                </>
            }
            </div>
        )
    }
}

export default PermitInformationForm;