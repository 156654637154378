import React, {Component} from 'react';
import FormWizard from '../form-wizard/FormWizard';
import FormWizardStep from '../form-wizard/FormWizardStep';
import ApplicantInformationForm from './road-permit-steps/ApplicantInformationForm';
import InvoiceInformationForm from './road-permit-steps/InvoiceInformationForm';
import LocationInformationForm from './road-permit-steps/LocationInformationForm';
import SupportingDocumentsForm from './road-permit-steps/SupportingDocumentsForm';
import SummaryForm from './road-permit-steps/SummaryForm';
import FormWizardResult from '../form-wizard/FormWizardResult';
import PermitResultForm from './road-permit-steps/PermitResultForm';
import FeeInformationForm from "./road-permit-steps/FeeInformationForm";

class MunicipalConsent extends Component {
    render(){
        return (
            <div>
                <FormWizard title="Application for Municipal Consent" type="Municipal Consent" apiEndpoint="/api/permit" relativeUrl={this.props.permitType} {...this.props}>
                    <FormWizardStep component={ApplicantInformationForm} name="applicantInformationForm" label="Applicant Information" />
                    <FormWizardStep component={InvoiceInformationForm} name="invoiceInformationForm" label="Invoice Information" />
                    <FormWizardStep component={LocationInformationForm} name="locationInformationForm" label="Location / Activity" />
                    <FormWizardStep component={SupportingDocumentsForm} name="supportingDocumentsForm" label="Supporting Documents" />
                    <FormWizardStep component={FeeInformationForm} name="feeInformationForm" label="Fees" />
                    <FormWizardStep component={SummaryForm} name="summaryForm" label="Summary" />
                    <FormWizardResult name="result" component={PermitResultForm} />
                </FormWizard>
            </div>
        );
    }
}

export default MunicipalConsent;