import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import packageJSON from '../../package.json';

class Header extends Component {
    state = {showHelp:false, showComments: false, commentForm: {complete: false}};

    hideUserMenu = () => {
        document.querySelector('#userMenu').classList.remove('show');
    }

    toggleUserMenu = () => {
        document.querySelector('#userMenu').classList.toggle('show');
    }

    handleHelpClose = () => {
        this.setState({showHelp: false});
    }

    handleCommentsClose = () => {
        this.setState({showComments: false});
    }

    handleCommentsClick = async() => {
     this.setState({showComments: false});
     try {
      await axios.post('/api/sendComment', this.state.commentForm);
     }
     catch (e) {
      console.log(e);

     }

    }

    handleCommentsChange = (index, value) => {
     let result = Object.assign({}, this.state);
     result.commentForm[index] = value.currentTarget.value;

     result.commentForm.complete = (
      (result.commentForm.fullName !== '' && result.commentForm.fullName !== undefined) &&
      (result.commentForm.email !== '' && result.commentForm.email !== undefined) &&
      (result.commentForm.yourMessage !== null && result.commentForm.yourMessage !== undefined) &&
      (result.commentForm.phone !== null && result.commentForm.phone !== undefined))


     this.setState(result);

    }

    render() {
        let notificationCount = 0;
        if(this.props.user && this.props.user.profile){
            notificationCount += this.props.user.profile.membershipRequests ? this.props.user.profile.membershipRequests.length : 0;
            notificationCount += this.props.user.profile.membershipInvites ? this.props.user.profile.membershipInvites.length : 0;
        }
        let notificationCountAttribute = notificationCount > 0 ? {"data-count":notificationCount} : {};

        return (
          <>
            <header>
              <div className="navButton">
                <i className="fas fa-bars likebutton" onClick={this.props.opennav}></i>
              </div>
              <h1>Road Permit Requests</h1>
              {process.env.REACT_APP_ENV_MSG && (
                <div style={{ position: "absolute", width: "100%", textAlign: "center", top: "0px", color: "#ff6600", zIndex: "-1", fontWeight: "bold", fontSize: "1.25rem", letterSpacing: "8px" }}>
                  {process.env.REACT_APP_ENV_MSG}
                </div>
              )}
              {/*this.props.user && this.props.user.profile && (this.props.user.profile.membershipRequests || this.props.user.profile.membershipInvites) && (
                    <div className="icon likebutton" style={{position:'relative',bottom:'10px'}} title="Notifications">
                        <NavLink id="notifications" to="/mynotifications" exact>
                            <i class="fas fa-bell"></i>
                            <div style={{position:'relative',fontSize:'0.5em',bottom:'8px'}}>Notifications</div>
                        </NavLink>
                    </div>
                ) */}
              {this.props.user ? (
                <div className="icon mr-2" {...notificationCountAttribute}>
                  <div id="userMenuIcon" onClick={this.toggleUserMenu}>
                    <i className="fas fa-user"></i>
                    <div className="icon-label" style={{ position: "relative", fontSize: "0.5em", bottom: "8px" }}>My Account</div>
                  </div>
                  <div id="userMenu" className="dropdown-menu dropdown-menu-right">
                    {/* <div className="dropdown-item-text text-white-50 text-nowrap bg-secondary">Logged in as {this.props.user.profile.firstName} {this.props.user.profile.lastName}</div> */}
                    <NavLink id="profile" to="/myprofile" className="dropdown-item text-reset" exact onClick={this.hideUserMenu}>
                      Profile
                    </NavLink>
                    <NavLink id="profile" to="/mynotifications" className="dropdown-item text-reset" {...notificationCountAttribute} exact onClick={this.hideUserMenu}>
                      Notifications
                    </NavLink>
                    <div className="dropdown-item text-reset" onClick={this.props.logout}>
                      Logout
                    </div>
                  </div>
                </div>
              ) : (
                <div className="icon likebutton" data-tip="Login to York Region" onClick={this.props.login}>
                  {/* <a href={signInUrl}> */}
                  <i className="fas fa-sign-in-alt"></i>
                  <div className="icon-label" style={{ position: "relative", fontSize: "0.5em", bottom: "8px" }}>Login</div>
                  {/* </a> */}
                </div>
              )}
              <div className="icon mr-2 likebutton" style={{ marginLeft: "5px", marginRight: "5px" }} onClick={() => this.setState({ showComments: true })}>
                <i className="fa fa-commenting-o"></i>
                <div className="icon-label" style={{ position: "relative", fontSize: "0.5em", bottom: "8px" }}>Feedback</div>
              </div>
              <div className="icon mr-2 likebutton" onClick={() => this.setState({ showHelp: true })}>
                <i className="far fa-question-circle"></i>
                <div className="icon-label" style={{ position: "relative", fontSize: "0.5em", bottom: "8px" }}>Help</div>
              </div>
            </header>

            <Modal show={this.state.showHelp} onHide={this.handleHelpClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>Road Permit Request Help</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                If you require assistance, please contact York Region by email at <a href="mailto:transportation@york.ca">transportation@york.ca</a> or by phone at{" "}
                <a href="tel:1-877-464-9675">1-877-464-9675</a>
              <div className="text-right"><small>v{packageJSON.version}</small></div>
              <div className="text-right"><small><NavLink id="profile" to="/release-notes" exact onClick={() => this.setState({showHelp:false})}>release notes</NavLink></small></div>
              </Modal.Body>
            </Modal>

            <Modal show={this.state.showComments} onHide={this.handleCommentsClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>Road Permit Request Feedback</Modal.Title>
              </Modal.Header>
              <Modal.Body>
               <div className="inline" >
                <div className="form-row">
                  <div className="col-lg-12">
                   <p>We would like to hear your ideas about the Roads Permits Request site to make it better!</p>
                    <div className="form-group">
                        <div>
                         <label htmlFor="fullName">Full Name</label>
                          <input type="text" className="form-control" name="fullName" value={this.state.commentForm.fullName} onChange={(e) => this.handleCommentsChange('fullName', e)}/>
                          <div className="invalid-feedback"></div>
                        </div>
                    </div>
                   <div className="form-group">
                    <div>
                     <label  htmlFor="email">E-Mail</label>
                     <input type="email" className="form-control" name="email" value={this.state.commentForm.email} onChange={(e) => this.handleCommentsChange('email', e)}/>
                     <div className="invalid-feedback"></div>
                    </div>
                   </div>
                   <div className="form-group">
                    <div>
                     <label htmlFor="phone">Phone</label>
                     <input type="tel" className="form-control" name="phone" value={this.state.commentForm.phone} onChange={(e) => this.handleCommentsChange('phone', e)}/>
                     <div className="invalid-feedback"></div>
                    </div>
                   </div>
                   <div className="form-group">
                    <div>
                     <label htmlFor="yourMessage">Your Message</label>
                     <textarea rows="3"  type="textarea" className="form-control" name="yourMessage" value={this.state.commentForm.yourMessage} onChange={(e) => this.handleCommentsChange('yourMessage', e)}/>
                     <div className="invalid-feedback"></div>
                    </div>
                   </div>
                   <div className="form-group">
                     {/*<input type="hidden" name="oid" value={userContext.user.oid}/>*/}
                     <button className="btn btn-primary btn-block" style={{marginRight: '10px'}}  disabled={!this.state.commentForm.complete} onClick={this.handleCommentsClick}>
                      Send Feedback
                     </button>

                    </div>
                  </div>
                </div>
               </div>
              </Modal.Body>
            </Modal>
          </>
        );
    }
}

export default Header;