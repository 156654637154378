import React from 'react';
import PermitRequestDetails from '../../PermitRequestDetails';

const SummaryForm = (props) => {
    // restructure form data so PermitRequest component can read it
    let dataSet = Object.keys(props.form).reduce((data, key) => { data[key] = props.form[key].data; return data; }, {});
    dataSet.type = props.type;
    return (
        <div>
            <h5>Summary</h5>
            <p>Please verify that the following information is entered correctly. You can go back to any section in the form by clicking on the checklist. Please press the "Submit" button to finalize the submission of the permit request.</p>
            <hr/>
            <PermitRequestDetails {...dataSet}/>
        </div>
    )
}

export default SummaryForm;