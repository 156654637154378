import React from 'react';
import 'filepond-polyfill'; // supports IE11 (no support for <11)
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import 'filepond/dist/filepond.min.css';
const axios = require('axios');
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginFileRename);

/**
 * Wrapper for the FilePond react component.
 * This is customized to work with the form wizard component.
 */
class FormWizardFileUpload extends React.Component {
    /**
     * fileLoading state is used to control whether we rerender the component.
     * Preventing rerender is important because if we rerender during an upload, we lose the upload progress animation
     */
    state = {fileLoading: false};

    shouldComponentUpdate(nextProps, nextState) {
        return !nextState.fileLoading;
    }

    onProcessAllFiles = () => {
        this.setState({fileLoading: false});
        this.props.allFilesProcessed(this.props.fieldName); 
    }
      
    render(){
        return(
            <>
                <label htmlFor={this.props.fieldName}>{this.props.fieldLabel} { this.props.required && <span style={{"color":"red"}}>*</span> }</label>
                <FilePond 
                allowMultiple={this.props.allowMultiple}
                className={this.props.fieldName}
                labelIdle={this.props.description ? this.props.description : ''}
                acceptedFileTypes={this.props.acceptedFileTypes || ['application/pdf']}
                allowFileSizeValidation={true}
                maxFileSize="75MB"
                server={{
                    process: `${this.props.apiEndpoint}/upload`,
                    revert: (uniqueFileId, load, error) => {
                        axios.post(`${this.props.apiEndpoint}/deleteUpload`, {fileId: uniqueFileId})
                        .then(response => {
                            this.props.fileRemoved(this.props.fieldName, uniqueFileId);
                        }).catch(err => {
                            error(err.message);
                        });
                    }
                }}
                files={ this.props.data[this.props.fieldName] }
                onprocessfile = { (error, file) => { this.props.fileProcessingCompleted(this.props.fieldName, file) } }
                onprocessfiles = { this.onProcessAllFiles }
                onaddfilestart = { () => this.setState({fileLoading: true}) }
                fileRenameFunction= { (file) => {
                    return file.name.replaceAll(/[\*\\\/\:\<\>\|\?\"]/gm,'');
                }}
                />
                <input type="hidden" className={'form-control' + (this.props.errors[this.props.fieldName]? ' is-invalid' : '')}></input>
                <div className="invalid-feedback">{this.props.errors[this.props.fieldName]}</div>
            </>
        )
    }
}

export default FormWizardFileUpload;