import * as React from "react";
export const FormWizardContext = React.createContext(
  {
    id: '',
    apiEndpoint: '',
    currentStep: 0,
    completedSteps: [],
    data: [],
    form: {},
    errors: [],
    handleFieldChange: () => {},
    fileProcessingCompleted: () => {},
    allFilesProcessed: () => {},
    fileRemoved: () => {},
    permitType: ''
  }
)