import React, {useState, createRef, useEffect} from 'react';

export const MonerisContext = React.createContext({});

export const MonerisProvider = ({ children }) => {
    const [partialPaymentAmount, setPartialPaymentAmount] = useState(null); // stores on client with '$' sign and decimal, remember to strip to number before sending to server
    const [isResourceLoaded, setIsResourceLoaded] = useState(false);
    const [monerisEnvironment, setMonerisEnvironment] = useState(null);

    let monerisRef = createRef();

    useEffect(() => {
      fetch('/api/moneris-environment').then(response => {
        if(response.ok) return response.text();
      }).then(text => {
        setMonerisEnvironment(text);
      });
    }, []);

    /**
   * Moneris actions are at top level due to an issue with placing it at lower level component.
   * The closeCheckout command is not working properly during component cleanup and that means you will end up with multiple instances of Moneris checkout which will call the callbacks multiple times as well.
   */
    const loadMonerisResources = async () => {
        if(!isResourceLoaded && monerisEnvironment){
          const script = document.createElement("script");
          let scriptSrc;
          if(monerisEnvironment === 'qa'){
            scriptSrc = 'https://gatewayt.moneris.com/chkt/js/chkt_v1.00.js';
          }else if(monerisEnvironment === 'prod'){
            scriptSrc = 'https://gateway.moneris.com/chkt/js/chkt_v1.00.js';
          }
          script.src = scriptSrc;
          script.async = true;
          document.body.appendChild(script);
          setIsResourceLoaded(true);
        }
    }

    const getMonerisCheckout = () => {
      if(!monerisRef.current){
      // eslint-disable-next-line no-undef
        monerisRef.current = new monerisCheckout();
      }
      return monerisRef.current;
    }

    const getNewMonerisCheckout = () => {
      // eslint-disable-next-line no-undef
      monerisRef.current = new monerisCheckout();
      return monerisRef.current;
    }
  
    // const provider = useMemo(() => {
    //   return { clickFunction: clickFunction, speakerList: speakerList };
    // }, []);
  
    //const provider = { clickFunction: clickFunction, speakerList: speakerList };
  
    const provider = {
        loadMonerisResources,
        getMonerisCheckout,
        getNewMonerisCheckout,
        partialPaymentAmount,
        setPartialPaymentAmount,
        monerisEnvironment
    };
  
    return (
      <MonerisContext.Provider value={provider}>{children}</MonerisContext.Provider>
    );
  };