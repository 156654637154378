import React, { Component } from 'react';

class Spinner extends Component {
    state = {displaySpinner: false};
    timer = setTimeout(() => this.setState({displaySpinner: true}), 250);

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render(){
        if (!this.state.displaySpinner) return null;

        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border text-secondary" style={{width: '3rem', height: '3rem'}} role="status">
                    <span className="sr-only" >Loading...</span>
                </div>
            </div>
        )
    }
}

export default Spinner;
