import React from 'react';
import { FormWizardContext } from './FormWizardContext';
import { UserContext } from '../UserContext';
import PropTypes from 'prop-types';

/**
 * Used to wrap wizard step forms to provide the wizard context automatically.
 * This allows all the sub-forms to use data from the wizard to keep everything
 * synchornized.
 */
class FormWizardStep extends React.Component {
    static displayName = 'FormWizardStep';

    render() {
        const Form = this.props.component;
        return (
          <FormWizardContext.Consumer>
            {(wizardContext) => (
              <UserContext.Consumer>
                {(userContext) => (
                  <Form {...this.props} {...wizardContext} {...userContext} />
                )}
              </UserContext.Consumer>
            )}
          </FormWizardContext.Consumer>
        );
    }
}

FormWizardStep.propTypes = {
    component: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }

export default FormWizardStep;