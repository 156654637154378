import React, {Component} from 'react';
import FormWizard from '../form-wizard/FormWizard';
import FormWizardStep from '../form-wizard/FormWizardStep';
import FormWizardResult from '../form-wizard/FormWizardResult';
import ApplicantInformationForm from './road-permit-steps/ApplicantInformationForm';
import ContactInformationForm from './road-permit-steps/ContactInformationForm';
import PermitInformationForm from './road-permit-steps/PermitInformationForm';
import TrafficManagementPlanForm from './road-permit-steps/TrafficManagementPlanForm';
import SupportingDocumentsForm from './road-permit-steps/SupportingDocumentsForm';
import FeeInformationForm from './road-permit-steps/FeeInformationForm';
import LocationInformationForm from './road-permit-steps/LocationInformationForm';
import PaymentInformationForm from './road-permit-steps/PaymentInformationForm';
import SummaryForm from './road-permit-steps/SummaryForm';
import PermitResultForm from './road-permit-steps/PermitResultForm';

class TemporaryRoadSignForm extends Component {
    render(){
        return (
            <div>
                <FormWizard title={`Corridor Control Permit for Road Occupancy - ${this.props.permitType}`} type={`Road Occupancy Application - ${this.props.permitType}`} relativeUrl={this.props.permitType} apiEndpoint="/api/permit" {...this.props}>
                    <FormWizardStep component={ApplicantInformationForm} name="applicantInformationForm" label="Applicant Information" />
                    <FormWizardStep component={ContactInformationForm} name="contactInformationForm" label="Contact Information" />
                    <FormWizardStep component={PermitInformationForm} name="permitInformationForm" label="Permit Information" />
                    <FormWizardStep component={LocationInformationForm} name="locationInformationForm" label="Location Information" />
                    <FormWizardStep component={SupportingDocumentsForm} name="supportingDocumentsForm" label="Supporting Documents" />
                    <FormWizardStep component={FeeInformationForm} name="feeInformationForm" label="Fees" />
                    <FormWizardStep component={PaymentInformationForm} name="paymentInformationForm" label="Payment" />
                    <FormWizardStep component={SummaryForm} name="summaryForm" label="Summary" />
                    <FormWizardResult name="result" component={PermitResultForm} />
                </FormWizard>
            </div>
        )
    }
}

export default TemporaryRoadSignForm;