import React from 'react';
import DatePicker from "react-datepicker";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import "react-datepicker/dist/react-datepicker.css";
import Autosuggest from 'react-autosuggest';
import MaskInput from 'react-text-mask';


class DateInputField extends React.Component {
    render(){
        return(
            <>
            <input className={'form-control' + (this.props.errors[this.props.fieldName]? ' is-invalid' : '')} value={this.props.value} onClick={this.props.onClick} id={this.props.fieldName} autoComplete="off" ></input>
            <div className="invalid-feedback">{this.props.errors[this.props.fieldName]}</div>
            </>
        )
    }
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion}
    </div>
);

const renderInputComponent = inputProps => (
    <div>
      <input {...inputProps} className={'form-control' + (inputProps.errors[inputProps.id]? ' is-invalid' : '')}/>
      <div className="invalid-feedback">{inputProps.errors[inputProps.id]}</div>
    </div>
);

/**
 * Custom input componet to handle these types of inputs: test inputs (and textareas), date inputs, checkboxes, drop downs and radio buttons
 */
class FormWizardField extends React.Component {
    state = {suggestions: [], value: ''};

    componentDidMount(){
        this.processReadOnly();
    }

    componentDidUpdate(){
        this.processReadOnly();
    }

    processReadOnly = () => {
        if(this.props.readonly) {
            if(this.props.fieldType === 'input') document.getElementById(this.props.fieldName).readOnly=true;
            else if(this.props.fieldType === 'select') document.getElementById(this.props.fieldName).disabled=true;
            
        }else{
            if(this.props.fieldType === 'input') document.getElementById(this.props.fieldName).readOnly=false;
            else if(this.props.fieldType === 'select') document.getElementById(this.props.fieldName).disabled=false;
        }
    }

    handleDateFieldChange = (date) => {
        const event = {target:{id: this.props.fieldName, value: date}};
        this.props.handleFieldChange(event);
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.props.getSuggestions(value).then(results => {
            this.setState({
                suggestions: results
            });
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
        suggestions: []
        });
    };

    onSuggestionSelected = (event, {suggestionValue}) => {
        const newEvent = {target:{id: this.props.fieldName, value: suggestionValue}};
        this.props.handleFieldChange(newEvent);
    };

    render(){
        let maxTime = new Date().setHours(23,30);
        let minTime = this.props.minTime ? new Date(this.props.minTime) : new Date().setHours(0,0,0,0);
        let minDate = this.props.minDate ? new Date(this.props.minDate) : new Date();
        let maxDate = new Date().setFullYear(2030);
        let dateValue = this.props.fieldType === 'date' && this.props.data[this.props.fieldName] ? new Date(this.props.data[this.props.fieldName]) : '';
        let timeValue = this.props.fieldType === 'time' && this.props.data[this.props.fieldName] ? new Date(this.props.data[this.props.fieldName]) : '';

        let tooltipComponent = (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={this.props.fieldName}>{this.props.tooltip}</Tooltip>
            }
            >
            <i class="fas fa-info-circle"></i>
        </OverlayTrigger>
        );

        return (
            <div className="form-group">
                { this.props.fieldType === 'checkbox' && (
                    <div className="form-check">
                        <input className={'form-check-input' + (this.props.errors[this.props.fieldName]? ' is-invalid' : '')} type="checkbox" checked={this.props.data[this.props.fieldName]} id={this.props.fieldName} name={this.props.fieldName} onChange={this.props.handleFieldChange}/>
                        { (typeof this.props.fieldLabel) === 'string' ? <><label htmlFor={this.props.fieldName} className="form-check-label">{this.props.fieldLabel}</label>{ this.props.tooltip && tooltipComponent }</> : <>{this.props.fieldLabel} { this.props.tooltip && tooltipComponent }</> }
                        <div className="invalid-feedback">{this.props.errors[this.props.fieldName]}</div>
                    </div>
                )}

                {  this.props.fieldType !== 'checkbox' && (
                    <><label htmlFor={this.props.fieldName}>{this.props.fieldLabel}{this.props.maxLength && <span style={{fontSize:'.9rem'}}> (max characters {this.props.maxLength})</span>} { this.props.required && <span style={{"color":"red"}}>*</span> }</label> { this.props.tooltip && tooltipComponent }</>
                )}
                
                { this.props.fieldType === 'input' && this.props.mask && (
                    <MaskInput
                        mask={this.props.mask}
                        guide={this.props.guide}
                        type={this.props.inputType} className={'form-control' + (this.props.errors[this.props.fieldName]? ' is-invalid' : '')} placeholder={this.props.placeholder} id={this.props.fieldName} onChange={this.props.handleFieldChange} value={this.props.data[this.props.fieldName] || ''} autoComplete={this.props.autoComplete} maxLength={this.props.maxLength}></MaskInput>
                )}

                { this.props.fieldType === 'input' && !this.props.mask && (
                    <input
                        type={this.props.inputType} className={'form-control' + (this.props.errors[this.props.fieldName]? ' is-invalid' : '')} placeholder={this.props.placeholder} id={this.props.fieldName} onChange={this.props.handleFieldChange} value={this.props.data[this.props.fieldName] || ''} autoComplete={this.props.autoComplete} maxLength={this.props.maxLength}></input>
                )}


                { this.props.fieldType === 'textarea' && (
                    <textarea className={'form-control' + (this.props.errors[this.props.fieldName]? ' is-invalid' : '')} placeholder={this.props.placeholder} id={this.props.fieldName} onChange={this.props.handleFieldChange} value={this.props.data[this.props.fieldName] || ''} maxLength={this.props.maxLength}></textarea>
                )}

                { this.props.fieldType === 'select' && (
                    <select className={'form-control' + (this.props.errors[this.props.fieldName]? ' is-invalid' : '')} id={this.props.fieldName} onChange={this.props.handleFieldChange} value={this.props.data[this.props.fieldName] || ''}>
                        { this.props.children }
                    </select>
                )}

                { this.props.fieldType === 'radio' && this.props.radioOptions.map((radioOption, index) => 
                    <div className="form-check" key={radioOption.value}>
                        <input className={'form-check-input' + (this.props.errors[this.props.fieldName] ? ' is-invalid' : '')} type="radio" name={this.props.fieldName} id={`${this.props.fieldName}${index}`} value={radioOption.value} onChange={this.props.handleFieldChange} checked={this.props.data[this.props.fieldName] === radioOption.value}></input>
                        <label className="form-check-label" htmlFor={`${this.props.fieldName}${index}`}>{radioOption.label}</label>
                        { index === this.props.radioOptions.length - 1 && <div className="invalid-feedback">{this.props.errors[this.props.fieldName]}</div> }
                    </div>
                )}

                { this.props.fieldType === 'date' && (
                    <DatePicker
                        customInput={<DateInputField fieldName={this.props.fieldName} fieldLabel={this.props.fieldLabel} errors={this.props.errors || {}} />}
                        selected={dateValue}
                        onChange={this.handleDateFieldChange}
                        dateFormat="yyyy-MM-dd"
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                )}

                { 
                    this.props.fieldType === 'time' && (
                    <DatePicker
                        customInput={<DateInputField fieldName={this.props.fieldName} fieldLabel={this.props.fieldLabel} errors={this.props.errors || {}} />}
                        selected={timeValue}
                        onChange={this.handleDateFieldChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                        minTime={minTime}
                        maxTime={maxTime}
                    />
                )}

                {
                    this.props.fieldType === 'autosuggest' && (
                    <Autosuggest
                        suggestions={this.state.suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        onSuggestionSelected={this.onSuggestionSelected}
                        renderInputComponent={renderInputComponent}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{id: this.props.fieldName, placeholder: this.props.placeholder, autoComplete: this.props.autoComplete, value: this.props.data[this.props.fieldName] || '', onChange: this.props.handleFieldChange, errors: this.props.errors || {}}}
                    />
                    )
                }

                <div className="invalid-feedback">{this.props.errors[this.props.fieldName]}</div>

                { this.props.fieldHelp && <small className="form-text text-muted">{this.props.fieldHelp}</small> }
            </div>
        );
    }
}

export default FormWizardField;