import React, {Component} from 'react';

class LoginFail extends Component {
    render(){
        return (
            <div>
                <h3>There was a problem logging you into the application.</h3>
                <h5>Please contact <a href="mailto:permits@york.ca">permits@york.ca</a> for assistance</h5>
            </div>
        )
    }
}

export default LoginFail;