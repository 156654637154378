import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Spinner from './Spinner';
import ReceiptContent from './ReceiptContent';

export default function PermitPaymentReceipt(props) {
  let cardIcons = [];
  let tenderIcons = [];
  cardIcons['V']='visa.png';
  cardIcons['M']='mastercard.png';
  tenderIcons['CHEQUE']='cheque.png';
  tenderIcons['CASH']='dollar-bill.png';

  let [transaction, setTransaction] = useState(null);
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/declined-payment/${props.match.params.orderNumber}`, {headers: { Pragma: 'no-cache'}}).then(response => {
      return response.json();
    }).then(returnedPayment => {
      setTransaction({
        ...returnedPayment,
        permitId: props.match.params.permitId,
        fees: returnedPayment.cartItems.map(fee => ({FEE_DESCRIPTION: fee.description, FEE_PAYMENT_AMOUNT: fee.unitCost})),
      });
      setIsLoading(false);
    });
  }, []);
  
  return (
    <div>
      <NavLink to={`/myrequests/${props.match.params.permitId}`}>
        <div className="m-4 d-flex">
          <i className="fas fa-arrow-left mr-2" style={{fontSize: '1.5rem'}}></i>
          <div>Back to permit request</div>
        </div>
      </NavLink>
      {isLoading && <div style={{maxWidth:'600px'}}><Spinner/></div>}
      { transaction && !isLoading && <ReceiptContent transaction={transaction} /> }
    </div>
  );
}
