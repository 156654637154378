import React from "react";
const { profileConfig } = require('../config/profileFormConfig');

export default function Invitation(props){ 
    const onSubmit = (data) => {
        let uri = data.accept ? 'accept-invitation' : 'ignore-invitation';
        data.invitation = props.invitation._id;
        data.invitedByOrg = props.invitation.invitedByOrg;
        data.role = props.invitation.role;

        fetch(`/api/${uri}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((response) => {
            if (!response.ok) throw Error(response.statusText);
            props.submitCallback(data.accept ? 'Invite accepted, you now have access to the ' : 'Access has been denied and the requester will be notified');
        })
        .catch((err) => {
            console.error(err);
        });
    }

    return (
        <div>
            <p><b>{props.invitation.invitedBy.firstName} {props.invitation.invitedBy.lastName}</b> ({props.invitation.invitedBy.accountEmail}) is inviting you to become a member of the business account <b>{props.invitation.invitedByOrg.name}</b> with <b>{props.invitation.role}</b> access.</p>
            {<p>{profileConfig.roleHelp[props.invitation.role]}</p>}
            <form>
                <div className="row justify-content-between">
                    <div className="col-7 text-left">
                        <button type="reset" className="btn btn-primary mr-1" onClick={props.cancelCallback}>Cancel</button>
                        <button className="btn btn-danger" onClick={() => onSubmit({accept: false})}>Ignore Invitation</button>
                    </div>
                    <div className="col text-right">
                        <button className="btn btn-success" onClick={() => onSubmit({accept: true})}>Accept Invitation</button>
                    </div>
                </div>
            </form>
        </div>
    )
}