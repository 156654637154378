import React from "react";

export default function BasicProfile(props) {
    return (
        <div className="container-fluid mb-4">
            { !props.profile.firstName && !props.profile.lastName && !props.profile.phone && !props.profile.address && <div>There is no profile data yet, press Edit to get started.</div>}
            <div className="row mb-2">
            {props.profile.phone && (
                <div className="col-auto">
                <div>Phone #</div>
                <div className="text-muted">
                    {props.profile.phone.phoneNumber}
                </div>
                </div>
            )}
            {props.profile.alternatePhone && (
                <div className="col-auto">
                <div>Alternate Phone #</div>
                <div className="text-muted">
                    {props.profile.alternatePhone.phoneNumber}
                </div>
                </div>
            )}
            </div>

            {props.profile.address && (
            <>
                <div className="row mb-2">
                <div className="col">
                    <div>Address</div>
                    <div className="text-muted">
                    {props.profile.address.street}
                    </div>
                </div>
                </div>
                <div className="row mb-2">
                <div className="col">
                    <div>City</div>
                    <div className="text-muted">
                    {props.profile.address.city}
                    </div>
                </div>
                </div>
                <div className="row mb-2">
                <div className="col">
                    <div>Postal Code</div>
                    <div className="text-muted">
                    {props.profile.address.postalCode}
                    </div>
                </div>
                </div>
                <div className="row mb-2">
                <div className="col">
                    <div>Province</div>
                    <div className="text-muted">
                    {props.profile.address.province}
                    </div>
                </div>
                </div>
            </>
            )}
            <div className="row mb-2">
                 Permit expiration notifications are {(props.user.preferences && props.user.preferences.expiryNotificationEnabled)?'ON':'OFF'}
            </div>
            <div className="row mb-2">
                 Permit insurance expiration notifications are {(props.user.preferences && props.user.preferences.insuranceNotificationEnabled)?'ON':'OFF'}
            </div>
            <div className="row mb-2">
                 Permit status change notifications are {(props.user.preferences && props.user.preferences.statusChangeNotificationEnabled)?'ON':'OFF'}
            </div>
            <div className="row mb-2">
                Web application notifications are {(props.user.preferences && props.user.preferences.appNotificationEnabled)?'ON':'OFF'}
            </div>
        </div>
    )
}