import React from 'react';
import FormWizardButtonGroup from './FormWizardButtonGroup';
import { FormWizardContext } from './FormWizardContext.js';
import { UserContext } from '../UserContext.js';
import FormWizardSteps from './FormWizardSteps';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from './../Spinner';
const axios = require('axios');

/**
 * This drives all wizard functionality such as fetching and saving form data across all steps,
 * keeping current step and completed step states.
 */

class FormWizard extends React.Component {
  state = {id: null, currentStep: 0, completedSteps: [], form: {}, timeStarted: Date.now(), type:null, relativeUrl: null, hiddenSteps: [], loading: true, validating: false};
  tempState = {};

  componentDidMount(){
    // initialize empty form object if needed
    if(Object.keys(this.state.form).length === 0){
      axios.get(`${this.props.apiEndpoint}/getFromSession/${this.props.type}`, {headers: { Pragma: 'no-cache'}}).then(response => {
        this.setState({loading: false});
        if(response.data) {
          this.setState({unsubmittedData: response.data});
          if(this.props.continueFromSession) this.loadSessionData();
        }
      });

      let form = {};
      let hiddenSteps = [];
      this.props.children.forEach((child, index)=> {
        form[child.props.name] = {data: {}, errors: {}};
        if(child.props.display === false) hiddenSteps.push(index);
      });
      this.setState({form, hiddenSteps, type: this.props.type, relativeUrl: this.props.relativeUrl});
      // check for first step hidden
      if(hiddenSteps.includes(this.state.currentStep)){
        let nextStep = this.getNextStep();
        if(nextStep < this.props.children.length) this.setState({currentStep: nextStep});
      }
    }
  }

  /**
   * Store form field value in wizard state.
   * This function is written as is to avoid mutating the form data.
   * {formName} is destructured optional parameter if field is forcing storage in formName instead of current step
   */
  handleFieldChange = (event, {formName} = {}) => {
    let form =  this.state.form;
    let formData = Object.assign({}, form[formName || this.getCurrentStepComponent().props.name].data); // create new data object instead of mutating
    formData[event.target.type === 'radio' ? event.target.name : event.target.id] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    form[formName || this.getCurrentStepComponent().props.name].data = formData;
    this.setState({ form });
  }

  triggerPreviousStep = () => {
    let previousStep = this.getPreviousStep();
    if(previousStep > -1){
      this.setState({currentStep: previousStep});
    }
  }

  getNextStep = () => {
    // check for hidden steps
    for(let i = parseInt(this.state.currentStep) + 1; i < this.props.children.length; i ++){
      if(this.props.children[i].props.display !== false) return i;
    }
    return this.props.children.length + 1; // force non-existent next step
  }

  getPreviousStep = () => {
    // check for hidden steps
    for(let i = parseInt(this.state.currentStep) - 1; i > -1; i --){
      if(this.props.children[i].props.display !== false) return i;
      return -1;
    }
  }

  /**
   * This is intended to be called by the user pressing the next button on the current step.
   * The wizard will not advance the user unless validation passes (no errors)
   */
  triggerNextStep = () => {
    let nextStep = this.getNextStep();
    if(nextStep < this.props.children.length){
      this.setState({validating: true});
      // validate
      // Send object with key = name of step as defined in prop
      axios.post(`${this.props.apiEndpoint}/validate`, { ...this.state, currentFormName: this.getCurrentStepComponent().props.name })
      .then(response => {
        if(response.data === 'success'){
          const completedSteps = [...this.state.completedSteps, this.state.currentStep];
          let form = this.state.form;
          form[this.getCurrentStepComponent().props.name].errors = {};
          this.setState({ currentStep: nextStep, completedSteps, form });
        }
      })
      .catch(error => {
        let errors = error.response.data;
        let form = this.state.form;

        if(Object.keys(errors).length > 0){
          form[this.getCurrentStepComponent().props.name].errors = errors;
          this.setState({form});
        }
      }).finally( () => this.setState({validating: false}) );
    }
  }

  changeStep = (event) => {
    let selectedStep = parseInt(event.currentTarget.getAttribute('data-index'));
    if(selectedStep > this.state.currentStep){
      // validate
      this.setState({validating: true});
      axios.post(`${this.props.apiEndpoint}/validate`, { ...this.state, currentFormName: this.getCurrentStepComponent().props.name })
      .then(response => {
        if(response.data === 'success'){
          let form = this.state.form;
          form[this.getCurrentStepComponent().props.name].errors = {};
          this.setState({ currentStep: selectedStep, form });
        }
      })
      .catch(error => {
        let errors = error.response.data;
        let form = this.state.form;

        if(Object.keys(errors).length > 0){
          form[this.getCurrentStepComponent().props.name].errors = errors;
          this.setState({form});
        }
      }).finally( () => {
        this.setState({validating: false});
      });
    }else{
      this.setState({ currentStep: selectedStep });
    }
  }

  submitForm = () => {
    // validate
    this.setState({validating: true});
    axios.post(`${this.props.apiEndpoint}/save`, { save: true, ...this.state })
      .then(response => {
        if(response.data.permitId) {
          const completedSteps = [...this.state.completedSteps, this.state.currentStep];
          this.setState({ completedSteps, currentStep: this.props.children.length - 1, id: response.data.permitId });
        }
        /*if(response.data === 'success'){
          // advance to last step which should be result component
          const completedSteps = [...this.state.completedSteps, this.state.currentStep];
          this.setState({ completedSteps });
          this.setState({ currentStep: this.props.children.length - 1});
          console.log('success');
        }*/
      })
      .catch(error => {
        debugger;
        let errors = error.response.data;
        console.log(errors);
        /*let form = this.state.form;
        if(Object.keys(errors).length > 0){
          Object.keys(errors).forEach((key, index) => {
            form[key].errors = errors[key];
          });
          this.setState({ form });
        }*/
      }).finally( () => this.setState({validating: false}));
  }

  /**
   * This will NOT get ALL the step names. Instead it just returns the step names for the
   * children that are wizard steps, this does not include the wizard result component.
   */
  getAllDisplayStepNames = () => {
    let stepNames = [];
    this.props.children.forEach(child => {
        if(child.type.displayName === 'FormWizardStep') stepNames.push(child.props.label);
    });
    return stepNames;
  }

  getCurrentStepComponent = () => {
    /*if(this.state.hiddenSteps.includes(this.state.currentStep)) {
      let nextStep = this.state.currentStep + 1;
      if(nextStep < this.props.children.length) this.setState({currentStep: nextStep});
    }
    else*/
    //console.log(`in getCurrentStepComponent: currentStep = ${this.state.currentStep}`);
    return this.props.children[this.state.currentStep];
  }

  getCurrentFormData = () => {
    return (this.state.form[this.getCurrentStepComponent().props.name] || {}).data;
  }

  getCurrentFormErrors = () => {
    return (this.state.form[this.getCurrentStepComponent().props.name] || {}).errors;
  }

  /**
   * When a file has been uploaded or reloaded into the file upload component,
   * store it in a temp state variable so not to force re-render
   */
  fileProcessingCompleted = (fieldId, fileItem) => {
    if(fileItem.origin !== 1) return; // ignore files that have already been uploaded and added to state
    let tempFileItem = {source: fileItem.serverId, fileName: fileItem.file.name ,options: {type: 'limbo', file: {name: fileItem.file.name, type: fileItem.file.type, size: fileItem.file.size}}};
    if(this.tempState[fieldId] == null) this.tempState[fieldId] = [];
    this.tempState[fieldId].push(tempFileItem);
  }

  /**
   * When all files have been uploaded or reloaded into the file component, take temp state
   * and store in state
   */
  allFilesProcessed = fieldId => {
    console.log('done processing all files');
    let form = this.state.form;
    let files = form[this.getCurrentStepComponent().props.name].data[fieldId] || [];
    this.tempState[fieldId].forEach((fileItem, index) => {
      if(files.filter(file => file.source === fileItem.source).length === 0){
        files.push(fileItem)
        form[this.getCurrentStepComponent().props.name].data[fieldId] = files;
      }
    });
    this.setState({ form });
    this.tempState[fieldId] = [];
  }

  /**
   * Called after file is deleted from server
   */
  fileRemoved = (fieldId, serverId) => {
    let form = this.state.form;
    form[this.getCurrentStepComponent().props.name].data[fieldId] = form[this.getCurrentStepComponent().props.name].data[fieldId].filter(file => file.source !== serverId);
    this.setState({ form });
  }

  loadSessionData = () => {
    this.setState({...this.state.unsubmittedData, unsubmittedData:null});
  }

  clearSessionData = () => {
    this.setState({unsubmittedData: null});
    axios.get(`${this.props.apiEndpoint}/clearFromSession/${this.props.type}`, {headers: { Pragma: 'no-cache'}});
  }

  render() {
    return this.getCurrentStepComponent() && !this.state.loading ? (
        <FormWizardContext.Provider value={{
            id: this.state.id,
            apiEndpoint: this.props.apiEndpoint,
            currentStep: this.state.currentStep,
            completedSteps: this.state.completedSteps,
            data: this.getCurrentFormData(),
            form: this.state.form,
            errors: this.getCurrentFormErrors(),
            handleFieldChange: this.handleFieldChange,
            fileProcessingCompleted: this.fileProcessingCompleted,
            allFilesProcessed: this.allFilesProcessed,
            fileRemoved: this.fileRemoved,
            type: this.props.type
        }}>
          <h5>{ this.props.title }</h5>

          <UserContext.Consumer>
            { userContext =>
              // !userContext.user && <div className="alert alert-primary mt-2" role="alert" style={{display:'inline-block'}}><i className="fas fa-lightbulb mr-1"></i>Tip: To submit this form, you will be required to login or create a new online account. <span className="likelink" onClick={userContext.login}>Login now</span> if you want to link this request to a business account you own or have access to.</div>
              !userContext.user && <div className="alert alert-primary mt-2" role="alert" style={{display:'inline-block'}}><i className="fas fa-lightbulb mr-1"></i>Tip: To submit this form, you will be required to login or create a new online account.</div>
            }
          </UserContext.Consumer>

          <div className="d-flex flex-wrap flex-md-nowrap">
            <div className="p-2 mr-3 wizard-steps">
                <FormWizardSteps allStepNames={this.getAllDisplayStepNames()} currentStep={this.state.currentStep} currentStepComponent = { this.getCurrentStepComponent() } completedSteps={this.state.completedSteps} hiddenSteps={this.state.hiddenSteps} changeStep={this.changeStep} validating = {this.state.validating}/>
            </div>
            <div className="flex-grow-1 p-2 bd-highlight" style={{"maxWidth":"1200px"}}>

            <Modal show={this.state.unsubmittedData} centered>
              <Modal.Header>
                <Modal.Title>Previous Form Data Found</Modal.Title>
              </Modal.Header>
              <Modal.Body>We noticed that you have previously entered data in this form without submitting it. Would you like to continue where you left off?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.clearSessionData}>No</Button>
                <Button variant="primary" onClick={this.loadSessionData}>Yes</Button>
              </Modal.Footer>
            </Modal>
              { // only show buttons on top when on last step
              this.getNextStep() >= this.getAllDisplayStepNames().length &&  <div className="mb-2">
                <FormWizardButtonGroup
                currentStepComponent = { this.getCurrentStepComponent() }
                currentStep = { parseInt(this.state.currentStep) }
                totalDisplaySteps = { this.getAllDisplayStepNames().length }
                nextStep = { this.getNextStep() }
                previousStep = { this.getPreviousStep() }
                triggerNextStep = { this.triggerNextStep }
                triggerPreviousStep = { this.triggerPreviousStep }
                submitForm = { this.submitForm }
                validating = { this.state.validating }
              /></div>}
              { // this is the main wizard step form
              Object.keys(this.state.form).length > 0 ? this.getCurrentStepComponent() : '' }
              <FormWizardButtonGroup
                currentStepComponent = { this.getCurrentStepComponent() }
                currentStep = { parseInt(this.state.currentStep) }
                totalDisplaySteps = { this.getAllDisplayStepNames().length }
                nextStep = { this.getNextStep() }
                previousStep = { this.getPreviousStep() }
                triggerNextStep = { this.triggerNextStep }
                triggerPreviousStep = { this.triggerPreviousStep }
                submitForm = { this.submitForm }
                validating = { this.state.validating }
              />
            </div>
          </div>

          <div>
            {
              /* render hidden steps on form to process any logic needed to fill data */
              this.state.hiddenSteps.map(index => {
                return this.props.children[index];
              })
            }
          </div>
        </FormWizardContext.Provider>
    ) : <Spinner/>;
  }
}

FormWizard.propTypes = {
  title: PropTypes.string.isRequired,
  apiEndpoint: PropTypes.string.isRequired
}

export default FormWizard;