import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import axios from 'axios';
import Spinner from "../Spinner";
import FormWizardFileUpload from "../form-wizard/FormWizardFileUpload";


class DateInputField extends React.Component {
    render(){
        return(
            <>
                <input className={'form-control' + (this.props.errors[this.props.fieldName]? ' is-invalid' : '')} value={this.props.value} onClick={this.props.onClick} id={this.props.fieldName} autoComplete="off" ></input>
                <div className="invalid-feedback">{this.props.errors[this.props.fieldName]}</div>
            </>
        )
    }
}

class PermitRenewal extends Component {
    constructor() {
        super();
        this.fileProcessingCompleted = this.fileProcessingCompleted.bind(this)
        this.state = { permit: null, isLoading: true, renewalDate: new Date(), status: 'waiting', files: {drawings: []}}
    }

    async componentDidMount() {
        if (this.props.match && this.props.match.params.permitId) {
            let response = await axios
                .get(`/api/permit/${this.props.match.params.permitId}`, {
                    headers: {Pragma: "no-cache"},
                })
            if (response.data.permitInformationForm &&
                response.data.permitInformationForm.occupancyType == 'Municipal Consent'
            ) {
                this.setState({...this.state, status: 'consent'})
            }
            this.setState({permit: response.data, isLoading: false})
        }
    }

    changeDate(date) {
        this.setState({renewalDate: date})
    }

    async sendRenewal() {
        try {
            let result = await axios.post(`/api/requestRenewal/${this.state.permit.cityworksCaseId}`,
                {newExpirationDate: this.state.renewalDate, caseID: this.state.permit.cityworksCaseId});
            this.setState({status: 'success'})
        }
        catch (e) {
            console.log(e)
            this.setState({status: 'failure'})
        }
    }

    async sendMCRenewal() {
        if (this.state.files.drawings[0]) {
            try {
                let file = this.state.files.drawings[0];
                let result = await axios.post(`/api/requestRenewal/${this.state.permit.cityworksCaseId}`,
                    {
                        newExpirationDate: this.state.renewalDate,
                        caseID: this.state.permit.cityworksCaseId,
                        file: {id: file.source, filename: file.fileName}
                    });
                this.setState({status: 'success'})
            } catch (e) {
                console.log(e)
                this.setState({status: 'failure'})
            }
        }
    }

    fileProcessingCompleted = (fieldId, fileItem) => {
        if(fileItem.origin !== 1) return; // ignore files that have already been uploaded and added to state
        let tempFileItem = {source: fileItem.serverId, fileName: fileItem.file.name ,options: {type: 'limbo', file: {name: fileItem.file.name, type: fileItem.file.type, size: fileItem.file.size}}};
        this.tempState = [tempFileItem];
    }

    allFilesProcessed = fieldId => {
        this.setState({...this.state, files: {drawings: this.tempState}});
    }


    render(){
        let data = {}
        let errors = {}
        return (
            <>
                {
                    !this.state.isLoading &&
                    <div className="flex-grow-1 p-2 bd-highlight" style={{maxWidth: '1200px'}}>
                        <div className="form-group" style={{padding: '20px'}}>
                            {(this.state.status === 'waiting') &&
                            <>
                                <h5>Permit Renewal Form</h5>
                                <p>Please set a <b>new end date</b> for
                                    permit <b>#{this.state.permit && this.state.permit.id} - {this.state.permit && this.state.permit.type}</b>:
                                </p>
                                <div className="form-row">
                                    <div className="col-lg-4">
                                        <label htmlFor="date">New occupancy end date <span
                                            style={{color: "red"}}>*</span></label>
                                        <DatePicker
                                            customInput={<DateInputField fieldName='date' fieldLabel='Renewal date'
                                                                         errors={this.props.errors || {}}/>}
                                            onChange={(e) => {
                                                this.changeDate(e)
                                            }}
                                            selected={this.state.renewalDate}
                                            dateFormat="yyyy-MM-dd"
                                            minDate={new Date()}
                                            // maxDate={maxDate}
                                        />
                                    </div>
                                </div>
                                <div className="wizard-buttons">
                                    <button type="button" className="btn btn-primary float-right" onClick={() => {
                                        this.sendRenewal()
                                    }}>Submit Renewal Request
                                    </button>
                                </div>
                            </>
                            }
                            {(this.state.status === 'consent') &&
                            <>
                                <h5>Permit Renewal Form</h5>
                                <p>Please upload the <b>new drawings file</b> for
                                    permit <b>#{this.state.permit && this.state.permit.id} - {this.state.permit && this.state.permit.type}</b>:
                                </p>
                                <div className="form-row">
                                    <div style={{width: '100%'}}>
                                        <FormWizardFileUpload
                                            fieldName="drawings"
                                            data={this.state.files}
                                            errors={errors}
                                            fileProcessingCompleted={this.fileProcessingCompleted}
                                            allFilesProcessed={this.allFilesProcessed}
                                            fieldLabel="Upload Drawings"
                                            required={true}
                                            apiEndpoint="/api/permit"
                                            description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file type: PDF<br />Please combine all drawings realted to the application in one PDF file.</div></div>" />
                                    </div>
                                </div>
                                <div className="wizard-buttons">
                                    <button type="button" className="btn btn-primary float-right" onClick={() => {
                                        this.sendMCRenewal()
                                    }}>Submit Renewal Request
                                    </button>
                                </div>
                            </>
                            }
                            {(this.state.status === 'success') && (
                                <>
                                    <p>Your request has been succesfully received. You will receive an email notification confirming your permit status</p>
                                    <div className="wizard-buttons">
                                        <button type="button" className="btn btn-primary float-right" onClick={() => { window.location = '/myrequests'; }}>Continue</button>
                                    </div>
                                </>
                            )}
                            {(this.state.status === 'failure') && (
                                <>
                                    <p>Your request cannot be processed at this time. Please, retry this operation at a later time.</p>
                                    <div className="wizard-buttons">
                                        <button type="button" className="btn btn-primary float-right" onClick={() => { window.location = '/myrequests'; }}>Continue</button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                }
                {this.state.isLoading && <Spinner/>}
            </>
        )
    }
}

export default PermitRenewal;