/*
 * THIS COMPONENT USES REACT HOOKS
 */

import React, { useState, useEffect } from "react";
import Spinner from '../Spinner';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import MembershipRequest from "../profile/MembershipRequest";
import Invitation from "../profile/Invitation";
import axios from 'axios';
const moment = require('moment');

export default function MyNotifications(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [membershipRequest, setMembershipRequest] = useState(null);
    const [membershipInvite, setMembershipInvite] = useState(null);
    const [notification, setNotification] = useState(null);
    const [appNotifications, setAppNotifications] = useState(null);
    const [appAllNotifications, setAllAppNotifications] = useState(null);

    // run when component is mounted
    useEffect(() => {
        refreshUser();
        getNotifications();
        getAllNotifications();
    }, []);

    const getNotifications = async() => {
        const response = await axios.get('/api/getAppNotifications', {headers: { Pragma: 'no-cache'}});
        setAppNotifications(response.data)
    }

    const getAllNotifications = async() => {
        const response = await axios.get('/api/getAllAppNotifications', {headers: { Pragma: 'no-cache'}});
        setAllAppNotifications(response.data)
    }

    const removeNotification = async(id) => {
        await axios.get('/api/removeNotification',  {headers: { Pragma: 'no-cache'}, params:  {id: id }});
        await getAllNotifications();
    }


    const removeAllNotifications = async() => {
        await axios.get('/api/removeAllNotifications',  {headers: { Pragma: 'no-cache'}});
        setAllAppNotifications(null)
    }

    const refreshUser = () => {
        setIsLoading(true);
        props.refreshUser().then(user => {
            setProfile(user.profile);
            setIsLoading(false);
        });
    }

    const handleMembershipRequestSubmit = (outcomeMessage) => {
        refreshUser();
        setMembershipRequest(null);
        setNotification(outcomeMessage);
    }

    const handleMembershipInviteSubmit = (outcomeMessage) => {
        refreshUser();
        setMembershipInvite(null);
        setNotification(outcomeMessage);
    }
    let otherNotifications = null;
    if (appAllNotifications && Array.isArray(appAllNotifications) && appAllNotifications.length ) {
        otherNotifications = appAllNotifications.map(notification => {
            if (!notification.removedDate) {
                return (
                    <div className="alert alert-info likebutton" role="alert" style={{marginLeft: '10px', marginRight: '10px'}}>
                        <div className="d-flex flex-row">
                            <i className="fas fa-bell mr-2" style={{fontSize: "1.5em"}}></i>
                            <div className="mr-2" dangerouslySetInnerHTML={{__html: notification.body}}/>
                            <div className="ml-auto">{moment(notification.createdAt).format('YYYY-MM-DD [at] hh:mm A')}</div>
                            <i className="fas fa-times-circle mr-2" style={{fontSize: "1.5em", marginLeft: '32px'}}
                               onClick={() => removeNotification(notification._id)}></i>
                        </div>
                    </div>
                )
            }
        })
    }

    return (
        <div>
            {notification && <Alert variant="success" onClose={() => setNotification(null)} dismissible>
                {notification}
            </Alert>}
          {!isLoading && (profile.membershipRequests || profile.membershipInvites) && (<div>
            <h2 className="mb-4">My Notifications</h2>
            {profile.membershipRequests && profile.membershipRequests.map(membershipRequest => (
                <div key={membershipRequest._id} className="alert alert-primary likebutton" role="alert" onClick={() => setMembershipRequest(membershipRequest)}>
                    <div className="d-flex flex-row">
                        <i className="fas fa-people-arrows mr-2" style={{fontSize:"1.5em"}}></i>
                        <div className="mr-2">Request from {membershipRequest.requestedBy.firstName} {membershipRequest.requestedBy.lastName} ({membershipRequest.requestedBy.accountEmail}) {membershipRequest.requestedByOrg && <>on behalf of {membershipRequest.requestedByOrg.name}</>} for sharing access</div>
                        <div className="ml-auto">{moment(membershipRequest.createdAt).format('YYYY-MM-DD')}</div>
                    </div>
                </div>
            )
            )}
            { profile.membershipInvites && profile.membershipInvites.map(membershipInvite => (
                <div key={membershipInvite._id} className="alert alert-primary likebutton" role="alert" onClick={() => setMembershipInvite(membershipInvite)}>
                    <div className="d-flex flex-row">
                        <i className="fas fa-people-arrows mr-2" style={{fontSize:"1.5em"}}></i>
                        <div className="mr-2">Invitation from {membershipInvite.invitedBy.firstName} {membershipInvite.invitedBy.lastName} ({membershipInvite.invitedBy.accountEmail}) to join {membershipInvite.invitedByOrg.name} for sharing access</div>
                        <div className="ml-auto">{moment(membershipInvite.createdAt).format('YYYY-MM-DD')}</div>
                    </div>
                </div>
            ))}
          </div>)}
            { otherNotifications && (
            <div style={{marginTop: '10px'}}>
                <div className="alert alert-info likebutton" role="alert" style={{marginLeft: '10px', marginRight: '10px'}}>
                    <div className="d-flex flex-row">
                        <i className="fas fa-bell mr-2" style={{fontSize: "1.5em"}}></i>
                        <div className="mr-2">
                            <a href="#" onClick={removeAllNotifications}>Remove all notifications</a>
                        </div>
                    </div>
                </div>
                {otherNotifications}
            </div>
            )}
          {isLoading && <Spinner/>}


          {!isLoading && !profile.membershipRequests && !profile.membershipInvites && !otherNotifications && <h4>You have no notifications</h4>}

        {/***** ACCESS / SHARING REQUEST *****/}
        {membershipRequest && <Modal
            show={membershipRequest ? true : false}
            onHide={() => setMembershipRequest(null)}
            centered
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header closeButton>
            <Modal.Title>
                Access / Sharing Request
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MembershipRequest profile={profile} membershipRequest={membershipRequest} submitCallback={handleMembershipRequestSubmit} cancelCallback={() => setMembershipRequest(null)} />
            </Modal.Body>
        </Modal>}

        {/***** INVITATION *****/}
        {membershipInvite && <Modal
            show={membershipInvite ? true : false}
            onHide={() => setMembershipInvite(null)}
            centered
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header closeButton>
            <Modal.Title>
                Invitation to Business Account
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Invitation profile={profile} invitation={membershipInvite} submitCallback={handleMembershipInviteSubmit} cancelCallback={() => setMembershipInvite(null)} />
            </Modal.Body>
        </Modal>}

        </div>
    )
}