import React from 'react';
import FormWizardStep from './FormWizardStep';

/**
 * Designed to run after the form wizard has completed.
 * This component extends the FormWizardStep component.
 */
class FormWizardResult extends React.Component {
    static displayName = 'FormWizardResult';

    render(){
        return(
            <FormWizardStep {...this.props}/>
        );
    }
}

export default FormWizardResult;