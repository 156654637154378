import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Spinner from './Spinner';
import ReceiptContent from './ReceiptContent';

export default function PermitPaymentReceipt(props) {
  let cardIcons = [];
  let tenderIcons = [];
  cardIcons['V']='visa.png';
  cardIcons['M']='mastercard.png';
  tenderIcons['CHEQUE']='cheque.png';
  tenderIcons['CASH']='dollar-bill.png';

  let [payment, setPayment] = useState(null);
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/payment/${props.match.params.receiptId}`, {headers: { Pragma: 'no-cache'}}).then(response => {
      return response.json();
    }).then(returnedPayment => {
      setPayment({
        permitId: props.match.params.permitId,
        result: returnedPayment.result,
        orderNumber: returnedPayment.REFERENCE_INFO,
        transactionTimestamp: returnedPayment.PAYMENT_DATE,
        cardType: returnedPayment.monerisTransaction ? returnedPayment.monerisTransaction.cardType : null,
        cardNumberF6L4: returnedPayment.monerisTransaction ? returnedPayment.monerisTransaction.cardNumberF6L4 : null,
        TENDER_TYPE: returnedPayment.TENDER_TYPE,
        fees: returnedPayment.fees,
        amount: returnedPayment.PAYMENT_AMOUNT
      });
      setIsLoading(false);
    });
  }, []);
  
  return (
    <div>
      <NavLink to={`/myrequests/${props.match.params.permitId}`}>
        <div className="m-4 d-flex">
          <i className="fas fa-arrow-left mr-2" style={{fontSize: '1.5rem'}}></i>
          <div>Back to permit request</div>
        </div>
      </NavLink>
      {isLoading && <div style={{maxWidth:'600px'}}><Spinner/></div>}
      { payment && !isLoading && <ReceiptContent transaction={payment}/> }
    </div>
  );
}
