import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from 'react-bootstrap/Modal';
import Spinner from '../Spinner';
import EditBasicProfile from './EditBasicProfile';
import BasicProfile from './BasicProfile';
import EditOrgProfile from './EditOrgProfile';
import CreateMembershipRequest from './CreateMembershipRequest';
import InviteMember from './InviteMember';
import OrgList from './OrgList';

export default function MyProfile(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('profile');
  const [orgProfile, setOrgProfile] = useState(null);
  const [showOrgProfileEdit, setShowOrgProfileEdit] = useState(null);
  const [showRequestAccess, setShowRequestAccess] = useState(false);
  const [showInviteMember, setShowInviteMember] = useState(false);
  const [editBasicProfile, setEditBasicProfile] = useState(false);
  const [authorizedMemberships, setAuthorizedMemberships] = useState([]);
  const { startTab } = useParams();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setAuthorizedMemberships(props.user.profile && props.user.profile.memberships ? props.user.profile.memberships.filter(membership => membership.roles.some(role => role.name === 'admin' || role.name === 'owner')) : []);
    if(location.pathname.includes('newprofile')) setEditBasicProfile(true);
    props.refreshUser().then(() => {
      setIsLoading(false);
    });
    if(startTab) setActiveTab(startTab);
  }, []);

  const selectOrgToEdit = (org) => {
    fetch(`/api/org-membership/${org._id}`)
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      }).then(orgMemberships => {
        org.memberships = orgMemberships;
        setOrgProfile(org);
        setShowOrgProfileEdit(true);
      }).catch((err) => {
        console.error(err);
      });
  }

  const handleOrgSubmit = () => {
    setShowOrgProfileEdit(false);
    props.refreshUser();
  }

  const handleProfileCancel = () => {
    setIsLoading(true);
     // if new profile, we are here after login and should redirect to wherever user was before login
     if(location.pathname.includes('newprofile')){
      redirect();
    }else{
      setIsLoading(false);
    }
    setEditBasicProfile(false);
  }

  const handleProfileSubmit = () => {
    setIsLoading(true);
    props.refreshUser();
    // if new profile, we are here after login and should redirect to wherever user was before login
    if(location.pathname.includes('newprofile')){
      redirect();
    }else{
      setIsLoading(false);
    }
    setEditBasicProfile(false);
  }

  const redirect = () => {
    fetch(`/auth/redirect`)
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response.text();
    }).then(redirect => {
      setIsLoading(false);
      if(redirect) history.push(redirect);
    });
  }

  return (
    <div>
      {isLoading && <Spinner/>}
      {props.user.profile && !isLoading && (
        <>
          { location.pathname.includes('newprofile') && <div class="alert alert-primary" role="alert">
            Please continue the signup process by verifying or entering some basic information about yourself. This application will use this information to make it easier to fill in the request forms.
          </div> }

          <div
            className="p-3 rounded mb-3"
            style={{ backgroundColor: "#e9ecef" }}
          >
            <div className="d-flex flex-row">
              <h2 className="mr-2">
                {props.user.profile.firstName ? `${props.user.profile.firstName} ${props.user.profile.lastName}'s Profile`
                : `${props.user.profile.accountEmail}'s Profile`}
              </h2>
            </div>
            <div className="d-flex flex-wrap flex-row">
              {props.user.profile.accountEmail && props.user.profile.firstName && (
                <div className="mx-2" title="Account Email">
                  <i className="far fa-envelope mr-2"></i>
                  {props.user.profile.accountEmail}
                </div>
              )}
            </div>
          </div>

          <Tabs defaultActiveKey={activeTab} id="uncontrolled-tab-example">
            <Tab eventKey="profile" title="Basic Profile" className="p-3">
              {editBasicProfile ? (
               <EditBasicProfile profile={props.user.profile} user={props.user} cancelCallback={handleProfileCancel} submitCallback={handleProfileSubmit}/>
              ) : (
               <>
                 <button className="btn btn-primary mb-3" onClick={() => setEditBasicProfile(true)}>Edit</button>
                 <BasicProfile profile={props.user.profile} user={props.user}/>
               </>
              )}
            </Tab>

            { props.user.preferences && props.user.preferences.businessAccountsEnabled &&
              <Tab eventKey="businesses" title="Business Accounts">
                <div className="m-3">
                  <button type="button" className="btn btn-primary mr-1" onClick={() => {setOrgProfile({}); setShowOrgProfileEdit(true);}}>Add Business</button>
                  <button type="button" className="btn btn-primary mr-1" onClick={() => setShowInviteMember(true)} disabled={authorizedMemberships.length === 0 ? true : false}>Invite to Your Business</button>
                  <button type="button" className="btn btn-primary" onClick={() => setShowRequestAccess(true)}>Request Access to Business</button>
                </div>

                <OrgList profile={props.user.profile} editOrgCallback={selectOrgToEdit}/>
              </Tab> }
          </Tabs>
        </>
      )}

      {!props.user.profile && <div>Oops, you have no profile</div>}

      <Modal
        show={showOrgProfileEdit}
        onHide={() => setShowOrgProfileEdit(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {orgProfile && orgProfile._id ? (
              <div>Edit Business</div>
            ) : (
              <div>Add New Business</div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditOrgProfile orgProfile={orgProfile} cancelCallback={() => setShowOrgProfileEdit(false)} submitCallback={handleOrgSubmit} inviteRedirect={() => setShowOrgProfileEdit(false)}/>
        </Modal.Body>
      </Modal>

      <Modal
        show={showRequestAccess}
        onHide={() => setShowRequestAccess(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Request Access to Existing Business
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateMembershipRequest user={props.user} cancelCallback={() => setShowRequestAccess(false)} submitCallback={() => setShowRequestAccess(false)}/>
        </Modal.Body>
        </Modal>

      <Modal
        show={showInviteMember}
        onHide={() => setShowInviteMember(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Invite a Member to Your Business Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InviteMember user={props.user} cancelCallback={() => setShowInviteMember(false)} submitCallback={() => setShowInviteMember(false)}/>
        </Modal.Body>
        </Modal>
    </div>
  );
}
