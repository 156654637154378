import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'; 

class Navigation extends Component {
    closeIfMobile = () => {
        if (window.innerWidth < 700) {
            this.props.closenav();
        }
    }

    render() {
        return (
        <div className="side-nav2">
            <div className="closebtn likebutton" onClick={this.props.closenav}>&times;</div>
            <ul>
                <li><NavLink to="/" activeClassName="active" exact onClick={this.closeIfMobile}><i className="fas fa-home"></i>Home</NavLink></li>
                <li><NavLink to="/myrequests" activeClassName="active" exact onClick={this.closeIfMobile}><i className="fas fa-clipboard-list"></i>My Requests</NavLink></li>
                { this.props.user && this.props.user.preferences && this.props.user.preferences.businessAccountsEnabled ? <li><NavLink to="/sharedrequests" activeClassName="active" exact onClick={this.closeIfMobile}><i className="fas fa-clipboard-list" style={{position:'relative'}}><i className="fas fa-people-arrows mr-2" style={{position: 'absolute', top:0, right:'45px', fontSize:".6em"}}></i></i>Shared Requests</NavLink></li> : null }
            </ul>

            <img className="yrlogo" alt="York Region Logo" src="/img/YorkLogo.png"/>
        </div>
        );
    }
}

export default Navigation;