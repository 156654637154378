import "core-js/es";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './style.scss';
import * as serviceWorker from './serviceWorker';

function getUserAgent() {
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/)
      if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    }
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      tem = ua.match(/Edge\/(\d+)/)
      if (tem != null) { return { name: 'Edge', version: tem[1] }; }      
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    return {
      name: M[0],
      version: +M[1]
    };
}

function browserSupported(browser) {
    if((browser.name === 'MSIE' || browser.name === 'IE') && browser.version < 11) return false;
    if(browser.name === 'Chrome' && browser.version < 50) return false;
    return true;
}

ReactDOM.render(<App browserSupported={browserSupported(getUserAgent())}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
