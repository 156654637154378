import React from 'react';

class PaymentInformationForm extends React.Component {
    render(){
        return (
            <div>
                <h5>Payment</h5>
                <p style={{color:'red'}}>A York Region representative will contact you with the total fee amount for the permit prior to receiving payment.</p>
                <p>Payment can be made online, by phone, or mail. <br/> 
                Note: The permit fee must be paid prior to the issuance of a permit.</p>
                <p>Please send a cheque payable to "York Region" to:</p>
                <p className="ml-3">The Regional Municipality of York <br/>
                Transportation Services, Permits <br/>
                17250 Yonge Street <br/>
                Newmarket, ON L3Y 6Z1</p>
                <p>Or phone us at:</p>
                <p className="ml-3">1-877-464-9675 ext. 75700</p>
            </div>
        )
    }
}

export default PaymentInformationForm;