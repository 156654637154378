let configuration = (formData) => {
  return {
    "Municipal Consent": {
      applicantInformationForm: {
        consultantLabel: "Consultant",
        businessLabel: "Utility Company",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: true,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: false,
        contractNumberRequired: false,
        subtypeLabel: "Sub Type",
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location / Activity",
      },
    },
    "Road Occupancy Application - Utility": {
      applicantInformationForm: {
        consultantLabel: "Contractor",
        businessLabel: "Utility Company",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: true,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: false,
        contractNumberRequired: false,
        subtypeLabel: "Sub Type",
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location Information",
      },
    },
    "Road Occupancy Application - Development": {
      applicantInformationForm: {
        consultantLabel: "Contractor",
        businessLabel: "Owner",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: true,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: "Development Approval Number",
        contractNumberRequired: true,
        subtypeLabel: "Sub Type",
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location Information",
      },
    },
    "Road Occupancy Application - Construction": {
      applicantInformationForm: {
        consultantLabel: "Contractor",
        businessLabel: "Owner",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: true,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: (() => {
          if (
           formData &&
           formData.form &&
           formData.form.permitInformationForm
          ) {
            return (
             (formData.form.permitInformationForm.data.occupancyType !== "Construction General")?"Contract Number":false
            );
          }
        })(),
        contractNumberRequired: (() => {
          if (
            formData &&
            formData.form &&
            formData.form.permitInformationForm
          ) {
            return (
              formData.form.permitInformationForm.data.occupancyType !==
              "Construction General"
            );
          }
        })(),
        subtypeLabel: "Sub Type",
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location Information",
      }
    },
    "Road Occupancy Application - Geotechnical": {
      applicantInformationForm: {
        consultantLabel: "Contractor",
        businessLabel: "Owner",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: true,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: "Contract Number",
        contractNumberRequired: false,
        subtypeLabel: "Sub Type",
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location Information",
      }
    },
    "Road Occupancy Application - Encroachment": {
      applicantInformationForm: {
        businessLabel: (() => {
          let Subtype = null;
          if (formData && formData.form && formData.form.applicantInformationForm) {
            Subtype =formData.form.applicantInformationForm.data.applicantSubtype;
          }
          if (formData && !formData.form) {
            Subtype = formData.applicantInformationForm.applicantSubtype;
          }
          switch (Subtype) {
            case "municipality":
              return "Municipality";
            default:
              return "Owner";
          }
        })(),
        dualOwners: true,
        showOwnerName: false,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: true,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: false,
        contractNumberRequired: false,
        subtypeLabel: "Sub Type",
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location Information",
      },
    },
    "Road Occupancy Application - Survey and Inspection": {
      applicantInformationForm: {
        consultantLabel: "Contractor",
        businessLabel: "Owner",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: true,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: "Contract Number",
        contractNumberRequired: false,
        subtypeLabel: "Sub Type",
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location Information",
        showMultipleMunicipalities: true,
      },
    },
    "Road Occupancy Application - Entrance": {
      applicantInformationForm: {
        consultantLabel: "Contractor",
        businessLabel: "Owner",
        showOwnerName: false,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: true,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: false,
        contractNumberRequired: false,
        subtypeLabel: "Sub Type",
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location Information",
        streetAddress: "Street Address",
      },
    },
    "Road Occupancy Application - Excess Load": {
      applicantInformationForm: {
        consultantLabel: "Contractor",
        businessLabel: "Owner",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Cargo Type",
        // activityDetailPlaceholder: "Provide a brief description of cargo",
        activityDetailMaxLength: 40,
        pavementCut: false,
        loadDimensions: (() => {
          if (
            formData &&
            formData.form &&
            formData.form.permitInformationForm
          ) {
            return (
              formData.form.permitInformationForm.data.subtype !==
              "Excess Load Annual"
            );
          }
        })(),
        dates: true,
        startDate: false,
        contractNumber: false,
        contractNumberRequired: false,
        subtypeLabel: "Trip type",
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location Information",
      },
    },
    "Road Occupancy Application - Film & Photo": {
      applicantInformationForm: {
        consultantLabel: "Applicant",
        businessLabel: "Owner",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: false,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: false,
        contractNumberRequired: false,
        subtypeLabel: "Sub Type",
        charityNumber: true,
      },
      locationInformationForm: {
        title: "Location Information",
        showMultipleMunicipalities: true,
      },
    },
    "Road Occupancy Application - Event": {
      applicantInformationForm: {
        consultantLabel: "Applicant",
        businessLabel: "Owner",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: false,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: false,
        contractNumberRequired: false,
        subtypeLabel: "Sub Type",
        charityNumber: true,
      },
      locationInformationForm: {
        title: "Location Information",
        showMultipleMunicipalities: true,
      },
    },
    "Road Occupancy Application - Temporary Road Sign": {
      applicantInformationForm: {
        consultantLabel: "Applicant",
        businessLabel: "Owner",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Organization/Event being Advertised",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: false,
        loadDimensions: false,
        dates: (() => {
          if (
            formData &&
            formData.form &&
            formData.form.permitInformationForm
          ) {
            // if ((formData.form.permitInformationForm.data.subtype === "Business (fees apply)")) return true;
            return formData.form.permitInformationForm.data.subtype ===
             "Charitable or community event";
          }
        }
        )(),
        startDate: (() => {
          if (
            formData &&
            formData.form &&
            formData.form.permitInformationForm
          ) {
            return (
              formData.form.permitInformationForm.data.subtype ===
              "Business (fees apply)"
            );
          }
        })(),
        contractNumber: false,
        contractNumberRequired: false,
        subtypeLabel: "Advertising for",
        charityNumber: (() => {
          if (
            formData &&
            formData.form &&
            formData.form.permitInformationForm
          ) {
            return (
              formData.form.permitInformationForm.data.subtype ===
              "Charitable or community event"
            );
          }
          return false;
        })(),
      },
      locationInformationForm: {
        title: "Location Information",
        showMultipleMunicipalities: true,
        streetAddress: "Address of Venue or Sign Location(s)",
      },
    },
    "Road Occupancy Application - Load Exemption": {
      applicantInformationForm: {
        consultantLabel: "Contractor",
        businessLabel: "Owner",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
          "Provide a brief description of the activity",
        pavementCut: false,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: false,
        contractNumberRequired: false,
        //subtypeLabel: "Restriction Type",
        restrictionType: [
          {"name": "Seasonal"},
          {"name": "Year Round"}
        ],
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location Information",
      },
    },
    "Road Occupancy Application - Temporary Outdoor Patio": {
      applicantInformationForm: {
        consultantLabel: "Tenant",
        businessLabel: "Owner",
        showOwnerName: true,
      },
      permitInformationForm: {
        occupancyTypeName: "Occupancy Type",
        activityDetail: "Activity Description",
        activityDetailPlaceholder:
            "Provide a brief description of the activity",
        pavementCut: false,
        loadDimensions: false,
        dates: true,
        startDate: false,
        contractNumber: false,
        contractNumberRequired: false,
        //subtypeLabel: "Restriction Type",
        charityNumber: false,
      },
      locationInformationForm: {
        title: "Location Information",
      },
    },
  };
};


export const regionalRoads = [
  {
    "number": 71,
    "name": "14th Avenue"
  },
  {
    "number": 40,
    "name": "15th Sideroad"
  },
  {
    "number": 73,
    "name": "16th Avenue"
  },
  {
    "number": 26,
    "name": "18th Sideroad"
  },
  {
    "number": 29,
    "name": "19th Avenue"
  },
  {
    "number": 34,
    "name": "2nd Concession Road"
  },
  {
    "number": 15,
    "name": "Aurora Road"
  },
  {
    "number": 88,
    "name": "Baseline Road"
  },
  {
    "number": 38,
    "name": "Bathurst Street"
  },
  {
    "number": 34,
    "name": "Bayview Avenue"
  },
  {
    "number": 80,
    "name": "Black River Road"
  },
  {
    "number": 40,
    "name": "Bloomington Road"
  },
  {
    "number": 69,
    "name": "Box Grove Bypass"
  },
  {
    "number": 13,
    "name": "Bradford Street"
  },
  {
    "number": 73,
    "name": "Carrville Road"
  },
  {
    "number": 71,
    "name": "Centre Street"
  },
  {
    "number": 31,
    "name": "Davis Drive"
  },
  {
    "number": 31,
    "name": "Davis Drive West"
  },
  {
    "number": 45,
    "name": "Doane Road"
  },
  {
    "number": 12,
    "name": "Don Mills Road"
  },
  {
    "number": 48,
    "name": "Donald Cousens Parkway"
  },
  {
    "number": 53,
    "name": "Dufferin Street"
  },
  {
    "number": 49,
    "name": "Elgin Mills Road East"
  },
  {
    "number": 490,
    "name": "Elgin Mills Road West"
  },
  {
    "number": 29,
    "name": "Gamble Road"
  },
  {
    "number": 33,
    "name": "Glenwoods Avenue"
  },
  {
    "number": 19,
    "name": "Green Lane East"
  },
  {
    "number": 19,
    "name": "Green Lane West"
  },
  {
    "number": 1,
    "name": "Highway 11"
  },
  {
    "number": 27,
    "name": "Highway 27"
  },
  {
    "number": 7,
    "name": "Highway 7"
  },
  {
    "number": 83,
    "name": "Holland Landing Road"
  },
  {
    "number": 17,
    "name": "Islington Avenue"
  },
  {
    "number": 55,
    "name": "Jane Street"
  },
  {
    "number": 6,
    "name": "Keele Street"
  },
  {
    "number": 3,
    "name": "Kennedy Road"
  },
  {
    "number": 11,
    "name": "King Road"
  },
  {
    "number": 14,
    "name": "King Vaughan Road"
  },
  {
    "number": 72,
    "name": "Langstaff Road"
  },
  {
    "number": 12,
    "name": "Leslie Street"
  },
  {
    "number": 16,
    "name": "Lloydtown-Aurora Road"
  },
  {
    "number": 14,
    "name": "Main Street"
  },
  {
    "number": 25,
    "name": "Major Mackenzie Drive East"
  },
  {
    "number": 25,
    "name": "Major Mackenzie Drive West"
  },
  {
    "number": 68,
    "name": "Markham Road"
  },
  {
    "number": 67,
    "name": "McCowan Road"
  },
  {
    "number": 78,
    "name": "Metro Road North"
  },
  {
    "number": 76,
    "name": "Morton Avenue"
  },
  {
    "number": 13,
    "name": "Mount Albert Road"
  },
  {
    "number": 74,
    "name": "Mulock Drive"
  },
  {
    "number": 49,
    "name": "Nashville Road"
  },
  {
    "number": 69,
    "name": "Ninth Line"
  },
  {
    "number": 79,
    "name": "Old Homestead Road"
  },
  {
    "number": 18,
    "name": "Park Road"
  },
  {
    "number": 21,
    "name": "Pefferlaw Road"
  },
  {
    "number": 57,
    "name": "Pine Valley Drive"
  },
  {
    "number": 34,
    "name": "Prospect Street"
  },
  {
    "number": 77,
    "name": "Queensville Sideroad"
  },
  {
    "number": 32,
    "name": "Ravenshoe Road"
  },
  {
    "number": 70,
    "name": "Reesor Road"
  },
  {
    "number": 73,
    "name": "Rutherford Road"
  },
  {
    "number": 26,
    "name": "St John's Sideroad"
  },
  {
    "number": 14,
    "name": "Stouffville Road"
  },
  {
    "number": 49,
    "name": "Teston Road"
  },
  {
    "number": 12,
    "name": "The Queensway South"
  },
  {
    "number": 82,
    "name": "Victoria Road"
  },
  {
    "number": 74,
    "name": "Vivian Road"
  },
  {
    "number": 65,
    "name": "Warden Avenue"
  },
  {
    "number": 81,
    "name": "Weir's Sideroad"
  },
  {
    "number": 15,
    "name": "Wellington Street"
  },
  {
    "number": 56,
    "name": "Weston Road"
  },
  {
    "number": 8,
    "name": "Woodbine Avenue"
  },
  {
    "number": 1,
    "name": "Yonge Street"
  },
  {
    "number": 30,
    "name": "York/Durham Line"
  },
  {
    "number": 9,
    "name": "Dalton Road"
  },
  {
    "number": 9,
    "name": "High Street"
  },
  {
    "number": 24,
    "name": "Highway 50"
  },
  {
    "number": 31,
    "name": "Highway 9"
  },
  {
    "number": 78,
    "name": "Metro Road South"
  }
]

export const permitNameMapping = {
  "Municipal Consent":  "Municipal Consent",
  "Road Occupancy Application - Utility": "Utility",
  "Road Occupancy Application - Development": "Development",
  "Road Occupancy Application - Construction":"Construction",
  "Road Occupancy Application - Geotechnical": "Geotechnical",
  "Road Occupancy Application - Encroachment": "Encroachment",
  "Road Occupancy Application - Survey and Inspection": "Survey and Inspection",
  "Road Occupancy Application - Entrance": "Entrance",
  "Road Occupancy Application - Excess Load": "Excess Load",
  "Road Occupancy Application - Film & Photo":  "Film & Photo",
  "Road Occupancy Application - Event": "Event",
  "Road Occupancy Application - Temporary Road Sign": "Temporary Road Sign",
  "Road Occupancy Application - Load Exemption": "Load Exemption",
  "Road Occupancy Application - Temporary Outdoor Patio": "Temporary Outdoor Patio"
}


export const config = (formData, type) => {
  //formData.form.applicantInformationForm.data.
  if (!type) {
    // from forms
    return configuration(formData)[formData.type][formData.name];
  }
  if (type && !formData.loading) {
    // from summary
    return configuration(formData)[formData.type];
  }
  //return configuration(formData);
};
