/*
 * THIS COMPONENT USES REACT HOOKS
 */

import React, { useState } from "react";
import { useForm } from "react-hook-form";

export default function EditMyProfile(props) {
  const [isSaving, setIsSaving] = useState(true);
  const [profile, setProfile] = useState(props.profile);

  const { register, handleSubmit, formState: { errors }, getValues } = useForm();
  const onSubmit = (data) => {
    setIsSaving(true);
    fetch("/api/my-profile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      })
      .then((profile) => {
        props.submitCallback();
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
      });
  };

  return (

        <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ maxWidth: "800px" }}>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.firstName ? " is-invalid" : "")
                          }
                          name="firstName"
                          defaultValue={profile.firstName}
                          {...register("firstName",{
                            required: "First name is required",
                          })}
                        />
                        {errors.firstName && (
                          <div className="invalid-feedback">
                            {errors.firstName.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.lastName ? " is-invalid" : "")
                          }
                          name="lastName"
                          defaultValue={profile.lastName}
                          {...register("lastName",{
                            required: "Last name is required",
                          })}
                        />
                        {errors.lastName && (
                          <div className="invalid-feedback">
                            {errors.lastName.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col">
                    <div className="form-group">
                        <label htmlFor="phone.phoneNumber">
                          Phone #
                        </label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.phone?.phoneNumber ? " is-invalid" : "")
                          }
                          name="phone.phoneNumber"
                          defaultValue={
                            profile.phone
                              ? profile.phone.phoneNumber
                              : ""
                          }
                          {...register("phone.phoneNumber",{
                            pattern: {
                              value: /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4})$/,
                              message: "Phone number not valid",
                            },
                          })}
                        />
                        {errors.phone?.phoneNumber && (
                          <div className="invalid-feedback">
                            {errors.phone.phoneNumber.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col">

                  <div className="form-group" style={{maxWidth:'150px'}}>
                    <label htmlFor="phone.type">Phone Type</label>
                    <select
                      name="phone.type"
                      className={
                        "form-control" +
                        (errors.phone?.type ? " is-invalid" : "")
                      }
                      defaultValue={
                        profile.phone ? profile.phone.type : ""
                      }
                      {...register("phone.type",{validate: value => {
                        const { phone } = getValues();
                        if(phone && phone.phoneNumber && value === '') return 'Please select a phone number type';
                        return true;
                      }})}
                    >
                      <option value=""></option>
                      <option value="mobile">Mobile</option>
                      <option value="land">Land Line</option>
                    </select>
                    {errors.phone?.type && (
                      <div className="invalid-feedback">
                        {errors.phone.type.message}
                      </div>
                    )}
                  </div>
                </div>
                </div>

                <div className="form-row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="alternatePhone">Alternate Phone #</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.alternatePhone?.phoneNumber
                              ? " is-invalid"
                              : "")
                          }
                          name="alternatePhone.phoneNumber"
                          defaultValue={
                            profile.alternatePhone
                              ? profile.alternatePhone.phoneNumber
                              : ""
                          }
                          {...register("alternatePhone.phoneNumber",{
                            pattern: {
                              value: /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4})$/,
                              message: "Phone number not valid",
                            },
                          })}
                        />
                        {errors.alternatePhone?.phoneNumber && (
                          <div className="invalid-feedback">
                            {errors.alternatePhone.phoneNumber.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col">

                  <div className="form-group" style={{maxWidth:'150px'}}>
                    <label htmlFor="alternatePhone.type">Phone Type</label>
                    <select
                      name="alternatePhone.type"
                      className={
                        "form-control" +
                        (errors.alternatePhone?.type ? " is-invalid" : "")
                      }
                      defaultValue={
                        profile.alternatePhone ? profile.alternatePhone.type : ""
                      }
                      {...register("alternatePhone.type",{validate: value => {
                        const { alternatePhone } = getValues();
                        if(alternatePhone && alternatePhone.phoneNumber && value === '') return 'Please select a phone number type';
                        return true;
                      }})}
                    >
                      <option value=""></option>
                      <option value="mobile">Mobile</option>
                      <option value="land">Land Line</option>
                    </select>
                    {errors.alternatePhone?.type && (
                      <div className="invalid-feedback">
                        {errors.alternatePhone.type.message}
                      </div>
                    )}
                  </div>
                </div>
                    </div>


                  <div className="form-row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="address.street">
                          Street Address
                        </label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.address?.street ? " is-invalid" : "")
                          }
                          name="address.street"
                          defaultValue={
                            profile.address ? profile.address.street : ""
                          }
                          {...register("address.street")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="address.city">City</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.address?.city ? " is-invalid" : "")
                          }
                          name="address.city"
                          defaultValue={
                            profile.address ? profile.address.city : ""
                          }
                          {...register("address.city")}
                        />
                      </div>
                    </div>
                    </div>
                  <div className="form-row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="address.postalCode">Postal Code</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.address?.postalCode ? " is-invalid" : "")
                          }
                          name="address.postalCode"
                          defaultValue={
                            profile.address ? profile.address.postalCode : ""
                          }
                          {...register("address.postalCode",{
                            pattern: {
                              value: /^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i,
                              message: "Postal code not valid",
                            },
                          })}
                        />
                        {errors.address?.postalCode && (
                          <div className="invalid-feedback">
                            {errors.address.postalCode.message}
                          </div>
                        )}
                      </div>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="address.province">Province</label>
                        <select
                          className={
                            "form-control" +
                            (errors.address?.province ? " is-invalid" : "")
                          }
                          name="address.province"
                          defaultValue={
                            profile.address ? profile.address.province : ""
                          }
                          {...register("address.province")}
                        >
                          <option></option>
                          <option value="AB">Alberta</option>
                          <option value="BC">British Columbia</option>
                          <option value="MB">Manitoba</option>
                          <option value="NB">New Brunswick</option>
                          <option value="NL">Newfoundland and Labrador</option>
                          <option value="NS">Nova Scotia</option>
                          <option value="ON">Ontario</option>
                          <option value="PE">Prince Edward Island</option>
                          <option value="QC">Quebec</option>
                          <option value="SK">Saskatchewan</option>
                          <option value="NT">Northwest Territories</option>
                          <option value="NU">Nunavut</option>
                          <option value="YT">Yukon</option>
                        </select>

                        {/*<input type="text" className={'form-control' + (errors.address?.province ? ' is-invalid' : '')} name="address.province" defaultValue={profile.address ? profile.address.province : ''} ref={register}/> */}
                      </div>
                    </div>
                  </div>

                  <div style={{marginTop: '10px', marginBottom: '8px', fontWeight: 'bold'}}>Notifications</div>
                  <div className="form-row">
                    <div className="col">
                      <div>
                        <input type="checkbox" name="notifications.expiry" {...register("notifications.expiry")} defaultChecked={props.user.preferences && props.user.preferences.expiryNotificationEnabled}/><label style={{marginLeft: '20px'}} htmlFor="notifications.expiry">I want to receive an email notification when a permit is about to expire</label>
                      </div>
                      <div>
                        <input type="checkbox" name="notifications.insurance" {...register("notifications.insurance")} defaultChecked={props.user.preferences && props.user.preferences.insuranceNotificationEnabled}/><label style={{marginLeft: '20px'}} htmlFor="notifications.insurance">I want to receive an email notification when a permit related insurance is about to expire</label>
                      </div>
                      <div>
                        <input type="checkbox" name="notifications.status" {...register("notifications.status")} defaultChecked={props.user.preferences && props.user.preferences.statusChangeNotificationEnabled}/><label style={{marginLeft: '20px'}} htmlFor="notifications.status">I want to receive an email notification when a permit status is changed</label>
                      </div>
                      <div>
                        <input type="checkbox" name="notifications.app" {...register("notifications.app")} defaultChecked={props.user.preferences && props.user.preferences.appNotificationEnabled}/><label style={{marginLeft: '20px'}} htmlFor="notifications.app">I want to view notifications on this web application</label>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-between">
                    <div className="col">
                        <button className="btn btn-primary mb-2" onClick={() => props.cancelCallback()}>Cancel</button>
                    </div>
                    <div className="col text-right">
                      <button type="submit" className="btn btn-primary mb-2">Save</button>
                    </div>
                  </div>
                </div>
              </form>
        </div>
  );
}