import React from "react";

export default function ReceiptContent(props) {
  let cardIcons = [];
  let tenderIcons = [];
  cardIcons['V']='visa.png';
  cardIcons['M']='mastercard.png';
  cardIcons['AX']='amex.png';
  tenderIcons['CHEQUE']='cheque.png';
  tenderIcons['CASH']='dollar-bill.png';
  
  return (
    <div>
      <div className="container-fluid mt-4">
      <div style={{maxWidth:'600px'}}>
        <div className="p-4 text-center" >
          <div className="mb-4 receipt-logo"><img src="/img/YorkLogoBlue.png" alt="York Region Logo"/></div>
          { props.transaction.result !== 'd' ? <>
            <div>
                <img src="/img/green-check.png"  width="100px" alt="Green Checkmark"/>
            </div>
            <div className="mb-4">Your transaction has been approved</div>
          </> : <>
            <div>
                <img src="/img/red-x.png"  width="100px" alt="Red X"/>
            </div>
            <div className="mb-4 text-danger">Your transaction has been declined on permit request <strong>#{props.transaction.permitId}</strong></div>
          </>}
          { props.transaction.result !== 'd' && <h1 style={{fontSize:'1.3rem'}} className="mb-4">
          Receipt for payment on permit request <strong>#{props.transaction.permitId}</strong>
          </h1> }
          { props.transaction.orderNumber && <h1 style={{fontSize:'1.3rem'}} className="mb-2">
          Confirmation Order Number: <strong>{props.transaction.orderNumber}</strong>
          </h1>}
        </div>
        <div className="m-2">
          <div className="receipt-detail-section" style={{borderRadius:'5px 5px 0px 0px', borderStyle: 'solid', borderWidth: '1px', borderColor: '#b0b0b0', backgroundColor: '#F1F1F2'}}>
            <div class="row">
              <div className="col">
                { new Intl.DateTimeFormat("en-CA", {dateStyle: "short"}).format(new Date(props.transaction.transactionTimestamp)) } 
              </div>
              <div className="col">
                <div class="d-flex justify-content-end flex-row">
                  <div className={`payment-type mr-2 ${cardIcons[props.transaction.cardType] ? 'card-container' : ''}`}>
                    { (props.transaction.TENDER_TYPE === 'CCARD' || props.transaction.cardType) && <img src={`/img/${cardIcons[props.transaction.cardType] ? cardIcons[props.transaction.cardType] : 'credit-card-regular.svg'}`} alt="credit card"/> }
                    { props.transaction.TENDER_TYPE !== 'CCARD' && tenderIcons[props.transaction.TENDER_TYPE] && <img src={`/img/${tenderIcons[props.transaction.TENDER_TYPE]}`} alt="tender type icon"/> }
                  </div>
                  { props.transaction.cardNumberF6L4 && <div>**** **** **** {props.transaction.cardNumberF6L4.substr(props.transaction.cardNumberF6L4.length - 4)}</div> }
                </div>
              </div>
            </div>
            <div className="row">

            </div>
          </div>
          <div className="receipt-detail-section" style={{borderStyle: 'solid', borderWidth: '0px 1px 1px 1px', borderColor: '#b0b0b0'}}>
            {props.transaction.fees.map(fee => <div class="row">
              <div className="col text-right">{fee.FEE_DESCRIPTION}</div>
              <div className="col text-right">${fee.FEE_PAYMENT_AMOUNT.toFixed(2)}</div>
            </div>)}
            
          </div>
          <div className="receipt-detail-section" style={{borderStyle: 'solid', borderWidth: '0px 1px 1px 1px', borderColor: '#b0b0b0'}}>
            <div class="row font-weight-bold">
              <div className="col text-right">Total:</div>
              <div className="col text-right">${props.transaction.amount.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
  );
}
