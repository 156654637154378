import React from 'react';
import FormWizardField from '../../form-wizard/FormWizardField';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class RegionRoad extends React.Component {
    handleFieldChange = (event) => {
        let data = this.props.data;
        data[event.target.id] = event.target.value;
        this.props.handleRoadChange(data); // send data up a level
    }

    render(){
        let errors = this.props.errors[this.props.data.id] || {};
        return (
            <div className="mt-3">
                <div style={{position:'relative'}}>Location # {this.props.index + 1} {this.props.removeRoad && <i className="fas fa-times likebutton" style={{position:'absolute', right:'0px'}} onClick={() => this.props.removeRoad(this.props.data)} title='Delete road entry'></i> }</div>
                <div className="border border-dark rounded p-3">
                    <div className="form-row">
                        <div className="col-lg-6">
                            <FormWizardField fieldType="input" fieldName="locationRegionRoad" fieldLabel="Region road" required={true} handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-lg">
                            <FormWizardField fieldType="input" fieldName="locationFromRoad" fieldLabel="Between" handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                        </div>
                        <div className="col-lg">
                            <FormWizardField fieldType="input" fieldName="locationToRoad" fieldLabel="And" handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                        </div>
                    </div>
                    <div className="form-row mb-2"><div className="col-lg-6">OR</div></div>
                    <div className="form-row">
                        <div className="col-lg-6">
                            <FormWizardField fieldType="input" fieldName="locationDetail" fieldLabel="At (intersection or address number)" handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
class EncroachmentPermitInformationForm extends React.Component {
    state = {}

    componentDidMount(){
        //initialize the region road component data if applicable for form type
        if(this.props.type !== 'Road Occupancy Application - Entrance' && !this.props.data.regionRoads){
            const event = {target:{id:'regionRoads', value:[{id:1}]}};
            this.props.handleFieldChange(event); // send data up to FormWizard
        }
        if(this.props.form.applicantInformationForm.data.applicantSubtype === 'municipality') {
            const event = {target:{id:'locationCity', value: this.props.form.applicantInformationForm.data.owner}};
            this.props.handleFieldChange(event);
        }
    }
/*
    handleRoadChange = (updatedRoad) => {
        const roads = this.props.data.regionRoads;
        const index = roads.findIndex((e) => e.id === updatedRoad.id);
        roads[index] = updatedRoad;
        const event = {target:{id:'regionRoads', value:roads}};
        this.props.handleFieldChange(event); // send data up to FormWizard
    }

    addRoad = () => {
        const roads = this.props.data.regionRoads;
        const newRoad = {id: roads[roads.length-1].id + 1};
        roads.push(newRoad);
        const event = {target:{id:'regionRoads', value:roads}};
        this.props.handleFieldChange(event);
    }

    removeRoad = (roadToRemove) => {
        const roads = this.props.data.regionRoads;
        const filteredRoads = roads.filter((e) => e.id !== roadToRemove.id);
        if(filteredRoads.length === 0) return;
        const event = {target:{id:'regionRoads', value:filteredRoads}};
        this.props.handleFieldChange(event);
    }*/

    render(){
        console.log(this.props.form.applicantInformationForm.data.applicantSubtype)
        let isOwner = true;
        switch (this.props.form.applicantInformationForm.data.applicantSubtype) {
            case 'owner':
                break;
            case 'municipality':
                isOwner = false;
                break;
        }


        return (
            <div>
                <h5>Permit Information</h5>
                <p>Please provide the following information about the location</p>
                <p><span style={{"color":"red"}}>*</span> indicates a required field</p>

                <form>
                    <>
                        <label for="title">Applicant land</label>
                        <div className="form-row">
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" fieldName="businessName" fieldLabel="Business Name" required={true} {...this.props} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" fieldName="businessNumber" fieldLabel="Business Number" required={true} {...this.props} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" fieldName="location" fieldLabel="Location" required={false} {...this.props} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="unit">Occupancy Dates Required</label>
                            <div className="form-row">
                                <div className="col-lg-4">
                                    <FormWizardField fieldType="date" fieldName="occupancyFromDate" fieldLabel="From" required={true} {...this.props} />
                                </div>
                                <div className="col-lg-4">
                                    <FormWizardField fieldType="date" fieldName="occupancyToDate" minDate={this.props.data.occupancyFromDate} fieldLabel="To" required={true} {...this.props} />
                                </div>
                            </div>
                        </div>
                    </>
                  </form>
            </div>
        );
    }
}

export default EncroachmentPermitInformationForm;