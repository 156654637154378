import React from 'react';
import FormWizardField from '../../form-wizard/FormWizardField';
import FormWizardFileUpload from '../../form-wizard/FormWizardFileUpload';

class SupportingDocumentsForm extends React.Component {

    render(){
        console.log(this.props)
        return(
            <div>
                <h5>Supporting Documents</h5>
                <p>Please provide supporting documents. Individual file size is limited to 75MB</p>
                <p><span style={{"color":"red"}}>*</span> indicates a required field</p>

                <form>
                    { /** MUNICIPAL CONSENT **/}
                    { this.props.type === 'Municipal Consent' && (
                        <>
                            <FormWizardFileUpload fieldName="drawings" fieldLabel="Upload Drawings" required={true} description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file type: PDF<br />Please combine all drawings realted to the application in one PDF file.</div></div>" {...this.props}/>

                            <hr/>

                            <FormWizardField fieldType="checkbox" fieldName="drawingRequirementsReviewed" fieldLabel="I have reviewed the municipal consent submission requirements checklist" {...this.props} />
                            <FormWizardField fieldType="radio" fieldName="capitalDeliveryProject" fieldLabel="Is the work for a Regional Municipality of York capital delivery project?" required={true} radioOptions={[{label:'Yes', value:'yes'}, {label:'No', value: 'no'}]} {...this.props}/>

                            { this.props.data.capitalDeliveryProject === 'yes' && (
                                <div className="form-group">
                                    <label htmlFor="title">York Region Project Manager</label>
                                    <div className="form-row">
                                        <div className="col-lg-6">
                                            <FormWizardField fieldType="input" fieldName="projectManagerFirstName" fieldLabel="First Name" {...this.props}/>
                                        </div>
                                        <div className="col-lg-6">
                                            <FormWizardField fieldType="input" fieldName="projectManagerLastName" fieldLabel="Last Name" {...this.props}/>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <FormWizardField fieldType="radio" fieldName="drawingsStandardFollowed" fieldLabel="Does the design follow the region utility corridor drawings standard?" required={true} radioOptions={[{label:'Yes', value:'yes'}, {label:'No', value: 'no'}]} {...this.props}/>
                            { this.props.data.drawingsStandardFollowed === 'no' && <FormWizardField fieldType="textarea" fieldName="drawingsStandardNotFollowedReason" fieldLabel="Please explain" required={true} {...this.props} /> }
                        </>
                    )}

                    { /** ALL OTHER **/}
                    { this.props.type === 'Road Occupancy Application - Encroachment' && (
                     <>
                      <FormWizardFileUpload fieldName="drawings" fieldLabel="Drawing of Structure" required={true} description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file type: PDF<br />Please combine all drawings realted to the application in one PDF file.</div></div>" {...this.props}/>
                      <FormWizardFileUpload fieldName="otherDocuments" allowMultiple={true}  fieldLabel="Other Document (e.g. Block Map)" acceptedFileTypes={['application/pdf','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']} description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file types: PDF, DOCX, XSLX</div></div>" {...this.props}/>
                     </>
                     )}


                    { !['Municipal Consent', 'Road Occupancy Application - Encroachment'].includes(this.props.type) && (
                        <>
                            <FormWizardFileUpload fieldName="insurance" fieldLabel="Insurance Certificate" required={true} description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file type: PDF</div></div>" {...this.props}/>
                            {
                                !['Road Occupancy Application - Temporary Road Sign',
                                  'Road Occupancy Application - Excess Load',
                                  'Road Occupancy Application - Load Exemption'
                                ].includes(this.props.type) &&
                                this.props.form.trafficManagementPlanForm && this.props.form.trafficManagementPlanForm.data.planIncludedInDrawings === 'yes' &&
                                <FormWizardFileUpload fieldName="plan" fieldLabel="Custom Traffic and Detour Plan" required={true} description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file type: PDF</div></div>" {...this.props}/>
                            }
                            {
                             ['Road Occupancy Application - Temporary Road Sign'].includes(this.props.type) && this.props.form.permitInformationForm.data.subtype === 'Charitable or community event' &&
                             <FormWizardFileUpload fieldName="townLetter" fieldLabel="Town / City Sponsorship Letter" description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file type: PDF</div></div>" {...this.props}/>
                            }
                            {
                             ['Road Occupancy Application - Survey and Inspection',
                              'Road Occupancy Application - Geotechnical'
                             ].includes(this.props.type) &&
                             <FormWizardFileUpload fieldName="workLocation" fieldLabel="Work Location Map" description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file type: PDF</div></div>" {...this.props} required={true} />
                            }
                            {
                             ['Road Occupancy Application - Excess Load'].includes(this.props.type) &&
                             <FormWizardFileUpload fieldName="loadDistributionDiagram" fieldLabel="Load Distribution Diagram" description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file type: PDF</div></div>" {...this.props}/>
                            }
                            {
                                ['Road Occupancy Application - Entrance',
                                 'Road Occupancy Application - Development'].includes(this.props.type) &&
                                <FormWizardFileUpload fieldName="drawings" fieldLabel="Site Drawings" required={true} description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file type: PDF</div></div>" {...this.props}/>
                            }
                            <FormWizardFileUpload fieldName="otherDocuments" allowMultiple={true} fieldLabel="Other Documents" acceptedFileTypes={['application/pdf','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']} description="<div class='row'><div class='col-auto'><i class='fas fa-arrow-circle-up' style='font-size: 3em;'></i></div><div class='col-auto text-left'>Drag & Drop your files or <span class='filepond--label-action'> Browse </span><br/> Accepted file types: PDF, DOCX, XSLX</div></div>" {...this.props}/>
                        </>
                    )}
                </form>
            </div>
        )
    }
}

export default SupportingDocumentsForm;