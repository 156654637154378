import React from 'react';
import FormWizardField from './../../form-wizard/FormWizardField';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class TimePermitted extends React.Component{
    handleFieldChange = (event) => {
        let data = this.props.data;
        data[event.target.id] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.props.handleTimePermittedChange(data); // send data up a level
    }

    render(){
        let errors = this.props.errors[this.props.data.id] || {};
        return (
            <>
                { this.props.zoneRequired.includes('Road Allowance') && (
                    <div className="form-row">
                        <div className="col-lg">
                            <FormWizardField fieldType="select" fieldLabel={this.props.index === 0 ? 'Day(s)' : null} required={true} fieldName="days" data={this.props.data} handleFieldChange={this.handleFieldChange} errors={errors}>
                                <option></option>
                                <option>Weekday (Monday - Friday)</option>
                                <option>Weekend (Saturday)</option>
                                <option>Sunday and / or Public Holiday</option>
                            </FormWizardField>
                        </div>
                        <div className="col-lg">
                            <FormWizardField fieldType="time" fieldLabel={this.props.index === 0 ? 'From' : null} required={true} fieldName="fromTime" data={this.props.data} handleFieldChange={this.handleFieldChange} errors={errors}/>
                        </div>
                        <div className="col-lg">
                            <FormWizardField fieldType="time" minTime={this.props.data.fromTime} fieldLabel={this.props.index === 0 ? 'To' : null} required={true} fieldName="toTime" data={this.props.data} handleFieldChange={this.handleFieldChange} errors={errors}/>
                        </div>
                        {
                            this.props.removeTimePermitted && <div className="col-lg-1">
                                <i className="fas fa-times likebutton" onClick={() => this.props.removeTimePermitted(this.props.data)} title='Delete time permitted entry'></i>
                            </div>
                        }
                    </div>
                )}

                { this.props.zoneRequired.includes('Closure') && (
                    <>
                        <div className="form-row">
                            <FormWizardField fieldType="checkbox" fieldLabel="Standard Daytime Hours (9:30am - 3:30pm, Monday - Friday)" fieldName="standardDaytimeHours" data={this.props.data} handleFieldChange={this.handleFieldChange} errors={errors}></FormWizardField>
                        </div>
                        <div className="form-row">
                            <FormWizardField fieldType="checkbox" fieldLabel="Standard Nighttime Hours (9:00pm - 5:00am, Monday - Friday)" fieldName="standardNighttimeHours" data={this.props.data} handleFieldChange={this.handleFieldChange} errors={errors}></FormWizardField>
                        </div>
                        <div className="form-row">
                            <FormWizardField fieldType="checkbox" fieldLabel="Weekend Hours (9:00pm Friday - 5:00am Monday)" fieldName="weekendHours" data={this.props.data} handleFieldChange={this.handleFieldChange} errors={errors}></FormWizardField>
                        </div>
                        <div className="form-row">
                            <em>Please contact the permits group for further review if peak hour lane closures are required</em>
                        </div>
                        {
                            (this.props.data.standardNighttimeHours === true ||  this.props.data.weekendHours === true) && <div className="form-row"><em>Noise by-law exemption required by local municipality</em></div>
                        }
                    </>
                )}
            </>
        )
    }
}

class TrafficManagementPlan extends React.Component {
    componentDidMount(){
        // set default dates
        if(!this.props.data.dateFrom && !this.props.data.dateTo){
            this.handleFieldChange({target:{id:'dateFrom', value: this.props.defaultDateFrom}});
            this.handleFieldChange({target:{id:'dateTo', value: this.props.defaultDateTo}});
        }
    }

    handleFieldChange = (event) => {
        let data = this.props.data;
        data[event.target.type === 'radio' ? event.target.name : event.target.id] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.props.handlePlanChange(data); // send data up a level
    }

    handleTimePermittedChange = (updatedTimePermitted) => {
        const data = this.props.data;
        const index = data.timesPermitted.findIndex(e => e.id === updatedTimePermitted.id);
        data.timesPermitted[index] = updatedTimePermitted;
        this.props.handlePlanChange(data);
    }

    addTimePermitted = () => {
        const data = this.props.data;
        data.timesPermitted.push({id: data.timesPermitted[data.timesPermitted.length-1].id + 1}); // get last id and add 1
        this.props.handlePlanChange(data);
    }

    removeTimePermitted = (timePermittedToRemove) => {
        const data = this.props.data;
        const filteredTimesPermitted = data.timesPermitted.filter(e => e.id !== timePermittedToRemove.id);
        if(filteredTimesPermitted.length === 0) return;
        data.timesPermitted = filteredTimesPermitted;
        this.props.handlePlanChange(data);
    }

    render(){
        let errors = this.props.errors[this.props.data.id] || {};
        return (
            <div className="mt-3">
                <div style={{position:'relative'}}>
                    Traffic Management Plan # {this.props.index + 1} &nbsp;
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Add multiple traffic management plans to include all applicable zones</Tooltip>}>
                        <i class="fas fa-info-circle"></i>
                    </OverlayTrigger>
                     {this.props.removePlan && <>&nbsp; &nbsp; <i className="fas fa-times likebutton" style={{position:'absolute', right:'0px'}} onClick={() => this.props.removePlan(this.props.data)} title='Delete traffic management plan'></i></>} </div>
                <div className="border border-dark rounded p-3">
                    <div className="form-row">
                        <div className="col-lg-6">
                            <FormWizardField fieldType="select" fieldName="zoneRequired" fieldLabel="Zone Required" required={true} handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}>
                                <option></option>
                                <option>Road Allowance - Sidewalk</option>
                                <option>Road Allowance - Boulevard / Shoulder</option>
                                <option>Road Allowance - Multiuse Trail</option>
                                <option>Lane Closure - Partial</option>
                                <option>Lane Closure - Full</option>
                                <option>Lane Closure - Multiple</option>
                                <option>Lane Closure - Mobile (No Stopping)</option>
                                <option>Road Closure - Full Closure</option>
                                <option>Road Closure - Intermittent Closure</option>
                            </FormWizardField>
                        </div>
                    </div>

                    <hr />

                    <div className="form-group">
                        <label>Dates Permitted</label>
                        <div className="form-row">
                            <div className="col-lg-4">
                                <FormWizardField fieldType="date" fieldName="dateFrom" fieldLabel="From" required={true} handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                            </div>
                            <div className="col-lg-4">
                                <FormWizardField fieldType="date" fieldName="dateTo" minDate={this.props.data.dateFrom} fieldLabel="To" required={true} handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                            </div>
                        </div>
                    </div>

                    <hr />

                    { this.props.data.zoneRequired && (this.props.data.zoneRequired.includes('Road Allowance') || this.props.data.zoneRequired.includes('Lane Closure')) && (
                        <>
                        <div className="form-group">
                            <label>Times Permitted</label>
                            {
                                this.props.data.timesPermitted.map((timePermitted, index) => <TimePermitted key={timePermitted.id} index={index} data={timePermitted} removeTimePermitted={this.props.data.timesPermitted.length > 1 && this.removeTimePermitted} handleTimePermittedChange={this.handleTimePermittedChange} errors={errors.timesPermitted || {}} zoneRequired={this.props.data.zoneRequired}/>)
                            }

                            { this.props.data.zoneRequired.includes('Road Allowance')  && (
                                <div className="likebutton" onClick={this.addTimePermitted}><i className="fas fa-plus"></i>&nbsp; Add Time</div>
                            )}
                        </div>
                        <hr />
                        </>
                    )}

                    { this.props.data.zoneRequired && this.props.data.zoneRequired.includes('Lane Closure') && (
                        <>
                        <div>
                            <FormWizardField fieldType="radio" fieldName="inVivaNextZone" fieldLabel="In VivaNext Zone?" required={true} radioOptions={[{label:'Yes', value:'yes'}, {label:'No', value: 'no'}]} handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                        </div>
                        <hr />
                        </>
                    )}

                    { this.props.data.zoneRequired && this.props.data.zoneRequired.includes('Road Closure') && (
                        <>
                        <div className="form-group">
                            <label>Road Closure Memo</label>
                            <div className="form-group">
                            <div className="form-row">
                                <div className="col-lg-6">
                                    <FormWizardField fieldType="input" fieldName="roadClosureMemoNumber" fieldLabel="Road Closure Memo No." handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                                </div>
                                <div className="col-lg-6">
                                    <FormWizardField fieldType="date" fieldName="roadClosureMemoDate" fieldLabel="Road Closure Memo Date" handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                                </div>
                            </div>
                            </div>
                        </div>
                        </>
                    )}

                    { this.props.planIncludedInDrawings === 'no' && 
                    <div className="form-group">
                        <div className="form-row">
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" fieldName="figureId" fieldLabel="OTM Book 7 Figure ID" required={true} handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}

class TrafficManagementPlanForm extends React.Component {
    componentDidMount(){
        if(!this.props.data.plans){
            const event = {target:{id:'plans', value:[{id:1, timesPermitted:[{id:1}]}]}};
            this.props.handleFieldChange(event); // send data up to FormWizard
        }
    }

    handlePlanChange = (updatedPlan) => {
        const plans = this.props.data.plans;
        const index = plans.findIndex((e) => e.id === updatedPlan.id);
        plans[index] = updatedPlan;
        const event = {target:{id:'plans', value:plans}};
        this.props.handleFieldChange(event); // send data up to FormWizard
    }

    addPlan = () => {
        const plans = this.props.data.plans;
        const newPlan = {id: plans[plans.length-1].id + 1, timesPermitted: [{id: 1}]};
        plans.push(newPlan);
        const event = {target:{id:'plans', value:plans}};
        this.props.handleFieldChange(event);
    }

    removePlan = (planToRemove) => {
        const plans = this.props.data.plans;
        const filteredPlans = plans.filter((e) => e.id !== planToRemove.id);
        if(filteredPlans.length === 0) return;
        const event = {target:{id:'plans', value:filteredPlans}};
        this.props.handleFieldChange(event);
    }

    render(){
        // determine default dates
        let defaultDateFrom = this.props.form['permitInformationForm'] ? this.props.form['permitInformationForm'].data.occupancyFromDate : null;
        let defaultDateTo = this.props.form['permitInformationForm'] ? this.props.form['permitInformationForm'].data.occupancyToDate : null;

        return(
            <div>
                <h5>Traffic Management</h5>
                <p><span style={{"color":"red"}}>*</span> indicates a required field</p>
                <form>
                    <FormWizardField fieldType="radio" fieldName="planIncludedInDrawings" fieldLabel="Is a custom traffic and detour plan required?" required={true} radioOptions={[{label:'Yes', value:'yes'}, {label:'No', value: 'no'}]} {...this.props}/>

                    <hr/>

                    {
                        (this.props.data.plans && this.props.data.plans.length > 0) && this.props.data.plans.map((plan, index) => <TrafficManagementPlan key={plan.id} index={index} data={plan} handlePlanChange={this.handlePlanChange} removePlan={this.props.data.plans.length > 1 && this.removePlan} planIncludedInDrawings={this.props.data.planIncludedInDrawings} errors={this.props.errors} defaultDateFrom={defaultDateFrom} defaultDateTo={defaultDateTo} />)
                    }
                    <div className="likebutton mb-3" onClick={this.addPlan}><i className="fas fa-plus"></i>&nbsp; Add Traffic Management Plan</div>
                </form>
            </div>
        );
    }
}

export default TrafficManagementPlanForm;