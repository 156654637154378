import React from "react";

export default function OrgList(props){
    return (
        <div>
        {props.profile.memberships.map((membership) => (
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12" key={membership._id}>
              <div className="card mb-2" key={membership._id}>
                <div className="card-header">
                  { membership.roles.some(role => role.name === 'admin' || role.name === 'owner') && <i className="fas fa-edit mr-2 likebutton" onClick={() => props.editOrgCallback(membership.organization)}></i>
                  }
                  
                  {membership.organization.name}
                  {membership.roles.map((role) => (
                    <span
                      className="badge badge-secondary"
                      style={{ position: "absolute", top: "0", right: "0" }}
                      key={role.name}
                    >
                      {role.label}
                    </span>
                  ))}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      {membership.organization.address && (
                        <div>
                          <div className="text-muted">Address</div>
                          <div>
                            {membership.organization.address.streetNumber}{" "}
                            {membership.organization.address.streetName}
                          </div>
                          <div>
                            {membership.organization.address.city},{" "}
                            {membership.organization.address.province}
                          </div>
                          {membership.organization.address.postalCode && (
                            <div>
                              {membership.organization.address.postalCode}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col">
                      {membership.organization.primaryPhone && (
                        <div>
                          <div className="text-muted">
                            Phone (
                            {membership.organization.primaryPhone.type}){" "}
                          </div>
                          <div>
                            {
                              membership.organization.primaryPhone
                                .phoneNumber
                            }
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>
    )
}