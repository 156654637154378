import React, {useContext} from 'react';
import { UserContext } from '../UserContext';

export default function FormWizardButtonGroup(props){
    const userContext = useContext(UserContext);

    let showPrevious = props.currentStepComponent.type.displayName === 'FormWizardStep' && props.previousStep > -1;
    let showNext = props.currentStepComponent.type.displayName === 'FormWizardStep' && props.nextStep < props.totalDisplaySteps;
    let showSubmit = props.currentStepComponent.type.displayName === 'FormWizardStep' && props.nextStep >= props.totalDisplaySteps;
    let signInUrl = `/auth/azure?p=${process.env.REACT_APP_SIGN_IN_POLICY}`;

    return (
        <div className="wizard-buttons d-flex justify-content-between">
            { showPrevious ? <button type="button" className="btn btn-primary" onClick={props.triggerPreviousStep}>Previous</button> : <div></div> }
            { showNext ? <button type="button" className="btn btn-primary" onClick={props.triggerNextStep} disabled={props.validating}>
                { props.validating && <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight:'5px'}}></span><span>Validating...</span></> }
                { !props.validating && 'Next' }
            </button> : ''}
            { showSubmit ? <button type="button" className="btn btn-primary" onClick={props.submitForm} disabled={props.validating || !userContext.user}>
                { props.validating && <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight:'5px'}}></span><span>Validating...</span></> }
                { !props.validating && 'Submit' }
            </button> : ''}
            { !userContext.user && showSubmit && <button type="button" className="btn btn-primary mr-2" onClick={userContext.login}>Login</button>}
        </div>
    )
}
