import React from 'react';
import { UserContext } from '../../UserContext';

class PermitResultForm extends React.Component {
    render(){
        return(
            <UserContext.Consumer>
            { context => (
                <div>
                    <h4>Your request has been successfully submitted</h4>
                    <br/>
                    <h5>Your request number is <b>{this.props.id}</b>, please record this for future correspondence.</h5>
                    <h5>An email has been sent to {context.user.email} with a copy of your request.</h5>
                </div>
            )}
            </UserContext.Consumer>
        )
    }
}

export default PermitResultForm;