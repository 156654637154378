import React from 'react';
import FormWizardField from './../../form-wizard/FormWizardField';

class InvoiceInformationForm extends React.Component {
    componentDidMount(){
        if(this.props.data.invoiceSource === 'applicant'){
            this.populateInvoiceFromApplicant(false);
        }
    }

    changeInvoiceSource = (event) => {
        this.props.handleFieldChange(event);

        if(event.target.value === 'applicant'){
            this.populateInvoiceFromApplicant(false);
        }else if(event.target.value === 'unique'){
            this.populateInvoiceFromApplicant(true);
        }
    }

    populateInvoiceFromApplicant = (clear) => {
        this.props.handleFieldChange({target:{id:'invoiceOwner', type: 'text', value: (this.props.form.applicantInformationForm.data.owner)}});
        this.props.handleFieldChange({target:{id:'invoiceTitle', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.title)}});
        this.props.handleFieldChange({target:{id:'invoiceFirstName', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.firstName)}});
        this.props.handleFieldChange({target:{id:'invoiceLastName', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.lastName)}});
        this.props.handleFieldChange({target:{id:'invoiceUnit', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.unit)}});
        this.props.handleFieldChange({target:{id:'invoiceStreet', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.street)}});
        this.props.handleFieldChange({target:{id:'invoiceCity', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.city)}});
        this.props.handleFieldChange({target:{id:'invoiceProvince', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.province)}});
        this.props.handleFieldChange({target:{id:'invoicePostalCode', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.postalCode)}});
        this.props.handleFieldChange({target:{id:'invoicePhone', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.phone)}});
        this.props.handleFieldChange({target:{id:'invoiceExtension', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.extension)}});
        this.props.handleFieldChange({target:{id:'invoiceCell', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.cell)}});
        this.props.handleFieldChange({target:{id:'invoiceEmail', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.email)}});
        this.props.handleFieldChange({target:{id:'invoiceEmailverify', type: 'text', value: (clear ? '' : this.props.form.applicantInformationForm.data.emailverify)}});
    }

    render(){
        return(
            <div>
                <h5>Invoice Information</h5>
                <p>Please provide contact information for the person or organization to receive invoice</p>
                <p><span style={{"color":"red"}}>*</span> indicates a required field</p>

                <form>
                    <FormWizardField fieldType="radio" fieldName="invoiceSource" fieldLabel="Please choose the source for invoice information" required={true} radioOptions={[{label:'Use applicant information for invoice', value:'applicant'}, {label:'Use different information for invoice', value: 'unique'}]} {...this.props} handleFieldChange={this.changeInvoiceSource} />

                    <hr />

                    { this.props.data.invoiceSource &&
                        <>
                        <FormWizardField fieldType="input" fieldName="invoiceOwner" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="Business / Organization Name" required={true} {...this.props}/>

                        <div className="form-group">
                            <label htmlFor="invoiceFirstName">Contact Name</label>
                            <div className="form-row">
                                <div className="col-lg-6">
                                    <FormWizardField fieldType="input" fieldName="invoiceFirstName" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="First Name" required={true} {...this.props}/>
                                </div>
                                <div className="col-lg-6">
                                    <FormWizardField fieldType="input" fieldName="invoiceLastName" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="Last Name" required={true} {...this.props}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-lg-6">
                                    <FormWizardField fieldType="input" fieldName="invoiceTitle" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="Job Title" {...this.props}/>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="form-group">
                            <label htmlFor="invoiceStreet">Business Address</label>
                            <div className="form-row">
                                <div className="col">
                                    <FormWizardField fieldType="input" fieldName="invoiceStreet" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="Street" required={true} {...this.props}/>
                                </div>
                                <div className="col-lg-2">
                                    <FormWizardField fieldType="input" fieldName="invoiceUnit" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="Unit Number" {...this.props}/>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-lg-5">
                                    <FormWizardField fieldType="input" fieldName="invoiceCity" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="City" required={true} {...this.props}/>
                                </div>
                                <div className="col-lg-4">
                                    <FormWizardField fieldType="select" fieldName="invoiceProvince" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="Province" required={true} {...this.props}>
                                        <option></option>
                                        <option value="AB">Alberta</option>
                                        <option value="BC">British Columbia</option>
                                        <option value="MB">Manitoba</option>
                                        <option value="NB">New Brunswick</option>
                                        <option value="NL">Newfoundland and Labrador</option>
                                        <option value="NS">Nova Scotia</option>
                                        <option value="ON">Ontario</option>
                                        <option value="PE">Prince Edward Island</option>
                                        <option value="QC">Quebec</option>
                                        <option value="SK">Saskatchewan</option>
                                        <option value="NT">Northwest Territories</option>
                                        <option value="NU">Nunavut</option>
                                        <option value="YT">Yukon</option>
                                    </FormWizardField>
                                </div>
                                <div className="col-md-3">
                                    <FormWizardField fieldType="input" fieldName="invoicePostalCode" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="Postal Code" required={true} {...this.props}/>
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className="form-group">
                            <label htmlFor="invoicePhone">Business Phone</label>
                            <div className="form-row align-items-end">
                                <div className="col-lg-4">
                                    <FormWizardField fieldType="input" fieldName="invoicePhone" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="Telephone Number (10 digit)" required={true} {...this.props}/>
                                </div>
                                <div className="col-lg-4 pr-4">
                                    <FormWizardField fieldType="input" fieldName="invoiceExtension" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="Extension" {...this.props} maxLength="6"/>
                                </div>
                                <div className="col-lg-4">
                                <FormWizardField fieldType="input" fieldName="invoiceCell" readonly={this.props.data.invoiceSource === 'applicant'} fieldLabel="Cell Phone (10 digit)" {...this.props}/>
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className="form-group">
                            <label htmlFor="invoiceEmail">Business Email</label>
                            <div className="form-row">
                                <div className="col-lg-6">
                                    <FormWizardField fieldType="input" inputType="email" readonly={this.props.data.invoiceSource === 'applicant'} fieldName="invoiceEmail" fieldLabel="Enter Email" required={true} {...this.props}/>
                                </div>
                                <div className="col-lg-6">
                                    <FormWizardField fieldType="input" inputType="email" readonly={this.props.data.invoiceSource === 'applicant'} fieldName="invoiceEmailverify" fieldLabel="Confirm Email" required={true} autoComplete="new-password" {...this.props}/>
                                </div>
                            </div>
                        </div>
                        </>
                    }
                </form>
            </div>
        );
    }
}

export default InvoiceInformationForm;