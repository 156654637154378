import React, { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';

export default function AppToastNotification(props) {
    const [showNotification, setShowNotification] = useState(true);

    const dismissNotification = () => {
      setShowNotification(false);
      fetch(`/api/dismissNotification?id=${props._id}`);
    }

    return (
        <Toast key={props._id} show={showNotification} onClose={dismissNotification} style={{fontSize: '16px'}}>
          <Toast.Header><div className='mr-auto' dangerouslySetInnerHTML={{__html: props.header}}/></Toast.Header>
          <Toast.Body><div dangerouslySetInnerHTML={{__html: props.body}}/></Toast.Body>
        </Toast>
    );
}