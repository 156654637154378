import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
const { profileConfig } = require('../config/profileFormConfig');

export default function MembershipRequest(props){
    const [authorizedMemberships, setAuthorizedMemberships] = useState([]);
    const { register, unregister, watch, handleSubmit, formState: { errors }, getValues, setValue } = useForm();
    watch();

    // run when component is mounted
    useEffect(() => {
        setAuthorizedMemberships(props.profile.memberships ? props.profile.memberships.filter(membership => membership.roles.some(role => role.name === 'admin' || role.name === 'owner')) : []);
    }, []);

    const grantRequest = handleSubmit(data => {
        data.grant = true;
        onSubmit(data);
    });

    const denyRequest = (e) => {
        e.preventDefault();
        unregister('requestedOrg');
        handleSubmit(data => {
            debugger;
            data.grant = false;
            onSubmit(data);
        })();
    }
    
    const onSubmit = (data) => {
        let uri = data.grant ? 'grant-org-access' : 'deny-org-access';
        data.membershipRequest = props.membershipRequest._id;
        if(data.grant){
            data.customer = props.membershipRequest.requestedBy;
            data.requestedOrg = authorizedMemberships.find(membership => membership.organization._id === data.requestedOrg).organization;
        }
        fetch(`/api/${uri}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((response) => {
            if (!response.ok) throw Error(response.statusText);
            props.submitCallback(data.grant ? 'Access has been granted and the requester will be notified' : 'Access has been denied and the requester will be notified');
        })
        .catch((err) => {
            console.error(err);
        });
    }

    return (
        <div>
            <p><b>{props.membershipRequest.requestedBy.firstName} {props.membershipRequest.requestedBy.lastName}</b> ({props.membershipRequest.requestedBy.accountEmail}) is requesting <b>{props.membershipRequest.role}</b> access to your business.</p>
            {<p>{profileConfig.roleHelp[props.membershipRequest.role]}</p>}
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <form>
                <div className="form-group">
                    <label htmlFor="requestedOrg">Account for Access</label>
                    <select
                        name="requestedOrg"
                        className={
                            "form-control" + (errors.requestedOrg ? " is-invalid" : "")
                        }
                        {...register("requestedOrg",{
                            required: "Business account is required",
                            })}
                        disabled={authorizedMemberships.length === 0 ? true : false}
                    >
                        <option value=""></option>
                        {
                            authorizedMemberships.map((membership) => (
                                membership.roles.some(role => role.name === 'admin' || role.name === 'owner') && (
                                <option key={membership.organization._id} value={membership.organization._id}>{membership.organization.name}</option>
                                )
                            ))
                        }
                    </select>
                    {errors.requestedOrg && (
                        <div className="invalid-feedback">
                            {errors.requestedOrg.message}
                        </div>
                    )}
                    
                    {authorizedMemberships.length > 0 &&
                        <small className="form-text text-muted">If you are the owner or admin of a business account, you can choose which business account you would like to give access to the requesting person.</small>
                    }
                </div>
                
                <div className="form-group">
                    <label htmlFor="role">Access Role</label>
                    <select
                        name="role"
                        className={
                            "form-control" + (errors.role ? " is-invalid" : "")
                        }
                        {...register("role",{
                            required: "Access role is required",
                            })}
                        disabled={authorizedMemberships.length === 0 ? 'true' : ''}
                        defaultValue={props.membershipRequest.role}
                        
                    >
                        <option>contractor</option>
                        <option>view</option>
                        <option>create</option>
                        <option>admin</option>
                    </select>
                    {errors.role && (
                        <div className="invalid-feedback">
                            {errors.role.message}
                        </div>
                    )}

                    {props.membershipRequest.role !== (getValues('role') || props.membershipRequest.role) && 
                        <small className="form-text text-danger">You have changed the access role to {getValues('role')}. This is not the requested access role.</small>
                    }
                </div>

                {authorizedMemberships.length === 0 &&
                    <p><span className="text-danger">You are not the owner or administrator of a business account.</span> To allow access to a business you must be an admin or create a new business account <a href="/myprofile/edit/businesses">here</a>.</p> 
                }

                <div className="row justify-content-between">
                    <div className="col text-left">
                        <button type="reset" className="btn btn-primary mr-1" onClick={props.cancelCallback}>Cancel</button>
                        <button className="btn btn-danger" onClick={denyRequest}>Deny Access</button>
                    </div>
                    <div className="col text-right">
                        <button className="btn btn-success" onClick={grantRequest}>Grant Access</button>
                    </div>
                </div>
            </form>
        </div>
    )
}