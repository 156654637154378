import React from 'react';
import FormWizardField from './../../form-wizard/FormWizardField';
const axios = require('axios');

class FeeInformationForm extends React.Component {
    componentDidMount(){
        axios.get(`/api/getPermitConfig/${this.props.type}`, {headers: { Pragma: 'no-cache'}}).then((response) => {
            if (response.data) {
                let event = {target:{id:'baseFeeMsg', value:response.data.baseFeeMsg}};
                this.props.handleFieldChange(event);
            }
        });
    }

    render(){
        // defaults
        let pavementDegradation = true;
        let roadClosures = true;

        switch (this.props.type) {
            case 'Road Occupancy Application - Event':
            case 'Road Occupancy Application - Film & Photo':
                pavementDegradation = false;
                break;
            case 'Road Occupancy Application - Load Exemption':
                pavementDegradation = false;
                roadClosures = false;
                break;
            case 'Road Occupancy Application - Temporary Road Sign':
            case 'Road Occupancy Application - Excess Load':
            case 'Road Occupancy Application - Encroachment':
            case 'Municipal Consent':
                roadClosures = false;
                break;
            default:
                break;
        }


        return(
            <div>
                <h5>Fees</h5>
                {   roadClosures &&
                 <>
                    <p>Please fill in the following information. A representative will contact you with a total fee amount for the permit.</p>
                    <form>
                        <div className="form-group">
                            <label htmlFor="title">Road Closures and Peak Hours Lane Closure (If approved by
                                Transportation Services)</label>
                            <div className="form-row">
                                <div className="col-lg-3 col-sm-6">
                                    <FormWizardField fieldType="input" fieldName="numberOfDays"
                                                     fieldLabel="Number of Days" {...this.props}/>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <FormWizardField fieldType="input" fieldName="numberOfLanes"
                                                     fieldLabel="Number of Lanes" {...this.props}/>
                                </div>
                            </div>
                        </div>
                        {
                            pavementDegradation &&
                            <div className="form-group">
                                <label htmlFor="title">Pavement Degradation</label>
                                <div className="form-row">
                                    <div className="col-lg-3 col-sm-4">
                                        <FormWizardField fieldType="input" fieldName="totalLength"
                                                         fieldLabel="Total Length (m)" {...this.props}/>
                                    </div>
                                    <div className="col-lg-3 col-sm-4">
                                        <FormWizardField fieldType="input" fieldName="totalWidth"
                                                         fieldLabel="Total Width (m)" {...this.props}/>
                                    </div>
                                    <div className="col-lg-3 col-sm-4">
                                        <FormWizardField fieldType="input" fieldName="numberOfLocations"
                                                         fieldLabel="Number of Locations" {...this.props}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </form>
                    </>
                }
                <p dangerouslySetInnerHTML={{__html: this.props.data.baseFeeMsg}}></p>
            </div>
        )
    }
}

export default FeeInformationForm
