import React, { Component, Suspense, createRef } from 'react';
import {BrowserRouter, Route, Redirect, NavLink} from "react-router-dom";
import Navigation from './components/Navigation';
import Header from './components/Header';
import PermitRequestList from './components/PermitRequestList';
//import MyRequests from './components/MyRequests';
import Logout from './components/Logout';
import LoginFail from './components/LoginFail';
import { UserContext } from './components/UserContext';
import { MonerisProvider } from './components/MonerisProvider';
import RoadPermitType from './components/road-permit-forms/RoadPermitType';
import TermsAndConditions from './components/TermsAndConditions';
import PermitRequest from './components/PermitRequest';
import BrowserNotSupported from './components/BrowserNotSupported';
import MyProfile from './components/profile/MyProfile';
import EditMyProfile from './components/profile/EditMyProfile';
import MyNotifications from './components/notification/MyNotifications';
import AppToastNotification from './components/notification/AppToastNotification';
import Spinner from './components/Spinner';
import PermitPayment from './components/PermitPayment';
import PermitPaymentReceipt from './components/PermitPaymentReceipt';
import PermitDeclinedPaymentReceipt from './components/PermitDeclinedPaymentReceipt';

import Toast from "react-bootstrap/Toast";
import PermitRenewal from "./components/road-permit-forms/PermitRenewal";
import ReleaseNotes from "./components/ReleaseNotes";

const MyRequests = React.lazy(() => import('./components/MyRequests'));
const SharedRequests = React.lazy(() => import('./components/SharedRequests'));

const axios = require('axios');

const authenticate = async () => {
  const response = await axios.get('/api/getUser?refresh=true', {headers: { Pragma: 'no-cache'}});
  if(response.data) return response.data;

  setTimeout(() => {window.location.href = `/auth/azure?p=${process.env.REACT_APP_SIGN_IN_POLICY}`;},1500);
  return false;
}

//TODO: might want to refactor this function
const closestByIds = function(el, ids) {
  // Traverse the DOM up with a while loop
  // eslint-disable-next-line no-loop-func
  while (!ids.some(id => id === ((el && el.id) || null))) {
      if(!el) return null;
      // Increment the loop to the parent node
      el = el.parentNode;
      if (!el) return null;
  }

  return el;
}

/**
 * Use this component to protect a route behind authentication.
 */
class PrivateRoute extends Component {
  render(){
    const { component:Component, ...rest } = this.props;
    return (
      <UserContext.Consumer>
        { context =>
        <Route {...rest} render={(props) => (
          context.user ? <Component {...props} {...context}/>
          : <div>Please login to view this page</div>
        )}/>
        }
      </UserContext.Consumer>
    )
  }
}

const RedirectAfterLogin = (props) => {
  const redirectPath = window.localStorage.getItem("redirectUrl");
  window.localStorage.removeItem("redirectUrl");
  return (
    <Redirect to={redirectPath}/>
  )
}

/**
 * Run authentication check before rendering. This is the first place that the app
 * will check the server to see if user has authenticated.
 * This was moved from the main app component to here to support automatic login re-direct.
 * If user is not authenticated, the render prop function won't fire and will instead render the 'redirect message'
 * and redirect the user to the login page.
 */
class AuthenticateBeforeRender extends Component {
  state = {
    isAuthenticated: false,
  }

  componentDidMount() {
    // main authentication check method will check for auth on server and if none, will redirect user to login
    authenticate().then(user => {
      if(!user){
        //if no user, then we will redirect to authentication page so we have to store redirect url to return to
        window.localStorage.setItem("redirectUrl", this.props.location.pathname);
      }
      this.props.setUser(user); // this comes first so the component won't render until the app knows about the authenticated user
      this.setState({ isAuthenticated: !!user });
    })
  }

  render() {
    return this.state.isAuthenticated ? this.props.render() :
    (
      <div className="d-flex flex-row">
        <div className="spinner-border text-secondary mr-3" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        <h3>
          Redirecting to login ...
        </h3>
      </div>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {navOpen:false, user: null, fetchingUser: null, browserAck: false, loading: true};
    this.monerisRef = createRef();

    if (window.innerWidth > 700) {
      setTimeout(() => {
        this.setState({
          navOpen: true
        });
      }, 150);
    }
  }

  componentDidMount(){
    // hold the app until we've checked for a user
    Promise.all([this.refreshUser(), this.fetchNotifications()]).then(() => this.setState({loading:false}));
    window.addEventListener('click', this.handleClick);
  }

  handleClick = (event) => {
    // hide user menu if clicked anywhere else
    if(!closestByIds(event.target.parentNode, ['userMenu', 'userMenuIcon'])){
      let userMenu = document.querySelector('#userMenu');
      if(userMenu) userMenu.classList.remove('show');
    }
  }

  permitSubmitted = (props) => {
    let permitId = props.match.params.permitId;
    return (
      <div>
        <h5>Your permit has been submitted, please record the permit request # below for your records.</h5>
        <h2>{permitId}</h2>
      </div>
    )
  }

  openNav = () => {
    this.setState({navOpen:true});
  }

  closeNav = () => {
    this.setState({navOpen:false});
  }

  setUser = (user) => {
    this.setState({user});
  }

  /**
   * Provides a function to refresh the user profile in client.
   * The function returns control back to the caller giving ability to control UI while we wait.
   *
   * @memberof App
   */
  refreshUser = async () => {
    return fetch('/api/getUser?refresh=true', {headers: { Pragma: 'no-cache'}})
    .then(response => {
      return response.json();
    }).then(user => {
      if(Object.keys(user).length > 0) {
        this.setState({user});
        return user;
      }else{
        return null;
      }
    }).catch((err) => {
      console.error(err);
      return null;
    });
  }

  login = () => {
    let currentUrl = `${window.location.pathname}${window.location.search}`;
    window.location.href = `/auth/azure?p=${process.env.REACT_APP_SIGN_IN_POLICY}&redirectUrl=${currentUrl}`;
  }

  logout = () => {
    let currentUrl = `${window.location.pathname}${window.location.search}`;
    window.location.href = `/auth/logout?redirectUrl=${currentUrl}`;
  }

  fetchNotifications = () => {
    return fetch('/api/getAppNotifications', {headers: { Pragma: 'no-cache'}}).then(response => {
      return response.json();
    }).then(notifications => {
      this.setState({appNotifications: notifications});
    });
  }

  dismissAllNotifications = () => {
    return fetch('/api/dismissAllNotifications', {headers: { Pragma: 'no-cache'}}).then(response => {
    }).then(() => {
      this.setState({appNotifications: null});
    });
  }


  /**
   * Moneris actions are at top level due to an issue with placing it at lower level component.
   * The closeCheckout command is not working properly during component cleanup and that means you will end up with multiple instances of Moneris checkout which will call the callbacks multiple times as well.
   */
  loadMonerisResources = () => {
    const script = document.createElement("script");
    script.src = "https://gatewayt.moneris.com/chkt/js/chkt_v1.00.js";
    script.async = true;
    document.body.appendChild(script);
  }

  getMonerisCheckout = () => {
    if(!this.monerisRef.current){
    // eslint-disable-next-line no-undef
      this.monerisRef.current = new monerisCheckout();
    }
    return this.monerisRef.current;
  }

  render() {

    let appNotifications = null;
    if (this.state.appNotifications && Array.isArray(this.state.appNotifications) && this.state.appNotifications.length) {
      appNotifications = this.state.appNotifications.map(notification => <AppToastNotification {...notification} />)
    }

  return (
      <>
        {this.state.loading && <div style={{position:'absolute', top:'50%', left:'50%'}}><Spinner/></div>}
        {
          !this.props.browserSupported ? <BrowserNotSupported /> : !this.state.loading && <UserContext.Provider value={{user: this.state.user, setUser: this.setUser, refreshUser: this.refreshUser, login: this.login}}>
            <MonerisProvider>
              <BrowserRouter>
                <div className={this.state.navOpen ? 'nav-toggle' : ''}>

                  {appNotifications && (
                    <div style={{ position: "fixed", top: 80, right: 10, zIndex: 1001 }}>
                      { appNotifications.length > 1 && <Toast key='xx0002' style={{fontSize: '16px'}}>
                        <Toast.Body>
                          <div>
                            <a href="#" onClick={this.dismissAllNotifications}>Dismiss all notifications</a>
                          </div>
                        </Toast.Body>
                      </Toast> }
                      {appNotifications}
                    </div>
                  )}

                  <Navigation closenav={this.closeNav} user={this.state.user} />
                  <Header opennav={this.openNav} user={this.state.user} login={this.login} logout={this.logout}/>
                  <div className="main-layout">
                      <Suspense fallback={<Spinner/>}>
                      <Route path="/loginredirect" exact component={RedirectAfterLogin} />
                      <Route path="/loginfail" exact component={LoginFail} />
                      <Route path='/logout' exact component={Logout} />
                      <Route path='/' exact component={PermitRequestList} />
                      <Route path='/request/:permitType/:continue' exact component={RoadPermitType} />
                      <Route path='/myrequests' exact component={MyRequests} />
                      <PrivateRoute path='/sharedrequests' exact component={SharedRequests} />
                      <PrivateRoute path='/myrequests/:permitId' exact component={PermitRequest} />
                      <PrivateRoute path='/myrequests/:permitId/payments/:receiptId' exact component={PermitPaymentReceipt} />
                      <PrivateRoute path='/myrequests/:permitId/declined-payments/:orderNumber' exact component={PermitDeclinedPaymentReceipt} />
                      <PrivateRoute path='/myprofile/:startTab?' exact component={MyProfile} />
                      <PrivateRoute path='/newprofile' exact component={MyProfile} />
                      <PrivateRoute path='/mynotifications' exact component={MyNotifications} />
                      <PrivateRoute path='/payfee/:permitId' exact component={PermitPayment} />
                      <PrivateRoute path='/paysecurity/:permitId' exact component={PermitPayment} />
                      <Route path="/permitsubmitted/:permitId" exact component={this.permitSubmitted} />
                      <Route path="/termsandconditions" exact component={TermsAndConditions} />
                      <PrivateRoute path="/renewal/:permitId" exact component={PermitRenewal} />
                      <Route path="/release-notes" exact component={ReleaseNotes} />
                      </Suspense>
                      <div style={{position: 'fixed', bottom: '0px', paddingLeft: '20px', width: '100%', backgroundColor: '#fff', height: '40px', lineHeight: '40px', boxShadow: '0 -3px 6px rgba(0,0,0,0.05)'}}>
                        <em><NavLink to="/termsandconditions" exact >Road Permits Application Terms and Conditions</NavLink></em>
                      </div>
                    </div>
                </div>
              </BrowserRouter>
            </MonerisProvider>
          </UserContext.Provider>
        }
      </>
    );
  }
}

export default App;
