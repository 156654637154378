import React from 'react';
import FormWizardField from './../../form-wizard/FormWizardField';

class Contact extends React.Component {
    handleFieldChange = (event) => {
        let data = this.props.data;
        data[event.target.id] = event.target.value;
        this.props.handleContactChange(data); // send data up a level
    }

    render(){
        return(
            <div className="form-row">
                <div className="col-lg-4">
                    <FormWizardField fieldType="input" fieldName="name" fieldLabel={this.props.index === 0 ? 'Name' : null} data={this.props.data} handleFieldChange={this.handleFieldChange} errors={this.props.errors} required={this.props.required}/>
                </div>
                <div className="col-lg-3 col-sm-11">
                    <FormWizardField fieldType="input" fieldName="phone" fieldLabel={this.props.index === 0 ? 'Mobile Number (10 digit)' : null} data={this.props.data} handleFieldChange={this.handleFieldChange} errors={this.props.errors} required={this.props.required}/>
                </div>
                <div className="col-lg-4 col-sm-11">
                    <FormWizardField fieldType="input" fieldName="email" fieldLabel={this.props.index === 0 ? 'Email' : null} data={this.props.data} handleFieldChange={this.handleFieldChange} errors={this.props.errors} required={false}/>
                </div>
                <div className="col-sm-1">
                    { this.props.removeContact && <i className="fas fa-times likebutton" onClick={() => this.props.removeContact(this.props.data)} title='Delete contact'></i> }
                </div>
            </div>
        )
    }
}

class ContactInformationForm extends React.Component {
    componentDidMount(){
        if(!this.props.data.emergencyContact){
            const event = {target:{id:'emergencyContact', value:{name:null, phone: null}}};
            const event2 = {target:{id:'otherContacts', value:[{id:1, name:null, phone: null}]}};
            this.props.handleFieldChange(event);
            this.props.handleFieldChange(event2); // send data up to FormWizard
        }
    }

    handleEmergencyContactChange = (updatedContact) => {
        const event = {target:{id:'emergencyContact', value: updatedContact}};
        this.props.handleFieldChange(event); // send data up to FormWizard
    }

    handleOtherContactChange = (updatedContact) => {
        this.handleContactChange(updatedContact, 'otherContacts');
    }

    handleContactChange = (updatedContact, collectionName) => {
        const contactCollection = this.props.data[collectionName];
        const index = contactCollection.findIndex((e) => e.id === updatedContact.id);
        contactCollection[index] = updatedContact;
        const event = {target:{id:collectionName, value:contactCollection}};
        this.props.handleFieldChange(event); // send data up to FormWizard
    }

    addContact = (collectionName) => {
        const contactCollection = this.props.data[collectionName];
        const newContact = {id: contactCollection[contactCollection.length-1].id + 1, timesPermitted: [{id: 1}]};
        contactCollection.push(newContact);
        const event = {target:{id:collectionName, value:contactCollection}};
        this.props.handleFieldChange(event);
    }

    removeContact = (contactToRemove, collectionName) => {
        const contactCollection = this.props.data[collectionName];
        const filteredContacts = contactCollection.filter((e) => e.id !== contactToRemove.id);
        if(filteredContacts.length === 0) return;
        const event = {target:{id:collectionName, value:filteredContacts}};
        this.props.handleFieldChange(event);
    }

    render(){
        return (
            <div>
                <h5>Contact Information</h5>
                <p>Please provide contact information for yourself. You will be the contact person for this application</p>
                <p><span style={{"color":"red"}}>*</span> indicates a required field</p>
                
                <form>
                    <div className="form-group">
                        <label htmlFor="title">Primary Emergency Contact</label>
                        { this.props.data.emergencyContact && <Contact index={0} data={this.props.data.emergencyContact} handleContactChange={this.handleEmergencyContactChange} errors={this.props.errors.emergencyContact || {}} required={true}/> }
                    </div>

                    <div className="form-group">
                        <label htmlFor="title">Other Contacts</label>
                        { this.props.data.otherContacts &&  this.props.data.otherContacts.length > 0 && this.props.data.otherContacts.map((contact, index) => 
                        <Contact index={index} key={contact.id} data={contact} handleContactChange={this.handleOtherContactChange} removeContact={ this.props.data.otherContacts.length > 1 && ((contactToRemove) => this.removeContact(contactToRemove, 'otherContacts'))}
                        errors={(this.props.errors.otherContacts && this.props.errors.otherContacts[contact.id]) || {}} /> ) }
                        <div className="likebutton" onClick={() => this.addContact('otherContacts')}><i className="fas fa-plus"></i>&nbsp; Add Contact</div>
                    </div>
                </form>
            </div>
        )
    }
}

export default ContactInformationForm;