import React from 'react';
import FormWizardField from './../../form-wizard/FormWizardField';

const axios = require('axios');
const {config} = require('../../config/formConfiguration');

function indefinite(noun) {
    if (['utility company'].includes(noun)) return 'a'
    return ['a','e', 'i', 'o', 'u', 'A', 'E', 'I', 'O', 'U'].includes(noun[0])?'an':'a';
}

class ApplicantInformationForm extends React.Component {
    state = {showAccountLinkHelp: false, ownerBusinessAccounts: [], contractorBusinessAccounts: []};

    componentDidMount(){
        // automatically apply profile
        if(this.props.type === "Road Occupancy Application - Encroachment") {
            this.setApplicantType('business');
        }
        if(this.props.user && this.props.user.profile){
            if(this.props.user.preferences && this.props.user.preferences.businessAccountsEnabled) this.setBusinessAccounts();
            this.setSelectedBusinessAccounts();
            this.applyProfile();
        }
    }

    setApplicantType = (value) => {
      this.props.handleFieldChange({target:{id:'applicantType', type: 'text', value: value}});
    }

    setBusinessAccounts = () => {
        // get business accounts from profile
        let ownerBusinessAccounts = [];
        let contractorBusinessAccounts = [];
        this.props.user && this.props.user.profile && this.props.user.profile.memberships.forEach(membership => {
            if(membership.roles.some(role => ['admin', 'owner', 'create'].includes(role.name))) ownerBusinessAccounts.push(membership.organization);
            else if(membership.roles.some(role => ['contractor'].includes(role.name))) contractorBusinessAccounts.push(membership.organization);
        });
        this.setState({ownerBusinessAccounts, contractorBusinessAccounts});
    }

    setSelectedBusinessAccounts = () => {
        // if(this.props.data.applicantType === 'business'){
        //     if(this.state.ownerBusinessAccounts.length > 0) this.props.handleFieldChange({target:{id:'ownerBusinessAccount', value: (this.state.ownerBusinessAccounts[0]._id)}});
        //     this.props.handleFieldChange({target:{id:'consultantBusinessAccount', value: null}});
        // }else if(this.props.data.applicantType === 'consultant'){
        //     if(this.state.ownerBusinessAccounts.length > 0) this.props.handleFieldChange({target:{id:'consultantBusinessAccount', value: (this.state.ownerBusinessAccounts[0]._id)}});
        //     if(this.state.contractorBusinessAccounts.length > 0) this.props.handleFieldChange({target:{id:'ownerBusinessAccount', value: (this.state.contractorBusinessAccounts[0]._id)}});
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data.applicantType !== this.props.data.applicantType && this.props.user && this.props.user.profile) {
            this.applyProfile();
            this.setSelectedBusinessAccounts();
        }
    }

    getCompanySuggestions = async value => {
        if(value.length >= 3){
            let response = await axios.get(`/api/contractors?search=${value}`);
            return response.data ? response.data : [];
        }else{
            return [];
        }
    }

    toggleAccountLinkHelp = () => {
        this.setState({showAccountLinkHelp: !this.state.showAccountLinkHelp});
    }

    applyProfile = () => {
        // filter out land and mobile phone from user profile
        let phone = this.props.user.profile.phone;
        let alternatePhone = this.props.user.profile.alternatePhone;
        let mobilePhone, landPhone;
        if(alternatePhone  && alternatePhone.type === 'mobile') mobilePhone = alternatePhone;
        else if(alternatePhone && alternatePhone.type === 'land') landPhone = alternatePhone;
        if(phone && phone.type === 'mobile') mobilePhone = phone;
        else if(phone && phone.type === 'land') landPhone = phone;

        // if(!this.props.data.firstName) this.props.handleFieldChange({target:{id:'firstName', type: 'text', value: (this.props.user.profile.firstName)}});
        // if(!this.props.data.lastName) this.props.handleFieldChange({target:{id:'lastName', type: 'text', value: (this.props.user.profile.lastName)}});
        // if(!this.props.data.phone && landPhone) this.props.handleFieldChange({target:{id:'phone', type: 'text', value: (landPhone.phoneNumber)}});
        // if(!this.props.data.cell && mobilePhone) this.props.handleFieldChange({target:{id:'cell', type: 'text', value: (mobilePhone.phoneNumber)}});
        // if(!this.props.data.street && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'street', type: 'text', value: (this.props.user.profile.address.street)}});
        // if(!this.props.data.city && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'city', type: 'text', value: (this.props.user.profile.address.city)}});
        // if(!this.props.data.province && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'province', type: 'text', value: (this.props.user.profile.address.province)}});
        // if(!this.props.data.postalCode && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'postalCode', type: 'text', value: (this.props.user.profile.address.postalCode)}});


        switch (this.props.data.applicantType) {
            case 'consultant':
                if(!this.props.data.consultantEmail) {
                    this.props.handleFieldChange({target:{id:'consultantEmail', type: 'text', value: (this.props.user.profile.accountEmail)}});
                    this.props.handleFieldChange({target:{id:'consultantEmailVerify', type: 'text', value: (this.props.user.profile.accountEmail)}});
                }

                if(!this.props.data.consultantFirstName) this.props.handleFieldChange({target:{id:'consultantFirstName', type: 'text', value: (this.props.user.profile.firstName)}});
                if(!this.props.data.consultantLastName) this.props.handleFieldChange({target:{id:'consultantLastName', type: 'text', value: (this.props.user.profile.lastName)}});
                if(!this.props.data.consultantPhone && landPhone) this.props.handleFieldChange({target:{id:'consultantPhone', type: 'text', value: (landPhone.phoneNumber)}});
                if(!this.props.data.consultantCell && mobilePhone) this.props.handleFieldChange({target:{id:'consultantCell', type: 'text', value: (mobilePhone.phoneNumber)}});
                if(!this.props.data.consultantStreet && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'consultantStreet', type: 'text', value: (this.props.user.profile.address.street)}});
                if(!this.props.data.consultantCity && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'consultantCity', type: 'text', value: (this.props.user.profile.address.city)}});
                if(!this.props.data.consultantProvince && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'consultantProvince', type: 'text', value: (this.props.user.profile.address.province)}});
                if(!this.props.data.consultantPostalCode && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'consultantPostalCode', type: 'text', value: (this.props.user.profile.address.postalCode)}});

                // Selectively erasing owner data
                if (this.props.user.profile.firstName && (this.props.user.profile.firstName === this.props.data.firstName)) this.props.handleFieldChange({target:{id:'firstName', type: 'text', value: null}});
                if (this.props.user.profile.lastName && (this.props.user.profile.lastName  === this.props.data.lastName)) this.props.handleFieldChange({target:{id:'lastName', type: 'text', value: null}});
                if (this.props.user.profile.phone && landPhone && landPhone.phoneNumber === this.props.data.phone) this.props.handleFieldChange({target:{id:'phone', type: 'text', value: null}});
                if (this.props.user.profile.phone && mobilePhone && mobilePhone.phoneNumber ===  this.props.data.cell) this.props.handleFieldChange({target:{id:'cell', type: 'text', value: null}});
                if (this.props.user.profile.address && (this.props.user.profile.address.street === this.props.data.street)) this.props.handleFieldChange({target:{id:'street', type: 'text', value: null}});
                if (this.props.user.profile.address && (this.props.user.profile.address.city === this.props.data.city)) this.props.handleFieldChange({target:{id:'city', type: 'text', value: null}});
                if (this.props.user.profile.address && (this.props.user.profile.address.province === this.props.data.province)) this.props.handleFieldChange({target:{id:'province', type: 'text', value: null}});
                if (this.props.user.profile.address && (this.props.user.profile.address.postalCode === this.props.data.postalCode)) this.props.handleFieldChange({target:{id:'postalCode', type: 'text', value: null}});
                if (this.props.user.profile.accountEmail && (this.props.user.profile.accountEmail  === this.props.data.email)) this.props.handleFieldChange({target:{id:'email', type: 'text', value: null}});
                if (this.props.user.profile.accountEmail && (this.props.user.profile.accountEmail  === this.props.data.emailverify)) this.props.handleFieldChange({target:{id:'emailverify', type: 'text', value: null}});

                break;
            case 'business':
                if(!this.props.data.email) {
                    this.props.handleFieldChange({target:{id:'email', type: 'text', value: (this.props.user.profile.accountEmail)}});
                    this.props.handleFieldChange({target:{id:'emailverify', type: 'text', value: (this.props.user.profile.accountEmail)}});
                }
                if(!this.props.data.firstName) this.props.handleFieldChange({target:{id:'firstName', type: 'text', value: (this.props.user.profile.firstName)}});
                if(!this.props.data.lastName) this.props.handleFieldChange({target:{id:'lastName', type: 'text', value: (this.props.user.profile.lastName)}});
                if(!this.props.data.phone && landPhone) this.props.handleFieldChange({target:{id:'phone', type: 'text', value: (landPhone.phoneNumber)}});
                if(!this.props.data.cell && mobilePhone) this.props.handleFieldChange({target:{id:'cell', type: 'text', value: (mobilePhone.phoneNumber)}});
                if(!this.props.data.street && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'street', type: 'text', value: (this.props.user.profile.address.street)}});
                if(!this.props.data.city && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'city', type: 'text', value: (this.props.user.profile.address.city)}});
                if(!this.props.data.province && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'province', type: 'text', value: (this.props.user.profile.address.province)}});
                if(!this.props.data.postalCode && this.props.user.profile.address) this.props.handleFieldChange({target:{id:'postalCode', type: 'text', value: (this.props.user.profile.address.postalCode)}});
                break;

            default:
                break;
        }
    }

    render(){
        let conf = config(this.props);

        let showAccountLinkSection = ((this.props.data.applicantType === 'consultant' && this.state.ownerBusinessAccounts.length > 0) || (((this.state.contractorBusinessAccounts.length > 0 && this.props.data.applicantType === 'consultant') || (this.state.ownerBusinessAccounts.length > 0 && this.props.data.applicantType === 'business'))));
        return(
            <div>
                <h5>Applicant Information</h5>
                <p>Please provide contact information for yourself. You will be the contact person for this application</p>
                <p><span style={{"color":"red"}}>*</span> indicates a required field</p>
                <form>
                    {
                        !conf.dualOwners && <FormWizardField fieldType="radio" fieldName="applicantType" fieldLabel="Please choose how you are applying for this request" required={true} radioOptions={[{label:`Apply as ${indefinite(conf.businessLabel.toLowerCase())} ${conf.businessLabel.toLowerCase()}`, value:'business'}, {label:`Apply as ${indefinite(conf.consultantLabel.toLowerCase())} ${conf.consultantLabel.toLowerCase()} on behalf of ${indefinite(conf.businessLabel.toLowerCase())} ${conf.businessLabel.toLowerCase()}`, value: 'consultant'}]} {...this.props} />
                    }
                    {
                        conf.dualOwners && <FormWizardField fieldType="radio" fieldName="applicantSubtype" fieldLabel="Please choose how you are applying for this request" required={true} radioOptions={[{label:`Apply as an owner`, value:'owner'}, {label:`Apply as a municipality`, value: 'municipality'}]} {...this.props} />
                    }

                    {this.props.data.applicantType &&
                        <>
                            {showAccountLinkSection &&
                                <div className="">
                                    <div className="form-row">
                                        <div className="col-lg-6">
                                            <p><i className="fas fa-people-arrows mr-2 text-primary wobble-hor-bottom" style={{fontSize:'1.5rem'}} />Link this request to your business accounts <i className="fas fa-question-circle likebutton" onClick={this.toggleAccountLinkHelp}></i></p>
                                            { this.state.showAccountLinkHelp && <p>Linking accounts to this request will enable sharing for members of those business accounts. For example: if you are a contractor with access to the owner company account, you can select their account so they can see the status of the permit request.</p>}

                                            { this.props.data.applicantType === 'consultant' && this.state.ownerBusinessAccounts.length > 0 &&
                                                <FormWizardField fieldType="select" fieldName="consultantBusinessAccount" fieldLabel={`${conf.consultantLabel} Business Account`}  {...this.props}>
                                                    <option key="consultantBusinessAccount_null" value=""></option>
                                                    {this.state.ownerBusinessAccounts.map(org => <option key={`consultantBusinessAccount_${org._id}`}value={org._id}>{org.name}</option>)}

                                                </FormWizardField>
                                            }

                                            { ((this.state.contractorBusinessAccounts.length > 0 && this.props.data.applicantType === 'consultant') || (this.state.ownerBusinessAccounts.length > 0 && this.props.data.applicantType === 'business')) &&
                                                <FormWizardField fieldType="select" fieldName="ownerBusinessAccount" fieldLabel={`${conf.businessLabel} Business Account`} fieldHelp={this.props.data.applicantType === 'consultant' ? 'These are the companies you have access to as a consultant / contractor' : null} {...this.props}>
                                                    <option key="ownerBusinessAccount_null" value=""></option>
                                                    { this.props.data.applicantType === 'consultant' ? this.state.contractorBusinessAccounts.map(org => <option value={org._id}>{org.name}</option>) : this.state.ownerBusinessAccounts.map(org => <option key={`ownerBusinessAccount_${org._id}`} value={org._id}>{org.name}</option>)}
                                                </FormWizardField>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }

                    { this.props.data.applicantType === 'business' && conf.showOwnerName &&
                        <>
                        <div className="form-row">
                        <div className="col"><FormWizardField fieldType="autosuggest" fieldName="owner" fieldLabel={`${conf.businessLabel} Name`} autoComplete="organization" required={true} getSuggestions={this.getCompanySuggestions} {...this.props}/></div>
                        </div>
                        <hr/>
                        </>
                    }

                    { this.props.data.applicantType === 'consultant' && (
                    <>
                    <div className="form-row">
                        <div className="col-lg-6"><FormWizardField fieldType="autosuggest" fieldName="consultantName" fieldLabel={`${conf.consultantLabel} Company Name`} required={true} getSuggestions={this.getCompanySuggestions} {...this.props}/></div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="title">{conf.consultantLabel} Name</label>
                        <div className="form-row">
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" fieldName="consultantFirstName" fieldLabel="First Name" required={true} {...this.props}/>
                            </div>
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" fieldName="consultantLastName" fieldLabel="Last Name" required={true} {...this.props}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" fieldName="consultantTitle" autoComplete="organization-title" fieldLabel="Job Title" {...this.props}/>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="form-group">
                        <label htmlFor="consultantStreet">{conf.consultantLabel} Address</label>
                        <div className="form-row">
                            <div className="col">
                                <FormWizardField fieldType="input" fieldName="consultantStreet" fieldLabel="Street" required={true} {...this.props}/>
                            </div>
                            <div className="col-lg-2">
                                <FormWizardField fieldType="input" fieldName="consultantUnit" fieldLabel="Unit Number" {...this.props}/>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-lg-5">
                                <FormWizardField fieldType="input" fieldName="consultantCity" fieldLabel="City" required={true} {...this.props}/>
                            </div>
                            <div className="col-lg-4">
                                <FormWizardField fieldType="select" fieldName="consultantProvince" fieldLabel="Province" required={true} {...this.props}>
                                    <option></option>
                                    <option value="AB">Alberta</option>
                                    <option value="BC">British Columbia</option>
                                    <option value="MB">Manitoba</option>
                                    <option value="NB">New Brunswick</option>
                                    <option value="NL">Newfoundland and Labrador</option>
                                    <option value="NS">Nova Scotia</option>
                                    <option value="ON">Ontario</option>
                                    <option value="PE">Prince Edward Island</option>
                                    <option value="QC">Quebec</option>
                                    <option value="SK">Saskatchewan</option>
                                    <option value="NT">Northwest Territories</option>
                                    <option value="NU">Nunavut</option>
                                    <option value="YT">Yukon</option>
                                </FormWizardField>
                            </div>
                            <div className="col-md-3">
                                <FormWizardField fieldType="input" fieldName="consultantPostalCode" fieldLabel="Postal Code" required={true} {...this.props}/>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="form-group">
                        <label htmlFor="consultantPhone">{conf.consultantLabel} Phone</label>
                        <div className="form-row align-items-end">
                            <div className="col-lg-4">
                                <FormWizardField fieldType="input" fieldName="consultantPhone" fieldLabel="Phone # (10 digit)" required={true} {...this.props}/>
                            </div>
                            <div className="col-lg-4 pr-4">
                                <FormWizardField fieldType="input" fieldName="consultantExtension" fieldLabel="Extension" {...this.props} maxLength="6"/>
                            </div>

                            <div className="col-lg-4">
                            <FormWizardField fieldType="input" fieldName="consultantCell" fieldLabel="Mobile Phone # (10 digit)" {...this.props}/>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div className="form-group">
                        <label htmlFor="consultantEmail">{conf.consultantLabel} Email</label>
                        <div className="form-row">
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" inputType="email" fieldName="consultantEmail" fieldLabel="Enter Email" required={true} {...this.props}/>
                            </div>
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" inputType="email" fieldName="consultantEmailVerify" fieldLabel="Confirm Email" required={true} autoComplete="new-password" {...this.props}/>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    </> )}

                    { (!conf.dualOwners && (this.props.data.applicantType === 'business' || this.props.data.applicantType === 'consultant') ||
                      (conf.dualOwners && this.props.data.applicantSubtype)) &&
                        <>
                           { this.props.data.applicantType === 'consultant' && conf.showOwnerName &&
                           <>
                            <div className="form-row">
                             <div className="col"><FormWizardField fieldType="autosuggest" fieldName="owner" fieldLabel={`${conf.businessLabel} Name`} autoComplete="organization" required={true} getSuggestions={this.getCompanySuggestions} {...this.props}/></div>
                            </div>
                            <hr/>
                           </>
                           }

                         {(conf.dualOwners && this.props.data.applicantSubtype === 'municipality') &&
                           <div className="form-row">
                            <div className="col-lg-6">
                             <FormWizardField fieldType="select" fieldName="owner" fieldLabel="Municipality" required={true} {...this.props}>
                              <option></option>
                              <option value="Aurora">Aurora</option>
                              <option value="East Gwillimbury">East Gwillimbury</option>
                              <option value="Georgina">Georgina</option>
                              <option value="King">King</option>
                              <option value="Markham">Markham</option>
                              <option value="Newmarket">Newmarket</option>
                              <option value="Richmond Hill">Richmond Hill</option>
                              <option value="Vaughan">Vaughan</option>
                              <option value="Whitchurch-Stouffville">Whitchurch-Stouffville</option>
                            </FormWizardField>
                            </div>
                           </div>
                         }

                            <div className="form-group">
                                <label htmlFor="title">{`${conf.businessLabel} Contact Name`}</label>
                                <div className="form-row">
                                    <div className="col-lg-6">
                                        <FormWizardField fieldType="input" fieldName="firstName" fieldLabel="First Name" required={true} {...this.props}/>
                                    </div>
                                    <div className="col-lg-6">
                                        <FormWizardField fieldType="input" fieldName="lastName" fieldLabel="Last Name" required={true} {...this.props}/>
                                    </div>
                                </div>
                             {!conf.dualOwners &&
                              <div className="form-row">
                                <div className="col-lg-6">
                                   <FormWizardField fieldType="input" fieldName="title" fieldLabel="Job Title" {...this.props} autoComplete="job-title"/>
                                </div>
                             </div>
                             }
                            </div>

                            <hr />

                            <div className="form-group">
                                <label htmlFor="street">{`${conf.businessLabel} Address`}</label>
                                <div className="form-row">
                                    <div className="col">
                                        <FormWizardField fieldType="input" fieldName="street" fieldLabel="Street" required={true} {...this.props}/>
                                    </div>
                                    <div className="col-lg-2">
                                        <FormWizardField fieldType="input" fieldName="unit" fieldLabel="Unit Number" {...this.props} autoComplete="unit-number"/>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col-lg-5">
                                        <FormWizardField fieldType="input" fieldName="city" fieldLabel="City" required={true} {...this.props}/>
                                    </div>
                                    <div className="col-lg-4">
                                        <FormWizardField fieldType="select" fieldName="province" fieldLabel="Province" required={true} {...this.props}>
                                            <option></option>
                                            <option value="AB">Alberta</option>
                                            <option value="BC">British Columbia</option>
                                            <option value="MB">Manitoba</option>
                                            <option value="NB">New Brunswick</option>
                                            <option value="NL">Newfoundland and Labrador</option>
                                            <option value="NS">Nova Scotia</option>
                                            <option value="ON">Ontario</option>
                                            <option value="PE">Prince Edward Island</option>
                                            <option value="QC">Quebec</option>
                                            <option value="SK">Saskatchewan</option>
                                            <option value="NT">Northwest Territories</option>
                                            <option value="NU">Nunavut</option>
                                            <option value="YT">Yukon</option>
                                        </FormWizardField>
                                    </div>
                                    <div className="col-md-3">
                                        <FormWizardField fieldType="input" fieldName="postalCode" fieldLabel="Postal Code" required={true} {...this.props}/>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="form-group">
                                <label htmlFor="phone">{`${conf.businessLabel} Phone`}</label>
                                <div className="form-row align-items-end">
                                    <div className="col-lg-4">
                                        <FormWizardField fieldType="input" fieldName="phone" fieldLabel="Phone # (10 digit)" required={true} {...this.props}/>
                                    </div>
                                    <div className="col-lg-4 pr-4">
                                        <FormWizardField fieldType="input" fieldName="extension" fieldLabel="Extension" {...this.props} maxLength="6"/>
                                    </div>

                                    <div className="col-lg-4">
                                    <FormWizardField fieldType="input" fieldName="cell" fieldLabel="Mobile Phone # (10 digit)" {...this.props}/>
                                    </div>
                                </div>
                            </div>

                            <hr/>

                            <div className="form-group">
                                <label htmlFor="email">{`${conf.businessLabel} Email`}</label>
                                <div className="form-row">
                                    <div className="col-lg-6">
                                        <FormWizardField fieldType="input" inputType="email" fieldName="email" fieldLabel="Enter Email" required={true} {...this.props}/>
                                    </div>
                                    <div className="col-lg-6">
                                        <FormWizardField fieldType="input" inputType="email" fieldName="emailverify" fieldLabel="Confirm Email" required={true} autoComplete="new-password" {...this.props}/>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </form>
            </div>
        )
    }
}

export default ApplicantInformationForm;
