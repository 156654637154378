import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
const { profileConfig } = require('../config/profileFormConfig');

export default function InviteMember(props) {
    const { register, handleSubmit, formState: { errors }, getValues, setValue, watch } = useForm();
    watch(['role']);

    useEffect(() => {
      if(!getValues.role) setValue('role', 'contractor');
    }, []);

    const onSubmit = (data) => {
      data.invitedByOrg = props.user.profile.memberships.find(membership => membership.organization._id === data.invitedByOrg).organization;
        fetch("/api/invite-to-org", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((response) => {
            if (!response.ok) throw Error(response.statusText);
            props.refreshUser();
        })
        .catch((err) => {
            console.error(err);
        }).then(() => {
            props.submitCallback();
        });
    }

    return (
        <div>
            <p>Inviting other members to your business account will allow them to either view or create permit requests on behalf of your business or as a contractor / consultant. Choosing the membership role will determine hte member's access.</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="recipientEmail">Recipient Email <span style={{"color":"red"}}>*</span></label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.recipientEmail ? " is-invalid" : "")
                }
                name="recipientEmail"
                {...register("recipientEmail",{ required: "Please enter an email address" })}
              />
              {errors.recipientEmail && (
                <div className="invalid-feedback">
                  {errors.recipientEmail.message}
                </div>
              )}
              <small className="form-text text-muted">If the invited member already has a York Region online identity, make sure to use the email used for that identity.</small>
            </div>

            <div className="form-group">
              <label htmlFor="role">Type of Access</label>
              <select
                name="role"
                className="form-control"
                {...register("role")}
                defaultValue="contractor"
              >
                <option value="contractor">Contractor / Consultant</option>
                <option value="view">View Requests</option>
                <option value="create">Create Requests</option>
                <option value="admin">Administrate Account</option>
              </select>
              <small className="form-text text-muted">{ profileConfig.roleHelp[getValues().role] }</small>
            </div>

            <div className="form-group">
              <label htmlFor="invitedByOrg">Business Account for Access</label>
              <select
                name="invitedByOrg"
                className="form-control"
                {...register("invitedByOrg",{ required: "A business account is required" })}
                
              >
                {
                  props.user.profile.memberships.map((membership) => (
                    membership.roles.some(role => role.name === 'admin' || role.name === 'owner') && (
                    <option key={membership.organization._id} value={membership.organization._id}>{membership.organization.name}</option>
                    )
                  ))
                }
              </select>
              <small className="form-text text-muted">Choose which business account you would like to invite the member to. Business accounts will only be selectable if they are owned or administered by you.</small>
            </div>

            <div className="row justify-content-between">
              <div className="col">
                <button type="reset" className="btn btn-primary mb-2" onClick={props.cancelCallback}>Cancel</button>
              </div>
              <div className="col text-right">
                <button type="submit" className="btn btn-primary mb-2">Send Invite</button>
              </div>
            </div>
          </form>
        </div>
    )
}