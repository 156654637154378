import React from 'react';
import { NavLink } from 'react-router-dom';

class PermitRequestList extends React.Component {
    render(){
        return (
            <div className="m-3">
                {/* <h5>Hello {this.props.user.profile.firstName}</h5> */}
                <h5>Please select from the permit request types below to start the request process.</h5>
                <ul>
                <li><NavLink to="/request/Municipal Consent/new" activeClassName="active" exact >Municipal Consent</NavLink></li>
                    <li><NavLink to="/request/Utility/new" activeClassName="active" exact >Utility</NavLink></li>
                    <li><NavLink to="/request/Development/new" activeClassName="active" exact >Development Related Approvals</NavLink></li>
                    <li><NavLink to="/request/Construction/new" activeClassName="active" exact >Construction</NavLink></li>
                    <li><NavLink to="/request/Geotechnical/new" activeClassName="active" exact >Geotechnical</NavLink></li>
                    <li><NavLink to="/request/Encroachment/new" activeClassName="active" exact >Encroachment</NavLink></li>
                    <li><NavLink to="/request/Survey and Inspection/new" activeClassName="active" exact >Survey and Inspection</NavLink></li>
                    <li><NavLink to="/request/Entrance/new" activeClassName="active" exact >Entrance</NavLink></li>
                    <li><NavLink to="/request/Excess Load/new" activeClassName="active" exact >Excess Load</NavLink></li>
                    <li><NavLink to="/request/Film & Photo/new" activeClassName="active" exact >Film or Photography</NavLink></li>
                    <li><NavLink to="/request/Event/new" activeClassName="active" exact >Special Event</NavLink></li>
                    <li><NavLink to="/request/Load Exemption/new" activeClassName="active" exact >Load Exemption</NavLink></li>
                    <li><NavLink to="/request/Temporary Road Sign/new" activeClassName="active" exact >Temporary Road Sign</NavLink></li>
                    {/*<li><NavLink to="/request/Temporary Outdoor Patio/new" activeClassName="active" exact >Temporary Outdoor Patio</NavLink></li>*/}
                </ul>
                {/*<em><NavLink to="/termsandconditions" exact >Road Permits Application Terms and Conditions</NavLink></em>*/}
            </div>
        )
    }
}

export default PermitRequestList;
