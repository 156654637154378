import React from 'react';
import { Redirect } from "react-router-dom";
import MunicipalConsent from './MunicipalConsent';
import RoadOccupancyApplicationForm from './RoadOccupancyApplicationForm';
import TemporaryRoadSignForm from './TemporaryRoadSignForm';
import ExcessLoadForm from './ExcessLoadForm';
import LoadExemption from './LoadExemption';
import Encroachment from './Encroachment';
import TemporaryOutdoorPatio from "./TemporaryOutdoorPatio";

function RoadPermitType ({match}) {
    switch(match.params.permitType) {
      case 'Municipal Consent':
        return <div className="m-3"><MunicipalConsent permitType={match.params.permitType} continueFromSession={match.params.continue === 'continue'}/></div>;
      case 'Temporary Road Sign':
        return <div className="m-3"><TemporaryRoadSignForm permitType={match.params.permitType} continueFromSession={match.params.continue === 'continue'} /></div>;
      case 'Excess Load':
        return <div className="m-3"><ExcessLoadForm permitType={match.params.permitType} continueFromSession={match.params.continue === 'continue'} /></div>;
      case 'Load Exemption':
        return <div className="m-3"><LoadExemption permitType={match.params.permitType} continueFromSession={match.params.continue === 'continue'} /></div>;
      case 'Temporary Outdoor Patio':
        return <div className="m-3"><TemporaryOutdoorPatio permitType={match.params.permitType} continueFromSession={match.params.continue === 'continue'} /></div>;
      case 'Encroachment':
        return <div className="m-3"><Encroachment permitType={match.params.permitType} continueFromSession={match.params.continue === 'continue'} /></div>;
      case 'Development':
      case 'Construction':
      case 'Geotechnical':
      case 'Utility':
      case 'Film & Photo':
      case 'Survey and Inspection':
      case 'Entrance':
      case 'Event':
        return <div className="m-3"><RoadOccupancyApplicationForm permitType={match.params.permitType} continueFromSession={match.params.continue === 'continue'} /></div>;
      default:
        return <Redirect to="/" />
    }
}

export default RoadPermitType;
