import React from 'react';
import FormWizardField from '../../form-wizard/FormWizardField';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {regionalRoads} from '../../config/formConfiguration';

class RegionRoad extends React.Component {
    handleFieldChange = (event) => {
        let data = this.props.data;
        data[event.target.id] = event.target.value;
        this.props.handleRoadChange(data); // send data up a level
    }

    render(){
        let errors = this.props.errors[this.props.data.id] || {};

        let roads = regionalRoads
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((road) => {
            return <option value={road.name}>{road.name}</option>;
          });    

        return (
            <div className="mt-3">
                <div style={{position:'relative'}}>Location # {this.props.index + 1} {this.props.removeRoad && <i className="fas fa-times likebutton" style={{position:'absolute', right:'0px'}} onClick={() => this.props.removeRoad(this.props.data)} title='Delete road entry'></i> }</div>
                <div className="border border-dark rounded p-3">
                    <div className="form-row">
                        <div className="col-lg-6">
                            <FormWizardField fieldType="select" fieldName="locationRegionRoad" fieldLabel="Region road" required={true} handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}>
                                <option value="">Select a regional road...</option>
                                {roads}
                            </FormWizardField>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-lg">
                            <FormWizardField fieldType="input" fieldName="locationFromRoad" fieldLabel="Between" handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                        </div>
                        <div className="col-lg">
                            <FormWizardField fieldType="input" fieldName="locationToRoad" fieldLabel="And" handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                        </div>
                    </div>
                    <div className="form-row mb-2"><div className="col-lg-6">OR</div></div>
                    <div className="form-row">
                        <div className="col-lg-6">
                            <FormWizardField fieldType="input" fieldName="locationDetail" fieldLabel="At (intersection or address number)" handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
class LocationInformationForm extends React.Component {
    state = { roadCharacterCount : {} };

    componentDidMount(){
        //initialize the region road component data if applicable for form type
        if(this.props.type !== 'Road Occupancy Application - Entrance'){
            if(this.props.data.regionRoads){
                this.setState({roadCharacterCount: this.props.data.regionRoads.reduce((previous, current, index, array) => 
                    {
                        return {...previous, [current.id]: (current.locationRegionRoad || '').length + (current.locationDetail || '').length + (current.locationFromRoad || '').length + (current.locationToRoad || '').length + (current.locationFromRoad || current.locationToRoad ? 14 : 0)/* 14 extra characters for " between <> and <>*/ + (current.locationDetail ? 4 : 0)/* 4 extra characters for " at "*/};
                    }, {})});
            }else{
                const event = {target:{id:'regionRoads', value:[{id:1}]}};
                this.props.handleFieldChange(event); // send data up to FormWizard
            }
        }
    }

    handleRoadChange = (updatedRoad) => {
        const roads = this.props.data.regionRoads;
        const index = roads.findIndex((e) => e.id === updatedRoad.id);
        roads[index] = updatedRoad;
        const event = {target:{id:'regionRoads', value:roads}};
        this.setState({roadCharacterCount: {...this.state.roadCharacterCount, [updatedRoad.id]: (updatedRoad.locationRegionRoad || '').length + (updatedRoad.locationDetail || '').length + (updatedRoad.locationFromRoad || '').length + (updatedRoad.locationToRoad || '').length + (updatedRoad.locationFromRoad || updatedRoad.locationToRoad ? 14 : 0)/* 14 extra characters for " between <> and <>*/ + (updatedRoad.locationDetail ? 4 : 0)/* 4 extra characters for " at "*/}});
        this.props.handleFieldChange(event); // send data up to FormWizard
    }

    addRoad = () => {
        const roads = this.props.data.regionRoads;
        const newRoad = {id: roads[roads.length-1].id + 1};
        roads.push(newRoad);
        const event = {target:{id:'regionRoads', value:roads}};
        this.props.handleFieldChange(event);
    }

    removeRoad = (roadToRemove) => {
        const roads = this.props.data.regionRoads;
        const filteredRoads = roads.filter((e) => e.id !== roadToRemove.id);
        if(filteredRoads.length === 0) return;
        const event = {target:{id:'regionRoads', value:filteredRoads}};
        this.setState({roadCharacterCount: {...this.state.roadCharacterCount, [roadToRemove.id]: 0}});
        this.props.handleFieldChange(event);
    }

    render(){
        let totalCharCount = this.state.roadCharacterCount ? Object.keys(this.state.roadCharacterCount).reduce((previous, current) => previous + this.state.roadCharacterCount[current] + 2, 0) : 0;
        return (
            <div>
                <h5>
                    {this.props.type === 'Municipal Consent' && 'Location / Activity'}
                    {this.props.type !== 'Municipal Consent' && 'Location Information'}
                </h5>
                <p>Please provide the following information about the location</p>
                <p><span style={{"color":"red"}}>*</span> indicates a required field</p>
                {this.props.errors && this.props.errors.msg && <p style={{"color":"red"}}>{this.props.errors.msg}</p>}

                <form>

                    <div className="form-row">
                        <div className="col-lg-6">
                            <FormWizardField fieldType="select" fieldName="locationCity" fieldLabel="Town / City" required={true} {...this.props}>
                                <option></option>
                                <option value="Aurora">Aurora</option>
                                <option value="East Gwillimbury">East Gwillimbury</option>
                                <option value="Georgina">Georgina</option>
                                <option value="King">King</option>
                                <option value="Markham">Markham</option>
                                <option value="Newmarket">Newmarket</option>
                                <option value="Richmond Hill">Richmond Hill</option>
                                <option value="Vaughan">Vaughan</option>
                                <option value="Whitchurch-Stouffville">Whitchurch-Stouffville</option>
                                <option value="Multiple Municipalities">Multiple Municipalities</option>
                            </FormWizardField>
                        </div>
                    </div>
                    { !['Road Occupancy Application - Temporary Road Sign'].includes(this.props.type) &&
                        (this.props.data.regionRoads && this.props.data.regionRoads.length > 0) && (
                            <>
                                {this.props.data.regionRoads.map((road, index) => <RegionRoad key={road.id} index={index} data={road} handleRoadChange={this.handleRoadChange} removePlan={this.props.data.regionRoads.length > 1 && this.removeRoad} errors={this.props.errors} removeRoad={this.props.data.regionRoads.length > 1 && this.removeRoad}/>)}
                                <div className="likebutton mb-3" onClick={this.addRoad}>
                                    <i className="fas fa-plus"></i>
                                    &nbsp; Add Road &nbsp;
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Add an additional section (regional road, between roads OR at location)</Tooltip>}>
                                        <i class="fas fa-info-circle"></i>
                                    </OverlayTrigger>
                                </div>
                            </>
                        )
                    }
                    { ['Road Occupancy Application - Temporary Road Sign'].includes(this.props.type) &&
                        <FormWizardField fieldType="textarea" fieldName="streetAddress" fieldLabel="Address of Venue or Sign Location(s)" required={true} {...this.props} />
                    }

                    { this.props.type === 'Road Occupancy Application - Entrance' && (
                        <div className="form-row">
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" fieldName="streetAddress" fieldLabel="Street Address" required={true} {...this.props}/>
                            </div>
                        </div>
                    )}

                    { this.props.type === 'Municipal Consent' && <><div className="form-row">
                            <div className="col">
                                <FormWizardField fieldType="textarea" fieldName="activityDetail" fieldLabel="Activity Description" required={true} placeholder="Provide a brief description of the activity" {...this.props} />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-lg-6">
                                <FormWizardField fieldType="input" fieldName="clientNumber" fieldLabel="Client number" {...this.props} maxLength="40"/>
                            </div>
                        </div>
                        </>
                    }
                    
                    { this.props.data.regionRoads && <p style={{"color":totalCharCount > 2000 ? "red" : "inherit"}}>{totalCharCount} / 2000 characters allowed</p>}
                </form>
            </div>
        );
    }
}

export default LocationInformationForm;