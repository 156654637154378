import React from 'react';
import FormWizardField from '../../form-wizard/FormWizardField';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class RegionRoad extends React.Component {
    handleFieldChange = (event) => {
        let data = this.props.data;
        data[event.target.id] = event.target.value;
        this.props.handleRoadChange(data); // send data up a level
    }

    render(){
        let errors = this.props.errors[this.props.data.id] || {};
        return (
         <div className="mt-3">
             <div style={{position:'relative'}}>Road # {this.props.index + 1} {this.props.removeRoad && <i className="fas fa-times likebutton" style={{position:'absolute', right:'0px'}} onClick={() => this.props.removeRoad(this.props.data)} title='Delete road entry'></i> }</div>
             <div className="border border-dark rounded p-3">
                 <div className="form-row">
                     <div className="col-lg-6">
                         <FormWizardField fieldType="input" fieldLabel="Road"  fieldName="locationDetail" required={true} handleFieldChange={this.handleFieldChange} data={this.props.data} errors={errors}/>
                     </div>
                 </div>
             </div>
         </div>
        )
    }
}
class RouteInformationForm extends React.Component {
    state = {}

    componentDidMount(){
        //initialize the region road component data if applicable for form type
        if(!this.props.data.regionRoads){
            const event = {target:{id:'regionRoads', value:[{id:1}]}};
            this.props.handleFieldChange(event); // send data up to FormWizard
        }
    }

    handleRoadChange = (updatedRoad) => {
        const roads = this.props.data.regionRoads;
        const index = roads.findIndex((e) => e.id === updatedRoad.id);
        roads[index] = updatedRoad;
        const event = {target:{id:'regionRoads', value:roads}};
        this.props.handleFieldChange(event); // send data up to FormWizard
    }

    addRoad = () => {
        const roads = this.props.data.regionRoads;
        const newRoad = {id: roads[roads.length-1].id + 1};
        roads.push(newRoad);
        const event = {target:{id:'regionRoads', value:roads}};
        this.props.handleFieldChange(event);
    }

    removeRoad = (roadToRemove) => {
        const roads = this.props.data.regionRoads;
        const filteredRoads = roads.filter((e) => e.id !== roadToRemove.id);
        if(filteredRoads.length === 0) return;
        const event = {target:{id:'regionRoads', value:filteredRoads}};
        this.props.handleFieldChange(event);
    }


    render(){
        let routeUse = (this.props.form.permitInformationForm.data.subtype !== "Excess Load Annual");
        return (
         <div>
             <h5>Route information</h5>
             {!routeUse &&
             <p> Route Information is not applicable for this type of excess load permit.</p>
             }
             {routeUse &&
             <>
                <p> Please provide the following information about the location</p>
                 <p><span style={{"color": "red"}}>*</span> indicates a required field</p>

                 <form>

                 <div className="form-row">
                 <div className="col-lg-6">
                 <FormWizardField fieldType="input" fieldName="startRegionRoad" fieldLabel="Region Road Start Point" {...this.props} required={true}/>
                 </div>
                 <div className="col-lg-6">
                 <FormWizardField fieldType="input" fieldName="startLocationDetail" fieldLabel="Start at (nearest intersection)" {...this.props} required={true}/>
                 </div>
                 </div>
                 <div className="form-row">
                 <div className="col-lg-6">
                 <FormWizardField fieldType="input" fieldName="endRegionRoad" fieldLabel="Region Road End Point" {...this.props} required={true}/>
                 </div>
                 <div className="col-lg-6">
                 <FormWizardField fieldType="input" fieldName="endLocationDetail" fieldLabel="End at (nearest intersection)" {...this.props} required={true}/>
                 </div>
                 </div>

                 {
                     (this.props.data.regionRoads && this.props.data.regionRoads.length > 0) && (
                      <>
                          <h6>Roads travelled</h6>
                          {this.props.data.regionRoads.map((road, index) =>
                           <RegionRoad key={road.id} index={index}
                                data={road}
                                handleRoadChange={this.handleRoadChange}
                                removePlan={this.props.data.regionRoads.length > 1 && this.removeRoad}
                                errors={this.props.errors}
                                removeRoad={this.props.data.regionRoads.length > 1 && this.removeRoad}/>)}
                          <div className="likebutton mb-3" onClick={this.addRoad}>
                              <i className="fas fa-plus"></i>
                              &nbsp; Add Road &nbsp;
                              <OverlayTrigger
                               placement="top"
                               overlay={<Tooltip>Add an additional road</Tooltip>}>
                                  <i class="fas fa-info-circle"></i>
                              </OverlayTrigger>
                          </div>
                      </>
                     )
                 }

                 </form>
             </>
             }
         </div>
        );
    }
}

export default RouteInformationForm;