import React from 'react';

class TermsAndConditions extends React.Component {
    render(){
        return (
            <div className="m-3">
                <h3>Terms and Conditions</h3>
                <p>By using this application, you acknowledge that The Regional Municipality of York (“The Region”) provides the application as a service to the public on an "as is", "as available", basis. The Region does not make any express or implied warranties, representations or endorsements with respect to the application. In particular, without limiting the generality of the foregoing, you acknowledge that the information provided through the application may not reflect:</p>
                <ul>
                    <li>
                        the current status of any permit or municipal consent applications received by The Region;
                    </li>
                    <li>
                        the current fees related to the application of a permit or municipal consent application;
                    </li>
                </ul>
                <p>Although The Region makes reasonable efforts to keep the information available through the application up-to-date, errors can occur in recording some transactions, and delays can occur in reporting information, affecting the accuracy of the information.</p>
                <p>The Region is not responsible for, and will not be liable to you or anyone else, for any damages whatsoever, including any indirect, special, incidental or consequential damages, arising out of or in connection with your use of, or inability to use, the application.</p>
                <p>By using this application, you agree that you will not (i) interfere with or disrupt the application or computer networks connected thereto; or (ii) delete or revise any materials or information accessible through the application.</p>
                <p>By using this application to apply for a permit, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you electronically in a variety of ways such as email and text messaging, application content and push notices. You agree that all agreements, notices, messages, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
            </div>
        )
    }
}

export default TermsAndConditions;