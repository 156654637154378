import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink, useParams, useLocation, useHistory } from "react-router-dom";
import { MonerisContext } from "./MonerisProvider";
import { UserContext } from "./UserContext";
import PermitPaymentReceipt from './PermitPaymentReceipt';
import Spinner from "./Spinner";

export default function PermitPayment(props) {
    const { permitId } = useParams();
    const history = useHistory();
    const location = useLocation();
    const monerisContext = useContext(MonerisContext);
    const userContext = useContext(UserContext);
    const [transaction, setTransaction] = useState(null);
    const [displayCheckout, setDisplayCheckout] = useState(true);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [category, setCategory] = useState(location.pathname.includes('payfee') ? 'fee' : location.pathname.includes('paysecurity') ? 'security' : null);
    const [paymentError, setPaymentError] = useState(false);

    useEffect(() => {
        setLoading(true);
        monerisContext.loadMonerisResources();
        preloadTransaction();
        return cleanupTransaction;
    }, []);

    const preloadTransaction = () => {
        // user can be sent to payment page to either pay a fee or pay security
        fetch('/api/payment/preload', {method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body:`{"permitId":"${permitId}", "category": "${category}", "partialPaymentAmount": "${monerisContext.partialPaymentAmount ? monerisContext.partialPaymentAmount.replace(/[$,]+/g,"") : ''}"}`
        }).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(responseJson => {
            if(responseJson.ticket){
                let monerisCheckout = monerisContext.getMonerisCheckout();
                monerisCheckout.setMode(monerisContext.monerisEnvironment);
                monerisCheckout.setCheckoutDiv("monerisCheckout");
                monerisCheckout.setCallback("error_event",console.error);
                monerisCheckout.setCallback("page_loaded",console.log);
                monerisCheckout.setCallback("cancel_transaction", cancelTransaction);
                monerisCheckout.setCallback("payment_receipt",transactionReceipt);
                monerisCheckout.setCallback("payment_complete",transactionReceipt);
                monerisCheckout.startCheckout(responseJson.ticket);
                monerisCheckout.transactionTicket = responseJson.ticket;
            }else{
                console.error(responseJson);
            }
            setLoading(false);
        })
        .catch((err) => {
            console.error(err);
            setLoading(false);
        });
    }

    const cleanupTransaction = () => {
        let monerisCheckout = monerisContext.getMonerisCheckout();
        if(monerisCheckout.transactionTicket){
            try {
                monerisCheckout.closeCheckout(" ");//monerisCheckout.transactionTicket);
                monerisCheckout.transactionTicket = null;
            } catch (error) {
                //console.error(error);
            }
        }
    };

    const transactionReceipt = response => {
        setProcessing(true);
        monerisContext.getMonerisCheckout().closeCheckout(" ");
        monerisContext.transactionTicket = null;
        setDisplayCheckout(false);
        const body = {ticket: JSON.parse(response).ticket, permitId, category};
        fetch('/api/payment/process-receipt', {method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(transactionResponse => {
            setTransaction(transactionResponse);
            if(transactionResponse.result === 'a'){
                if(transactionResponse.cityworksReceiptId){
                    history.push(`/myrequests/${permitId}/payments/${transactionResponse.cityworksReceiptId}`);
                }else{
                    //TODO handle error here
                    console.error('No receipt id returned');
                    setPaymentError(true);
                }
            }else{
                history.push(`/myrequests/${permitId}/declined-payments/${transactionResponse.orderNumber}`);
            }
            setProcessing(false);
        }).catch((err) => {
            //TODO handle error here
            console.error(err);
            setPaymentError(true);
            setProcessing(false);
        });
    };

    const cancelTransaction = response => {
        let monerisCheckout = monerisContext.getMonerisCheckout();
        monerisCheckout.closeCheckout(" ");//monerisCheckout.transactionTicket);
        monerisCheckout.transactionTicket = null;
        history.push(`/myrequests/${permitId}`);
    };

    return (
        <div>
            { loading && <div>
                <Spinner />
            </div> }
            { processing && <div>
                <Spinner />
                <div className="text-center">processing payment, please wait ...</div>
            </div> }
            { paymentError && <div className="m-4">
                <NavLink to={`/myrequests/${permitId}`}>
                    <div className="m-4 d-flex">
                    <i className="fas fa-arrow-left mr-2" style={{fontSize: '1.5rem'}}></i>
                    <div>Back to permit request</div>
                    </div>
                </NavLink>
                { transaction && <div style={{marginTop:"30px"}}>
                    { transaction.result === 'a' ? <div>
                    <div><img src="/img/green-check.png"  width="100px" alt="Green Checkmark"/></div>
                    <div className="mb-4">Your transaction has been approved on permit request <strong>#{permitId}</strong></div>
                    </div> : <div>
                        <div><img src="/img/red-x.png"  width="100px" alt="Red X"/></div>
                        <div className="mb-4 text-danger">Your transaction has been declined on permit request <strong>#{permitId}</strong></div>
                    </div> }
                    <div>Confirmation Order Number: <strong>{transaction.orderNumber}</strong></div>
                    <div>Due to an application error, we cannot display the receipt at this time. Please contact <a href="mailto:permits@york.ca">permits@york.ca</a> for support. Please do not attempt another payment or you might be charged more than once.</div>
                </div> }
                { !transaction && <div>
                    <div>Due to an application error, your transaction may not have been processed at this time. Please contact <a href="mailto:permits@york.ca">permits@york.ca</a> for support. Please do not attempt another online transaction at this time.</div>
                </div> }
            </div> }
            { !paymentError && <div className="monerisContainer">
                { displayCheckout && <div id="monerisCheckout"></div> }
            </div> }
        </div>
    );
}