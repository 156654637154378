import React from 'react';

class FormWizardSteps extends React.Component {
    render(){
        return (
            <div className="card">
                <div className="card-header">
                    Checklist
                </div>
                <ul className="list-group list-group-flush">
                    { (this.props.allStepNames || []).map((step, index) => {
                        let isStepCompleted = this.props.completedSteps.includes(index);
                        let isCurrentStep = this.props.currentStep === index;
                        let canClickChangeStep = isStepCompleted && this.props.currentStepComponent.type.displayName === 'FormWizardStep';
                        let display = !this.props.hiddenSteps.includes(index);

                        let iconClass = isCurrentStep ? (isCurrentStep && isStepCompleted ? 'fa-dot-circle text-success' : 'fa-dot-circle') : isStepCompleted ? 'fa-check-circle text-success' : 'fa-circle';
                        return display ? <li className={'list-group-item' + (canClickChangeStep ? ' likebutton' : '') + (isCurrentStep ? ' current-step' : '')} key={step} data-index={index} onClick={canClickChangeStep ? this.props.changeStep : null} >
                        { this.props.validating && isCurrentStep ? <span className="spinner-border text-secondary mr-2" role="status" aria-hidden="true" style={{width:'1.5rem', height:'1.5rem'}}></span> : <i className={`far ${iconClass} mr-2`} /> }
                        { this.props.validating && isCurrentStep ? <span className="step-name">Validating...</span> : <span className="step-name">{step}</span> }
                        </li> : null;
                    }) }
                </ul>
            </div>
        );
    }
}

export default FormWizardSteps;
