import React from "react";
import { useForm } from "react-hook-form";

export default function CreateMembershipRequest(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        fetch("/api/request-org-access", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((response) => {
            if (!response.ok) throw Error(response.statusText);
            props.refreshUser();
        })
        .catch((err) => {
            console.error(err);
        }).then(() => {
            props.submitCallback();
        });
    }
    
    return (
        <div>
            <p>Reqesting access to another business would allow you to view or create permit requests on behalf of that business. Use this feature if you are a contractor working on behalf of another business / organization. </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="email">Recipient Business Email <span style={{"color":"red"}}>*</span></label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.recipientEmail ? " is-invalid" : "")
                }
                name="recipientEmail"
                {...register("recipientEmail",{ required: "Please enter an email address" })}
              />
              {errors.recipientEmail && (
                <div className="invalid-feedback">
                  {errors.recipientEmail.message}
                </div>
              )}
              <small className="form-text text-muted">The request recipient must be the owner or administrator of the busienss account you want to request access to.</small>
            </div>

            <div className="form-group">
              <label htmlFor="role">Type of Access</label>
              <select
                name="role"
                className="form-control"
                {...register("role")}
              >
                <option value="contractor">Contractor / Consultant</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="requestedByOrg">Account for Access</label>
              <select
                name="requestedByOrg"
                className="form-control"
                {...register("requestedByOrg")}
              >
                <option value="">My Individual Account</option>
                {
                  props.user.profile.memberships.map((membership) => (
                    membership.roles.some(role => role.name === 'admin' || role.name === 'owner') && (
                    <option key={membership.organization._id} value={membership.organization._id}>{membership.organization.name}</option>
                    )
                  ))
                }
              </select>
              <small className="form-text text-muted">Choose which account you would like to have access to the requested recipient business. If you are the owner or admin of a business account, you can choose to link that business acount or choose your individual account.</small>
            </div>

            <div className="row justify-content-between">
              <div className="col">
                <button type="reset" className="btn btn-primary mb-2" onClick={props.cancelCallback}>Cancel</button>
              </div>
              <div className="col text-right">
                <button type="submit" className="btn btn-primary mb-2">Send Access Request</button>
              </div>
            </div>
          </form>
        </div>
    )
}