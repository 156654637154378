import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

export default function ReleaseNotes(props) {
  const [releaseNotes, setReleaseNotes] = useState([]);

  useEffect(() => {
    fetch("/api/release-notes")
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      })
      .then((releaseNotes) => {
        setReleaseNotes(releaseNotes);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
      <div className="m-3">
        <h1 style={{fontSize:"1.75em"}}>What's new with the Road Permit Request App?</h1>
        <h2 style={{fontSize:"1em"}} className="mb-5">Road Permit Request Application News and Release Information</h2>
        {releaseNotes.map(releaseNote => (
          <div className="mb-4 m-3">
              <h3 className="text-muted" style={{fontSize:"1.35em"}}>{new Intl.DateTimeFormat("en-CA", {dateStyle : "long", }).format(new Date(releaseNote.date))}</h3>
              <h2 style={{fontSize:"1.5em"}}><div class="badge badge-primary">v{releaseNote.version}</div> {releaseNote.heading}</h2>
              <div dangerouslySetInnerHTML={{ __html: releaseNote.body }}></div>
          </div>
        ))}
      </div>
  );
}
