/*
 * THIS COMPONENT USES REACT HOOKS
 */

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Spinner from "../Spinner";

export default function EditMyProfile(props) {
  const [showMemberAccessHelp, setShowMemberAccessHelp] = useState(false);
  const { register, handleSubmit, watch, formState: { errors }, getValues, setValue } = useForm();
  watch();

  const onSubmit = (data) => {
    // clean out memberships
    delete data.memberships;
    fetch("/api/my-org-profile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        props.submitCallback();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const toggleMemberAccessHelp = () => {
    setShowMemberAccessHelp(!showMemberAccessHelp);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="hidden"
        name="_id"
        defaultValue={props.orgProfile._id}
        {...register("_id")}
      />
      <div className="form-row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className={"form-control" + (errors.name ? " is-invalid" : "")}
              name="name"
              defaultValue={props.orgProfile.name}
              {...register("name",{
                required: "Name is required",
              })}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name.message}</div>
            )}
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="primaryPhone.phoneNumber">Phone Number</label>
            <input
              type="text"
              className={
                "form-control" +
                (errors.primaryPhone?.phoneNumber ? " is-invalid" : "")
              }
              name="primaryPhone.phoneNumber"
              defaultValue={
                props.orgProfile.primaryPhone
                  ? props.orgProfile.primaryPhone.phoneNumber
                  : ""
              }
              {...register("primaryPhone.phoneNumber",{
                pattern: {
                  value: /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4})$/,
                  message: "Phone number not valid",
                },
              })}
            />
            {errors.primaryPhone?.phoneNumber && (
              <div className="invalid-feedback">
                {errors.primaryPhone.phoneNumber.message}
              </div>
            )}
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="primaryPhone.type">Phone Type</label>
            <select
              name="primaryPhone.type"
              className={
                "form-control" +
                (errors.primaryPhone?.type ? " is-invalid" : "")
              }
              defaultValue={
                props.orgProfile.primaryPhone ? props.orgProfile.primaryPhone.type : ""
              }
              {...register("primaryPhone.type",{
                validate: (value) => {
                  const { primaryPhone } = getValues();
                  if (primaryPhone && primaryPhone.phoneNumber && value === "")
                    return "Please select a phone number type";
                  return true;
                },
              })}
            >
              <option value=""></option>
              <option value="mobile">Mobile</option>
              <option value="land">Land Line</option>
              <option value="other">Other</option>
            </select>
            {errors.primaryPhone?.type && (
              <div className="invalid-feedback">
                {errors.primaryPhone.type.message}
              </div>
            )}
          </div>
        </div>
      </div>

      <label htmlFor="address.unitNumber">Address</label>

      <div className="form-row">
        <div className="col-3">
          <div className="form-group">
            <label htmlFor="address.unitNumber">Unit</label>
            <input
              type="text"
              className={
                "form-control" +
                (errors.address?.unitNumber ? " is-invalid" : "")
              }
              name="address.unitNumber"
              defaultValue={
                props.orgProfile.address ? props.orgProfile.address.unitNumber : ""
              }
              {...register("address.unitNumber")}
            />
            {errors.address?.unitNumber && (
              <div className="invalid-feedback">
                {errors.address.unitNumber.message}
              </div>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label htmlFor="address.streetNumber">Street Number</label>
            <input
              type="text"
              className={
                "form-control" +
                (errors.address?.streetNumber ? " is-invalid" : "")
              }
              name="address.streetNumber"
              defaultValue={
                props.orgProfile.address ? props.orgProfile.address.streetNumber : ""
              }
              {...register("address.streetNumber")}
            />
            {errors.address?.streetNumber && (
              <div className="invalid-feedback">
                {errors.address.streetNumber.message}
              </div>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="address.streetName">Street Name</label>
            <input
              type="text"
              className={
                "form-control" +
                (errors.address?.streetName ? " is-invalid" : "")
              }
              name="address.streetName"
              defaultValue={
                props.orgProfile.address ? props.orgProfile.address.streetName : ""
              }
              {...register("address.streetName")}
            />
            {errors.address?.streetName && (
              <div className="invalid-feedback">
                {errors.address.streetName.message}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="address.city">City</label>
            <input
              type="text"
              className={
                "form-control" + (errors.address?.city ? " is-invalid" : "")
              }
              name="address.city"
              defaultValue={props.orgProfile.address ? props.orgProfile.address.city : ""}
              {...register("address.city")}
            />
            {errors.address?.city && (
              <div className="invalid-feedback">
                {errors.address.city.message}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="address.province">Province</label>
            <select
              className={
                "form-control" + (errors.address?.province ? " is-invalid" : "")
              }
              name="address.province"
              defaultValue={
                props.orgProfile.address ? props.orgProfile.address.province : ""
              }
              {...register("address.province")}
            >
              <option></option>
              <option value="AB">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="MB">Manitoba</option>
              <option value="NB">New Brunswick</option>
              <option value="NL">Newfoundland and Labrador</option>
              <option value="NS">Nova Scotia</option>
              <option value="ON">Ontario</option>
              <option value="PE">Prince Edward Island</option>
              <option value="QC">Quebec</option>
              <option value="SK">Saskatchewan</option>
              <option value="NT">Northwest Territories</option>
              <option value="NU">Nunavut</option>
              <option value="YT">Yukon</option>
            </select>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col">
          <div className="form-group">
              <label htmlFor="address.postalCode">Postal Code</label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.address?.postalCode ? " is-invalid" : "")
                }
                name="address.postalCode"
                defaultValue={
                  props.orgProfile.address ? props.orgProfile.address.postalCode : ""
                }
                {...register("address.postalCode",{
                  pattern: {
                    value: /^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i,
                    message: "Postal code not valid",
                  },
                })}
              />
              {errors.address?.postalCode && (
                <div className="invalid-feedback">
                  {errors.address.postalCode.message}
                </div>
              )}
          </div>
        </div>
      </div>

      {props.orgProfile.memberships && 
        <div className="mt-3">
          <table class="table">
            <thead class="thead-light">
              <tr>
                <th colspan="3">
                  Member Access <i class="fas fa-question-circle likebutton" onClick={toggleMemberAccessHelp}></i>
                  {showMemberAccessHelp && <div><small>The table below shows any accounts that have access to this business account's data and the access roles. To add a new member, use the <span className="likelink" onClick={() => props.inviteRedirect()}>"Invite to Your Business"</span> button</small></div>}
                </th>
              </tr>
              <tr>
                <th scope="col">Member</th>
                <th scope="col">Roles</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {props.orgProfile.memberships.map((membership, index) => {
              let deleted = getValues(`memberships[${index}].deleted`);
              let roles = getValues(`memberships[${index}].roles`) || membership.roles;
              return (
                <tr key={membership._id}>
                  <input
                    type="hidden"
                    {...register(`memberships[${index}].deleted`)}
                    name={`memberships[${index}].deleted`}
                  />
                  <input
                    type="hidden"
                    {...register(`memberships[${index}].roles`)}
                    name={`memberships[${index}].roles`}
                  />
                  <input
                    type="hidden"
                    {...register(`memberships[${index}]._id`)}
                    name={`memberships[${index}]._id`}
                  />
                  <th scope="row">
                    <div
                      className="col-6 p-2"
                      style={{ textDecoration: deleted ? "line-through" : "none" }}
                    >
                    {membership.customer.firstName} {membership.customer.lastName} (
                    {membership.customer.accountEmail})
                  </div>
                  </th>
                  <td>
                  <div
                    className="col-5 p-2"
                    style={{ textDecoration: deleted ? "line-through" : "none" }}
                  >
                    {membership.roles.map((role, roleIndex) => (
                      <span key={role} className="badge badge-pill badge-secondary">
                        {role}{" "}
                        <i
                          className="fas fa-times likebutton"
                          onClick={() => {
                            roles.splice(roleIndex, 1);
                            setValue(`memberships[${index}].roles`, roles);
                          }}
                        />
                      </span>
                    ))}
                    <i className="fas fa-plus likebutton mx-2" />
                  </div>
                  </td>
                  <td>
                  <div className="col-1 p-2 text-right">
                    <i
                      className="fas fa-times likebutton"
                      onClick={() => {
                        setValue(`memberships[${index}].deleted`, true);
                        membership.delete = true;
                      }}
                      title="Remove member access"
                    />
                  </div>
                  </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      }
      <div className="row justify-content-between">
        <div className="col">
          <button
            type="reset"
            className="btn btn-primary mb-2"
            onClick={() => props.cancelCallback()}
          >
            Cancel
          </button>
        </div>
        <div className="col text-right">
          <button type="submit" className="btn btn-primary mb-2">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
