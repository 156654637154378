import React from 'react';

const BrowserNotSupported = () => {
    return (
        <div className="container" style={{textAlign:'center'}}>
            <img className="mt-3 mb-3" alt="York Region Logo" src="/img/YorkLogoBlue.png"/>
            <h5>It appears your are using a browser that we no longer support. It is recommended that you use the latest version of Google Chrome, Mozilla Firefox or Microsoft Edge to safely use our services.</h5>
            
            <p>You can still get in touch with us:</p>
            <div className="center">
                <h3>Access York</h3>
                <p>1-877-464-9675<br />
                1-866-512-6228 (TTY)<br />	
                <a href="mailto:accessyork@york.ca">accessyork@york.ca</a></p>
            </div>
        </div>
    )
}

export default BrowserNotSupported;